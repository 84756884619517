import React from 'react';
import { FormateDate } from '../../utils/CustomFormatations';

/**
 * props:
 * - data
 * - onClick
 */
function OrderRow(props) {
	function getDate(timestamp) {
		// const date = new Date(Number(timestamp));
		// const frm = new Intl.DateTimeFormat('en-US', { dateStyle: 'short', timeStyle: 'short' }).format(date);
		const frm = FormateDate(parseInt(timestamp), true, true);
		return frm;
	}

	function getStatus(status) {
		let className;
		switch (status) {
			case 'PARTIALLY_FILLED':
				className = 'badge bg-info py-1';
				break;
			case 'FILLED':
				className = 'badge bg-success py-1';
				break;
			case 'REJECTED':
			case 'EXPIRED':
			case 'CANCELED':
				className = 'badge bg-danger py-1';
				break;
			default:
				className = 'badge bg-warning py-1';
				break;
		}
		return <span className={className}>{status.split('_')[0]}</span>;
	}

	return (
		<tr>
			<td>
				{props.data.automationId ? (
					<svg
						xmlns='http://www.w3.org/2000/svg'
						fill='white'
						viewBox='0 0 24 24'
						stroke-width='1.5'
						stroke='currentColor'
						className='icon icon-xs me-2'>
						<path
							stroke-linecap='round'
							stroke-linejoin='round'
							d='M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z'
						/>
						<path stroke-linecap='round' stroke-linejoin='round' d='M15 12a3 3 0 11-6 0 3 3 0 016 0z' />
					</svg>
				) : (
					<svg className='icon icon-xs me-2' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
						<path
							fillRule='evenodd'
							d='M9 3a1 1 0 012 0v5.5a.5.5 0 001 0V4a1 1 0 112 0v4.5a.5.5 0 001 0V6a1 1 0 112 0v5a7 7 0 11-14 0V9a1 1 0 012 0v2.5a.5.5 0 001 0V4a1 1 0 012 0v4.5a.5.5 0 001 0V3z'
							clipRule='evenodd'
						/>
					</svg>
				)}
				{props.data.side === 'BUY' ? (
					<span className='badge bg-dark text-warning py-1 me-2'>Buy</span>
				) : (
					<span className='badge bg-warning text-dark py-1 me-2'>Sell</span>
				)}
				{props.data.symbol}
				{props.data.side === 'BUY' && !props.data.returned && (
					<svg
						xmlns='http://www.w3.org/2000/svg'
						fill='yellow'
						viewBox='0 0 24 24'
						strokeWidth='1.5'
						stroke='currentColor'
						className='icon icon-xs ms-2'>
						<path
							stroke-linecap='round'
							stroke-linejoin='round'
							d='M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z'
						/>
					</svg>
				)}
			</td>
			<td>
				<span className='fw-normal'>{getDate(props.data.transactTime)}</span>
			</td>
			<td>
				<span className='fw-normal'>{props.data.quantity}</span>
			</td>
			<td>
				<span className='fw-bold'>{props.data.net}</span>
			</td>
			<td>
				<span className='fw-bold'>{props.data.avgPrice}</span>
			</td>
			<td>{getStatus(props.data.status)}</td>
			<td className='text-center'>
				<button
					id={'view' + props.data.id}
					type='button'
					className='btn btn-primary btn-xs'
					data-bs-toggle='modal'
					data-bs-target='#modalViewOrder'
					onClick={props.onClick}>
					<svg className='icon icon-xs' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
						<path d='M10 12a2 2 0 100-4 2 2 0 000 4z' />
						<path
							fillRule='evenodd'
							d='M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z'
							clipRule='evenodd'
						/>
					</svg>
				</button>
			</td>
		</tr>
	);
}

export default OrderRow;
