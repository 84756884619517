/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import SideBar from './SideBar';

function Menu() {
	return (
		<React.Fragment>
			<SideBar />
		</React.Fragment>
	);
}

export default Menu;
