/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getStrategies, startStrategy, stopStrategy, deleteStrategy } from '../../../services/StrategiesService';
import MyStrategyRow from './MyStrategyRow';
import StrategyModal from '../StrategyModal/StrategyModal';

import { i18n } from '../../../utils/translates/i18n';
import Pagination from '../../../utils/pagination/Pagination';
import { SettingPage } from '../../../utils/pagination/Settings';

/**
 * props:
 * - symbol
 * - onNotification
 */
function MyStrategies(props) {
	const navigate = useNavigate();
	const [strategies, setStrategies] = useState([]);
	const [editStrategy, setEditStrategy] = useState({});

	// PARA PAGINAÇÃO
	const token = sessionStorage.getItem('token');
	const [search, setSearch] = useState('');
	const [count, setCount] = useState(0);
	const [page, setPage] = useState(1);
	const [lim, setLims] = useState(10);
	SettingPage({ setting: setPage });

	const loading = async () => {
		getStrategies(props.symbol, page || 1)
			.then(result => {
				setStrategies(result.rows);
				setCount(result.count);
			})
			.catch(err => {
				console.error(err.response ? err.response.data : err.message);
				props.onNotification({ type: 'error', text: err.response ? err.response.data : err.message });
			});
	};

	const doSearch = () => {
		setPage(1);
		loading();
	};

	useEffect(() => {
		loading();
	}, [token, page, lim, props.symbol]);
	// FIM PARA PAGINAÇÃO

	function onEditClick(event) {
		const id = event.target.id.replace('edit', '');
		const strategy = strategies.find(s => s.id == id);
		setEditStrategy({ ...strategy });
	}

	function onStopClick(event) {
		const id = event.target.id.replace('stop', '');
		stopStrategy(id)
			.then(strategy => {
				navigate(0);
			})
			.catch(err => {
				console.error(err.response ? err.response.data : err.message);
				props.onNotification({ type: 'error', text: err.response ? err.response.data : err.message });
			});
	}

	function onStartClick(event) {
		const id = event.target.id.replace('start', '');
		startStrategy(id)
			.then(strategy => {
				navigate(0);
			})
			.catch(err => {
				console.error(err.response ? err.response.data : err.message);
				props.onNotification({ type: 'error', text: err.response ? err.response.data : err.message });
			});
	}

	function onDeleteClick(event) {
		const id = event.target.id.replace('delete', '');
		deleteStrategy(id)
			.then(strategy => {
				navigate(0);
			})
			.catch(err => {
				console.error(err.response ? err.response.data : err.message);
				props.onNotification({ type: 'error', text: err.response ? err.response.data : err.message });
			});
	}

	function onStrategySubmit(strategy) {
		navigate(0);
	}

	return (
		<React.Fragment>
			<Pagination count={count} pageSize={lim}>
				<span>
					<svg className='icon icon-xs' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
						<path d='M10 3.5a1.5 1.5 0 013 0V4a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-.5a1.5 1.5 0 000 3h.5a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-.5a1.5 1.5 0 00-3 0v.5a1 1 0 01-1 1H6a1 1 0 01-1-1v-3a1 1 0 00-1-1h-.5a1.5 1.5 0 010-3H4a1 1 0 001-1V6a1 1 0 011-1h3a1 1 0 001-1v-.5z' />
					</svg>
				</span>
				<div className='input-group mx-3'>
					<input
						type='text'
						className='form-control'
						placeholder={`${i18n.t('search')} ${i18n.t('subject')} / ${i18n.t('description')}`}
						value={search}
						onChange={e => setSearch(e.target.value)}
						onBlur={doSearch}
					/>
					<button className='btn input-group-text btn-dark' onClick={doSearch}>
						<i className='fas fa-search'></i>
					</button>
				</div>
				<select className='form-select w-50' value={lim} onChange={e => setLims(e.target.value)}>
					<option className='bg-light' value='5'>
						5 {i18n.t('registers')}
					</option>
					<option className='bg-light' value='10'>
						10 {i18n.t('registers')}
					</option>
					<option className='bg-light' value='25'>
						25 {i18n.t('registers')}
					</option>
					<option className='bg-light' value='50'>
						50 {i18n.t('registers')}
					</option>
					<option className='bg-light' value='100'>
						100 {i18n.t('registers')}
					</option>
				</select>
			</Pagination>
			<div className='card card-body border-0 shadow table-wrapper table-responsive'>
				<table className='table table-hover'>
					<thead>
						<tr>
							<th className='border-gray-200'>Symbol</th>
							<th className='border-gray-200'>Name</th>
							<th className='border-gray-200'>Status</th>
							<th className='border-gray-200'>Actions</th>
						</tr>
					</thead>
					<tbody>
						{strategies && strategies.length ? (
							strategies.map(strategy => (
								<MyStrategyRow
									key={strategy.id}
									data={strategy}
									onEditClick={onEditClick}
									onStartClick={onStartClick}
									onStopClick={onStopClick}
									onDeleteClick={onDeleteClick}
								/>
							))
						) : (
							<React.Fragment></React.Fragment>
						)}
					</tbody>
				</table>
			</div>
			<StrategyModal data={editStrategy} onSubmit={onStrategySubmit} />
		</React.Fragment>
	);
}

export default MyStrategies;
