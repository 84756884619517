import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Dashboard from './private/Dashboard/Dashboard';
import Orders from './private/Orders/Orders';
import Monitors from './private/Monitors/Monitors';
import Automations from './private/Automations/Automations';
import OrderTemplates from './private/OrderTemplates/OrderTemplates';
import NewOrderTemplate from './private/OrderTemplates/NewOrderTemplate';
import WithdrawTemplates from './private/WithdrawTemplates/WithdrawTemplates';
import Reports from './private/Reports/Reports';
import Symbols from './private/Symbols/Symbols';
import Wallet from './private/Wallet/Wallet';
import WebHooks from './private/WebHooks/WebHooks';
import Strategies from './private/Strategies/Strategies';
import NewOrder from './private/Futures/NewOrder';
import Menu from './components/Menu/Menu';
import Footer from './components/Footer/Footer';
import TopBar from './components/Menu/TopBar';

import NotAuthorized from './mmn/NotAuthorized';
import NotFound from './mmn/NotFound';

import Login from './Auth/Login';
import Register from './Auth/Register';
import WaitConfirm from './Auth/WaitConfirm';
import WaitReConfirm from './Auth/WaitReConfirm';
import ConfirmedEmail from './Auth/ConfirmedEmail';
import Forget from './Auth/Forget';
import RedefinePassword from './Auth/RedefinePassword';
import Logout from './Auth/Logout';

import Call from './mmn/Call';
import MmnDashboard from './mmn/Dashboard';
import Extract from './mmn/Extract';
import Order from './mmn/Order';
import Profile from './mmn/Profile';
import ReadCall from './mmn/ReadCall';
import UpdateWallet from './mmn/UpdateWallet';
import WithdrawalBtc from './mmn/WithdrawalBtc';
import OrderView from './mmn/OrderView';
import Mandala from './mmn/Mandala';
import NoConfirmedEmailWithdraw from './mmn/NoConfirmedEmailWithdraw';
import ConfirmedEmailWithdraw from './mmn/ConfirmedEmailWithdraw';
import BotSettings from './mmn/BotSettings';

export default function MainRoutes() {
	function Priv({ children }) {
		return (
			<>
				<Menu />
				<section className='main_content dashboard_part large_header_bg bg-grey-light'>
					<TopBar />
					{children}
				</section>
				<Footer />
			</>
		);
	}

	return (
		<BrowserRouter>
			<Routes>
				{/* Auth */}
				<Route path='/login' element={<Login />} />
				<Route path='/waitconfirm' element={<WaitConfirm />} />
				<Route path='/reconfirmemail/:nick' element={<WaitReConfirm />} />
				<Route path='/confirmedemail' element={<ConfirmedEmail />} />
				<Route path='/forget' element={<Forget />} />
				<Route path='/p/t/:tokenPass' element={<RedefinePassword />} />
				<Route path='/logout' element={<Logout />} />

				<Route path='/confirmedTokenWithdraw' element={<ConfirmedEmailWithdraw />} />
				<Route path='/noConfirmedTokenWithdraw' element={<NoConfirmedEmailWithdraw />} />

				<Route path='/traderbot'>
					<Route index element={<NotFound />} />
					<Route
						path='settings'
						element={
							<Priv>
								<BotSettings />
							</Priv>
						}
					/>
					<Route path='orders'>
						<Route
							index
							element={
								<Priv>
									<Orders />
								</Priv>
							}
						/>
						<Route
							path=':symbol'
							element={
								<Priv>
									<Orders />
								</Priv>
							}
						/>
					</Route>
					<Route path='forders'>
						<Route
							index
							element={
								<Priv>
									<Orders />
								</Priv>
							}
						/>
						<Route
							path=':symbol'
							element={
								<Priv>
									<Orders />
								</Priv>
							}
						/>
					</Route>
					<Route
						path='dashboard'
						element={
							<Priv>
								<Dashboard />
							</Priv>
						}
					/>
					<Route
						path='monitors'
						element={
							<Priv>
								<Monitors />
							</Priv>
						}
					/>
					<Route
						path='automations'
						element={
							<Priv>
								<Automations />
							</Priv>
						}
					/>
					<Route
						path='reports'
						element={
							<Priv>
								<Reports />
							</Priv>
						}
					/>
					<Route
						path='freports'
						element={
							<Priv>
								<Reports />
							</Priv>
						}
					/>
					<Route
						path='symbols'
						element={
							<Priv>
								<Symbols />
							</Priv>
						}
					/>
					<Route path='fOrderTemplates'>
						<Route
							index
							element={
								<Priv>
									<OrderTemplates />
								</Priv>
							}
						/>
						<Route
							path=':symbol'
							element={
								<Priv>
									<OrderTemplates />
								</Priv>
							}
						/>
					</Route>
					<Route path='orderTemplates'>
						<Route
							index
							element={
								<Priv>
									<OrderTemplates />
								</Priv>
							}
						/>
						<Route
							path=':symbol'
							element={
								<Priv>
									<OrderTemplates />
								</Priv>
							}
						/>
					</Route>
					<Route path='orderTemplate'>
						<Route
							index
							element={
								<Priv>
									<NewOrderTemplate />
								</Priv>
							}
						/>
						<Route
							path=':orderTemplateId'
							element={
								<Priv>
									<NewOrderTemplate />
								</Priv>
							}
						/>
					</Route>

					<Route path='withdrawTemplates'>
						<Route
							index
							element={
								<Priv>
									<WithdrawTemplates />
								</Priv>
							}
						/>
						<Route
							path=':coin'
							element={
								<Priv>
									<WithdrawTemplates />
								</Priv>
							}
						/>
					</Route>

					<Route
						path='wallet'
						element={
							<Priv>
								<Wallet />
							</Priv>
						}
					/>
					<Route
						path='webhooks'
						element={
							<Priv>
								<WebHooks />
							</Priv>
						}
					/>
					<Route
						path='strategies'
						element={
							<Priv>
								<Strategies />
							</Priv>
						}
					/>
					<Route
						path='futures'
						element={
							<Priv>
								<NewOrder />
							</Priv>
						}
					/>
				</Route>

				{/* MMN */}
				<Route path='/dashboard'>
					<Route
						index
						element={
							<Priv>
								<MmnDashboard />
							</Priv>
						}
					/>
					<Route
						path='businessgroup'
						element={
							<Priv>
								<Mandala />
							</Priv>
						}
					/>
					<Route
						path='profile'
						element={
							<Priv>
								<Profile />
							</Priv>
						}
					/>
					<Route
						path='extract'
						element={
							<Priv>
								<Extract />
							</Priv>
						}
					/>
					<Route path='order'>
						<Route
							index
							element={
								<Priv>
									<Order />
								</Priv>
							}
						/>
						<Route
							path=':orderId'
							element={
								<Priv>
									<OrderView />
								</Priv>
							}
						/>
					</Route>

					<Route
						path='withdrawalbtc'
						element={
							<Priv>
								<WithdrawalBtc />
							</Priv>
						}
					/>
					<Route
						path='updatewallet'
						element={
							<Priv>
								<UpdateWallet />
							</Priv>
						}
					/>
					<Route
						path='call'
						element={
							<Priv>
								<Call />
							</Priv>
						}
					/>
					<Route
						path='readcall'
						element={
							<Priv>
								<ReadCall />
							</Priv>
						}
					/>
				</Route>

				{/* Impeded */}
				<Route
					path='/notAuthorized'
					element={
						<Priv>
							<NotAuthorized />
						</Priv>
					}
				/>
				<Route path='/'>
					<Route index element={<Login />} />
					<Route path=':nickIndicant' element={<Register />} />
				</Route>
				<Route path='/about' element={<Login />} />
				<Route path='/*' element={<NotFound />} />
			</Routes>
		</BrowserRouter>
	);
}
