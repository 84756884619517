/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { i18n } from '../utils/translates/i18n';
import { useNavigate } from 'react-router-dom';
import { CheckPrivateAdmin } from '../utils/CheckPrivateAdmin';
import LoaderPage from '../utils/LoaderPage';
import { FormateDate, FormateFiatCurrency } from '../utils/CustomFormatations';
import { getExtract } from '../services/mmn/MovimentService';
import Toast from '../components/Toast/Toast';

export default function Extract() {
	// const token = process.env.REACT_APP_USER_TOKEN;
	const token = sessionStorage.getItem('token');
	const navigate = useNavigate();
	const [show, setShow] = useState(false);

	const [extract, setExtract] = useState([]);
	const [cred, setCred] = useState(0);
	const [deb, setDeb] = useState(0);
	const [total, setTotal] = useState(0);

	const doGetExtract = async () => {
		const success = await getExtract(token);
		if (success.status) setExtract(success.list.rows);
	};

	useEffect(() => {
		document.title = `${i18n.t('extract')} | ${process.env.REACT_APP_NAME}`;
		const doCheck = async () => {
			const token = sessionStorage.getItem('token');
			if (!token) navigate('/logout');
			const check = await CheckPrivateAdmin(token);
			if (!check) navigate('/logout');
			doGetExtract();
			setShow(true);
		};
		doCheck();
	}, []);

	useEffect(() => {
		let credit = 0;
		let debit = 0;

		for (let i of extract) {
			i.credit > 0 && (credit += i.credit);
			i.debit > 0 && (debit += i.debit);
		}
		setCred(credit);
		setDeb(debit);
		setTotal(credit - debit);
	}, [extract]);

	return !show ? (
		<LoaderPage />
	) : (
		<>
			<div className='container-fluid'>
				<div className='row'>
					<div className='col-12'>
						<div className='card radius-15'>
							<div className='pb-0 border-0 card-header d-sm-flex d-block'>
								<div className='pr-3 mb-3 mr-auto mb-sm-0'>
									<h4>{i18n.t('extract')}</h4>
								</div>
							</div>
							<div className='card card-body border-0 shadow table-wrapper table-responsive'>
								<table className='table table-hover'>
									<thead>
										<tr>
											<th className='border-gray-200'>{i18n.t('date')}</th>
											<th className='border-gray-200'>{i18n.t('description')}</th>
											<th className='border-gray-200'>{i18n.t('nick')}</th>
											<th className='border-gray-200'>{i18n.t('cred')}</th>
											<th className='border-gray-200'>{i18n.t('debt')}</th>
											<th className='border-gray-200'>{i18n.t('total')}</th>
										</tr>
									</thead>
									<tbody>
										{extract ? (
											extract.map((e, i) => {
												return (
													<tr
														key={i}
														className={`border-bottom border-${
															e.credit ? 'success list-group-item-success' : 'danger list-group-item-danger'
														}`}>
														<td>{FormateDate(e.createdAt, true, true)}</td>
														<td>{i18n.t(e.description)}</td>
														<td>{e.nick}</td>
														<td className='text-success'>{e.credit && FormateFiatCurrency(e.credit)}</td>
														<td className='text-danger'>{e.debit && FormateFiatCurrency(e.debit)}</td>
														<td></td>
													</tr>
												);
											})
										) : (
											<tr>
												<td colSpan='6'></td>
											</tr>
										)}
										<tr>
											<td colSpan='3'></td>
											<td className='text-success'>
												<strong>{FormateFiatCurrency(cred)}</strong>
											</td>
											<td className='text-danger'>
												<strong>{FormateFiatCurrency(deb)}</strong>
											</td>
											<td>{FormateFiatCurrency(total)}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Toast />
		</>
	);
}
