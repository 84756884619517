/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AutomationModal from './AutomationModal/AutomationModal';
import GridModal from './GridModal/GridModal';
import AutomationRow from './AutomationRow';
import { getAutomations, startAutomation, stopAutomation, deleteAutomation } from '../../services/AutomationsService';
import Toast from '../../components/Toast/Toast';
import NewAutomationButton from './NewAutomationButton';
import BacktestButton from './BacktestModal/BacktestButton';
import BacktestModal from './BacktestModal/BacktestModal';

import { i18n } from '../../utils/translates/i18n';
import Pagination from '../../utils/pagination/Pagination';
import { SettingPage } from '../../utils/pagination/Settings';
import { CheckPrivateAdmin } from '../../utils/CheckPrivateAdmin';
import LoaderPage from '../../utils/LoaderPage';

function Automations() {
	const navigate = useNavigate();
	const [show, setShow] = useState(false);
	const [automations, setAutomations] = useState([]);
	const [notification, setNotification] = useState({ type: '', text: '' });

	// PARA PAGINAÇÃO
	const token = sessionStorage.getItem('token');
	const [search, setSearch] = useState('');
	const [count, setCount] = useState(0);
	const [page, setPage] = useState(1);
	const [lim, setLims] = useState(10);
	SettingPage({ setting: setPage });

	const loading = async () => {
		getAutomations(page || 1, search)
			.then(result => {
				setAutomations(result.rows);
				setCount(result.count);
			})
			.catch(err => {
				console.error(err.response ? err.response.data : err.message);
				setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
			});
	};

	const doSearch = () => {
		setPage(1);
		loading();
	};

	useEffect(() => {
		loading();
	}, [token, page, lim, search]);
	// FIM PARA PAGINAÇÃO

	const DEFAULT_AUTOMATION = {
		symbol: '',
		conditions: '',
		actions: [],
		name: '',
		indexes: '',
	};

	const [editAutomation, setEditAutomation] = useState(DEFAULT_AUTOMATION);

	function onEditClick(event) {
		const id = event.target.id.replace('edit', '');
		const automation = automations.find(m => m.id == id);
		setEditAutomation({ ...automation });
	}

	function onStopClick(event) {
		const id = event.target.id.replace('stop', '');
		stopAutomation(id)
			.then(automation => {
				loading();
			})
			.catch(err => {
				console.error(err.response ? err.response.data : err.message);
				setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
			});
	}

	function onStartClick(event) {
		const id = event.target.id.replace('start', '');
		startAutomation(id)
			.then(automation => {
				loading();
			})
			.catch(err => {
				console.error(err.response ? err.response.data : err.message);
				setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
			});
	}

	function onDeleteClick(event) {
		const id = event.target.id.replace('delete', '');
		deleteAutomation(id)
			.then(automation => {
				loading();
			})
			.catch(err => {
				console.error(err.response ? err.response.data : err.message);
				setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
			});
	}

	function onAutomationSubmit(automation) {
		loading();
	}

	function onNewAutomationClick(event) {
		if (event.target.id === 'linkSchedule')
			setEditAutomation({ ...DEFAULT_AUTOMATION, schedule: new Date().toISOString() });
		else setEditAutomation({ ...DEFAULT_AUTOMATION });
	}

	useEffect(() => {
		const doCheck = async () => {
			document.title = `${i18n.t('automations')} | ${process.env.REACT_APP_NAME}`;
			const token = sessionStorage.getItem('token');
			if (!token) navigate('/logout');
			const check = await CheckPrivateAdmin(token);
			if (!check || !check.isActive) navigate('/notAuthorized');
			if (!check.accessKey) navigate('/traderbot/settings');
			setShow(true);
		};
		doCheck();
	}, []);

	return !show ? (
		<LoaderPage />
	) : (
		<React.Fragment>
			<section className='section'>
				<div className='container-fluid'>
					<div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
						<div className='d-block mb-4 mb-md-0'>
							<h2 className='h4'>Automations</h2>
						</div>
						{/* <div className='btn-toolbar mb-2 mb-md-0'>
							<div className='d-inline-flex align-items-center'>
								<BacktestButton />
								<NewAutomationButton onClick={onNewAutomationClick} />
							</div>
						</div> */}
					</div>
					<Pagination count={count} pageSize={lim}>
						<span>
							<svg className='icon icon-xs' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
								<path d='M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z'></path>
							</svg>
						</span>
						<div className='input-group mx-3'>
							<input
								type='text'
								className='form-control'
								placeholder={`${i18n.t('search')} ${i18n.t('subject')} / ${i18n.t('description')}`}
								value={search}
								onChange={e => setSearch(e.target.value)}
								onBlur={doSearch}
							/>
							<button className='btn input-group-text btn-dark' onClick={doSearch}>
								<i className='fas fa-search'></i>
							</button>
						</div>
						<select className='form-select w-50' value={lim} onChange={e => setLims(e.target.value)}>
							<option className='bg-light' value='5'>
								5 {i18n.t('registers')}
							</option>
							<option className='bg-light' value='10'>
								10 {i18n.t('registers')}
							</option>
							<option className='bg-light' value='25'>
								25 {i18n.t('registers')}
							</option>
							<option className='bg-light' value='50'>
								50 {i18n.t('registers')}
							</option>
							<option className='bg-light' value='100'>
								100 {i18n.t('registers')}
							</option>
						</select>
					</Pagination>
					<div className='card card-body border-0 shadow table-wrapper table-responsive'>
						<table className='table table-hover'>
							<thead>
								<tr>
									<th className='border-gray-200'>{i18n.t('symbol')}</th>
									<th className='border-gray-200'>{i18n.t('name')}</th>
									<th className='border-gray-200'>{i18n.t('status')}</th>
									<th className='border-gray-200'>{i18n.t('action')}</th>
								</tr>
							</thead>
							<tbody>
								{automations?.map(automation => (
									<AutomationRow
										key={automation.id}
										data={automation}
										onEditClick={onEditClick}
										onStartClick={onStartClick}
										onStopClick={onStopClick}
										onDeleteClick={onDeleteClick}
									/>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</section>
			<AutomationModal data={editAutomation} onSubmit={onAutomationSubmit} />
			<GridModal data={editAutomation} onSubmit={onAutomationSubmit} />
			<BacktestModal />
			<Toast type={notification.type} text={notification.text} />
		</React.Fragment>
	);
}

export default Automations;
