import { i18n } from './translates/i18n';

export const FormatePhone = (fone, ddi = null) => {
	if (!fone) return;
	let p = fone.toString().replace(/\D/g, '');
	if (!p) return '';
	p = p.split('');
	if (p.length === 12) p = p.slice(0, -1);
	return p.length === 11
		? `${ddi ? `+${ddi} ` : ''}${p[0] ? `(${p[0]}` : ''}${p[1] ? p[1] : ''}${p[2] ? `) ${p[2]}` : ''}${
				p[3] ? p[3] : ''
		  }${p[4] ? p[4] : ''}${p[5] ? p[5] : ''}${p[6] ? p[6] : ''}${p[7] ? `-${p[7]}` : ''}${p[8] ? p[8] : ''}${
				p[9] ? p[9] : ''
		  }${p[10] ? p[10] : ''}`
		: `${ddi ? `+${ddi} ` : ''}${p[0] ? `(${p[0]}` : ''}${p[1] ? p[1] : ''}${p[2] ? `) ${p[2]}` : ''}${
				p[3] ? p[3] : ''
		  }${p[4] ? p[4] : ''}${p[5] ? p[5] : ''}${p[6] ? `-${p[6]}` : ''}${p[7] ? p[7] : ''}${p[8] ? p[8] : ''}${
				p[9] ? p[9] : ''
		  }`;
};

export const FormateDate = (
	d,
	local = false,
	hours = false,
	aniversary = false,
	imperial = false,
	extensive = false,
	inputDateTimeLocal = false,
) => {
	if (!d) return;
	let data = new Date(d);

	let dia = local ? data.getDate().toString() : data.getUTCDate().toString();
	let diaF = dia.length === 1 ? '0' + dia : dia;
	let mes = local ? (data.getMonth() + 1).toString() : (data.getUTCMonth() + 1).toString();
	let mesF = mes.length === 1 ? '0' + mes : mes;
	let ano = local ? data.getFullYear().toString() : data.getUTCFullYear().toString();

	let hora = local ? data.getHours().toString() : data.getUTCHours().toString();
	let horaF = hora === 0 ? '00' : hora.length === 1 ? '0' + hora : hora;
	let minuto = local ? data.getMinutes().toString() : data.getUTCMinutes().toString();
	let minutoF = minuto === 0 ? '00' : minuto.length === 1 ? '0' + minuto : minuto;

	let hoje = new Date();
	let diah = parseInt(local ? hoje.getDate() : hoje.getUTCDate());
	let mesh = parseInt(local ? hoje.getMonth() + 1 : hoje.getUTCMonth() + 1);
	let anoh = parseInt(local ? hoje.getFullYear() : hoje.getUTCFullYear());
	let idade = anoh - parseInt(ano);

	if (mesh < parseInt(mes) || (mesh === parseInt(mes) && diah < parseInt(dia))) idade--;

	let formated = `${diaF}/${mesF}/${ano}`;

	if (imperial) {
		formated = `${ano}-${mesF}-${diaF}`;
	} else if (extensive) {
		let mesExtensive = [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December',
		][local ? data.getMonth() : data.getUTCMonth()];
		formated = `${diaF} ${i18n.t('of')} ${i18n.t(mesExtensive)} ${i18n.t('of')} ${ano}`.toLowerCase();
	}
	if (hours) formated = `${formated} - ${horaF}:${minutoF}`;

	if (aniversary) formated = `${formated} (${idade} ${i18n.t('years')})`;

	if (inputDateTimeLocal) formated = `${ano}-${mesF}-${diaF}T${horaF}:${minutoF}`;

	return formated;
};

export const FormateFiatCurrency = (value, fiat = null) => {
	if (!fiat) fiat = process.env.REACT_APP_FIAT_DEFAULT;
	let symbol = '$';
	let locale = undefined;
	switch (fiat) {
		case 'usd':
			symbol = '$';
			break;
		case 'brl':
			symbol = 'R$';
			break;
		case 'eur':
			symbol = '€';
			break;
		case 'jpy':
			symbol = '¥';
			break;
		case 'chf':
			symbol = '₣';
			break;
		case 'gbp':
			symbol = '£';
			break;
		case 'krw':
			symbol = '₩';
			break;
		default:
			symbol = '$';
			break;
	}
	if (!value) return `${symbol} 0,00`;
	let currency = parseFloat(value).toLocaleString(locale, { minimumFractionDigits: 2 });
	return `${symbol} ${currency}`;
};

export const translate = (e, l) => {
	if (!l) return l;
	let larr = l.split('-');
	if (larr[0]) l = larr[0];
	let t = JSON.parse(e);
	let text = 'vazio';
	t.map(el => (el.lang === l ? (text = el.text) : false));
	return text;
};

export const toCapitalize = t => {
	if (!t) return t;
	return t.toLowerCase().charAt(0)?.toUpperCase() + t.toLowerCase().slice(1);
};

export const toCapitalizeAll = t => {
	if (!t) return t;
	let arr = [];
	t.toLowerCase()
		.split(' ')
		.map(el => arr.push(el.length > 1 ? el.charAt(0)?.toUpperCase() + el.slice(1) : el));
	let newText = arr.join(' ');
	return newText;
};

export const FormateZipcode = t => {
	if (!t) return t;
	let r = t.toString().replace(/\D/g, '');
	if (r.length <= 5) return r;
	else return `${r.slice(0, 5)}-${r.slice(5, 8)}`;
};

export const EmailValidate = t => {
	if (!t) return t;
	let re = /\S+@\S+\.\S+/;
	return re.test(t);
};

export const FormateCPF = t => {
	if (!t) return t;
	let numbers = t.toString().replace(/\D/g, '');
	if (!numbers) return numbers;
	if (numbers.length <= 3) return numbers;
	else if (numbers.length <= 6) return `${numbers.slice(0, 3)}.${numbers.slice(3)}`;
	else if (numbers.length <= 9) return `${numbers.slice(0, 3)}.${numbers.slice(3, 6)}.${numbers.slice(6)}`;
	else return `${numbers.slice(0, 3)}.${numbers.slice(3, 6)}.${numbers.slice(6, 9)}-${numbers.slice(9, 11)}`;
};

export const FormateCNPJ = t => {
	if (!t) return t;
	let numbers = t.toString().replace(/\D/g, '');
	if (!numbers) return numbers;
	if (numbers.length <= 2) return numbers;
	else if (numbers.length <= 5) return `${numbers.slice(0, 2)}.${numbers.slice(2)}`;
	else if (numbers.length <= 8) return `${numbers.slice(0, 2)}.${numbers.slice(2, 5)}.${numbers.slice(5)}`;
	else if (numbers.length <= 12)
		return `${numbers.slice(0, 2)}.${numbers.slice(2, 5)}.${numbers.slice(5, 8)}/${numbers.slice(8)}`;
	else
		return `${numbers.slice(0, 2)}.${numbers.slice(2, 5)}.${numbers.slice(5, 8)}/${numbers.slice(
			8,
			12,
		)}-${numbers.slice(12, 14)}`;
};

export const OnlyNumber = t => {
	if (!t) return t;
	return t.toString().replace(/\D/g, '');
};

export const RemoveSpaceAccents = t => {
	if (!t) return t;
	return t
		.normalize('NFD')
		.replace(/[^a-zA-Z\s]/g, '')
		.replace(' ', '') // remove espaços
		.replace(' ', '') // remove caractere especial não exibível
		.replace(' ', '') // remove caractere especial não exibível
		.replace(' ', '') // remove caractere especial não exibível
		.replace(' ', ''); // remove caractere especial não exibível
};
