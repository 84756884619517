/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { doLogout } from '../services/mmn/AuthService';
import Loader from '../utils/Loader';
import { i18n } from '../utils/translates/i18n';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
	const navigate = useNavigate();
	useEffect(() => {
		const goLogout = async () => {
			const token = sessionStorage.getItem('token');
			document.title = `${i18n.t('logout')} | ${process.env.REACT_APP_NAME}`;
			if (token) {
				await doLogout(token);
				sessionStorage.removeItem('token');
			} else {
				sessionStorage.removeItem('token');
			}

			navigate('/login');
		};
		goLogout();
	}, []);

	return <Loader show={true} />;
};

export default Logout;
