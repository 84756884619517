/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { i18n } from '../../utils/translates/i18n';
import { useNavigate } from 'react-router-dom';
import LoaderPage from '../../utils/LoaderPage';
import { getMandalas } from '../../services/mmn/MandalasService';
import MandalaImg from './MandalaImg';
import Toast from '../../components/Toast/Toast';

export default function Mandala() {
	const navigate = useNavigate();
	const [show, setShow] = useState(false);
	const [mandalas, setMandalas] = useState([]);

	const doGetMandalas = async token => {
		const success = await getMandalas(token);
		if (success.status) {
			setMandalas(success.mandalas.rows);
		} else navigate('/notAuthorized');
	};

	useEffect(() => {
		document.title = `${i18n.t('mandala')} | ${process.env.REACT_APP_NAME}`;
		const doCheck = async () => {
			const token = sessionStorage.getItem('token');
			if (!token) navigate('/logout');
			await doGetMandalas(token);
			setShow(true);
		};
		doCheck();
	}, []);

	return !show ? (
		<LoaderPage />
	) : (
		<>
			<div className='container -fluid'>
				<div className='alert alert-default'>
					<h3>{i18n.t('mandalas')}</h3>
				</div>
				{mandalas?.map((m, i) => (
					<div className='row' key={i}>
						<div className='col'>
							<div className='card radius-15'>
								<div className='card-header bg-dark'>{`${i18n.t('mandala')} # ${m.id}`}</div>
								<div className='list-group'>
									{m.positions?.map((p, ip) => (
										<div className='list-group-item py-1' key={ip}>
											<span
												className={`btn btn-${
													p.position >= 8
														? 'danger'
														: p.position >= 4
														? 'warning'
														: p.position >= 2
														? 'info'
														: 'success'
												} btn-sm me-2`}>
												{p.position}
											</span>
											<span>{p.user?.name?.toUpperCase()}</span>
										</div>
									))}
								</div>
							</div>
						</div>
						<div className='col-md'>
							<MandalaImg data={m} />
						</div>
					</div>
				))}
			</div>
			<Toast />
		</>
	);
}
