/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { searchSymbols } from '../../services/SymbolsService';
import SymbolModal from './SymbolModal';
import SymbolRow from './SymbolRow';
import SelectQuote, { getDefaultQuote, setDefaultQuote } from '../../components/SelectQuote/SelectQuote';
import Toast from '../../components/Toast/Toast';

import { i18n } from '../../utils/translates/i18n';
import Pagination from '../../utils/pagination/Pagination';
import { SettingPage } from '../../utils/pagination/Settings';
import { useNavigate } from 'react-router-dom';
import { CheckPrivateAdmin } from '../../utils/CheckPrivateAdmin';
import LoaderPage from '../../utils/LoaderPage';

function Symbols() {
	const navigate = useNavigate();
	const [show, setShow] = useState(false);
	const [symbols, setSymbols] = useState([]);
	const [quote, setQuote] = useState(getDefaultQuote());
	const [notification, setNotification] = useState({});
	const [viewSymbol, setViewSymbol] = useState({
		symbol: '',
		basePrecision: '',
		quotePrecision: '',
		minNotional: '',
		minLotSize: '',
	});

	function loadSymbols(selectedValue) {
		const search = selectedValue === 'FAVORITES' ? '' : selectedValue;
		const onlyFavorites = selectedValue === 'FAVORITES';
		searchSymbols(search, onlyFavorites, page)
			.then(result => {
				setSymbols(result.rows);
				setCount(result.count);
			})
			.catch(err => errorHandling(err));
	}

	// PARA PAGINAÇÃO
	const token = sessionStorage.getItem('token');
	const [search, setSearch] = useState('');
	const [count, setCount] = useState(0);
	const [page, setPage] = useState(1);
	const [lim, setLims] = useState(10);
	SettingPage({ setting: setPage });

	const doSearch = () => {
		setPage(1);
		loadSymbols(quote);
	};

	useEffect(() => {
		loadSymbols(quote);
	}, [token, page, lim, quote]);
	// FIM PARA PAGINAÇÃO

	function onQuoteChange(event) {
		setQuote(event.target.value);
		setDefaultQuote(event.target.value);
	}

	function errorHandling(err) {
		console.error(err.response ? err.response.data : err.message);
		setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
	}

	function onViewClick(event) {
		const coinpair = event.target.id.replace('view', '');
		const symbol = symbols.find(s => s.symbol === coinpair);
		setViewSymbol({ ...symbol });
	}

	useEffect(() => {
		const doCheck = async () => {
			document.title = `${i18n.t('symbols')} | ${process.env.REACT_APP_NAME}`;
			const token = sessionStorage.getItem('token');
			if (!token) navigate('/logout');
			const check = await CheckPrivateAdmin(token);
			if (!check || !check.isActive) navigate('/notAuthorized');
			if (!check.accessKey) navigate('/traderbot/settings');
			setShow(true);
		};
		doCheck();
	}, []);

	return !show ? (
		<LoaderPage />
	) : (
		<React.Fragment>
			<section className='section'>
				<div className='container-fluid'>
					<div className='row py-4'>
						<div className='col-10'>
							<h2 className='h4'>Symbols</h2>
						</div>
						<div className='col-2'>
							<SelectQuote onChange={onQuoteChange} value={quote} />
						</div>
					</div>
					<Pagination count={count} pageSize={lim}>
						<div className='input-group mx-3'>
							<input
								type='text'
								className='form-control'
								placeholder={`${i18n.t('search')} ${i18n.t('subject')} / ${i18n.t('description')}`}
								value={search}
								onChange={e => setSearch(e.target.value)}
								onBlur={doSearch}
							/>
							<button className='btn input-group-text btn-dark' onClick={doSearch}>
								<i className='fas fa-search'></i>
							</button>
						</div>
						<select className='form-select w-50' value={lim} onChange={e => setLims(e.target.value)}>
							<option className='bg-light' value='5'>
								5 {i18n.t('registers')}
							</option>
							<option className='bg-light' value='10'>
								10 {i18n.t('registers')}
							</option>
							<option className='bg-light' value='25'>
								25 {i18n.t('registers')}
							</option>
							<option className='bg-light' value='50'>
								50 {i18n.t('registers')}
							</option>
							<option className='bg-light' value='100'>
								100 {i18n.t('registers')}
							</option>
						</select>
					</Pagination>
					<div className='card card-body border-0 shadow table-wrapper table-responsive'>
						<table className='table table-hover'>
							<thead>
								<tr>
									<th className='border-gray-200'>Symbol</th>
									<th className='border-gray-200'>Base Prec</th>
									<th className='border-gray-200'>Quote Prec</th>
									<th className='border-gray-200'>Min Notional</th>
									<th className='border-gray-200'>Min Lot Size</th>
									<th className='border-gray-200'>Actions</th>
								</tr>
							</thead>
							<tbody>
								{symbols && symbols.length ? (
									symbols.map(item => <SymbolRow key={item.symbol} data={item} onClick={onViewClick} />)
								) : (
									<React.Fragment></React.Fragment>
								)}
							</tbody>
						</table>
					</div>
				</div>
			</section>
			<Toast text={notification.text} type={notification.type} />
			<SymbolModal data={viewSymbol} />
		</React.Fragment>
	);
}

export default Symbols;
