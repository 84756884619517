import axios from './BaseService';
const API_URL = process.env.REACT_APP_API_URL;

export const getWallets = async token => {
	const endPoint = `${API_URL}/wallets`;
	const headers = { 'Content-Type': 'application/json', Authorization: `${token}` };
	const response = await axios.get(endPoint, { headers });
	return response.data;
};

export const getWalletPreferred = async token => {
	const endPoint = `${API_URL}/wallets/preferred`;
	const headers = { 'Content-Type': 'application/json', Authorization: `${token}` };
	const response = await axios.get(endPoint, { headers });
	return response.data;
};

export const isValidWallet = async (token, wallet) => {
	const endPoint = `${API_URL}/wallets/isValidWallet`;
	const headers = { 'Content-Type': 'application/json', Authorization: `${token}` };
	const response = await axios.post(endPoint, { wallet }, { headers });
	return response.data;
};

export const addWallet = async (token, wallet, securityPassword) => {
	const endPoint = `${API_URL}/wallets/addWallet`;
	const headers = { 'Content-Type': 'application/json', Authorization: `${token}` };
	const response = await axios.post(endPoint, { wallet, securityPassword }, { headers });
	return response.data;
};
