/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { i18n } from '../utils/translates/i18n';
import { useNavigate } from 'react-router-dom';
import { CheckPrivateAdmin } from '../utils/CheckPrivateAdmin';
import Loader from '../utils/Loader';
import { SettingPage } from '../utils/pagination/Settings';
import Pagination from '../utils/pagination/Pagination';
import { getOrders } from '../services/mmn/OrdersService';
import OrderRow from './OrderRow';
import LoaderPage from '../utils/LoaderPage';
import Toast from '../components/Toast/Toast';

export default function Order() {
	const navigate = useNavigate();
	const [show, setShow] = useState(false);
	const [loader, setLoader] = useState(false);

	const [orders, setOrders] = useState([]);

	// PARA PAGINAÇÃO
	const token = sessionStorage.getItem('token');
	const [search, setSearch] = useState('');
	const [count, setCount] = useState(0);
	const [page, setPage] = useState(1);
	const [lim, setLims] = useState(10);
	SettingPage({ setting: setPage });

	const doGetOrders = async () => {
		const success = await getOrders(token, page, lim, search);
		if (success.status) {
			setOrders(success.list.rows);
			setCount(success.list.count);
		}
	};

	useEffect(() => {
		doGetOrders();
	}, [token, page, lim, search]);
	// FIM PARA PAGINAÇÃO

	useEffect(() => {
		const doCheck = async () => {
			document.title = `${i18n.t('orders')} | ${process.env.REACT_APP_NAME}`;
			const token = sessionStorage.getItem('token');
			if (!token) navigate('/logout');
			const check = await CheckPrivateAdmin(token);
			if (!check) navigate('/logout');
			setShow(true);
		};
		doCheck();
	}, []);

	return !show ? (
		<LoaderPage />
	) : (
		<>
			<div className='container-fluid'>
				<Pagination count={count} pageSize={lim}>
					<div className='align-items-center'>
						<div className='alert bg-dark m-0 py-2 d-flex justify-content-around align-items-center'>
							<i className='fas fa-dollar me-2'></i>
							<span className='fw-bold '>{i18n.t('orders')}</span>
						</div>
					</div>
					<div className='input-group mx-3'>
						<select className='form-select' value={search} onChange={e => setSearch(e.target.value)}>
							<option value='%'>{i18n.t('all')}</option>
							<option value='add'>{i18n.t('add')}</option>
							<option value='upgrade'>{i18n.t('upgrade')}</option>
							<option value='renew'>{i18n.t('renew')}</option>
							<option value='monthly'>{i18n.t('monthly')}</option>
						</select>
					</div>
					<select className='form-select w-50' value={lim} onChange={e => setLims(e.target.value)}>
						<option className='bg-light' value='5'>
							5 {i18n.t('registers')}
						</option>
						<option className='bg-light' value='10'>
							10 {i18n.t('registers')}
						</option>
						<option className='bg-light' value='25'>
							25 {i18n.t('registers')}
						</option>
						<option className='bg-light' value='50'>
							50 {i18n.t('registers')}
						</option>
						<option className='bg-light' value='100'>
							100 {i18n.t('registers')}
						</option>
					</select>
				</Pagination>
				<div className='row'>
					<div className='col'>
						<div className='card radius-15'>
							<div className='card-body'>
								<ul className='list-group'>
									{orders?.map((o, i) => (
										<OrderRow o={o} key={i} />
									))}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Toast />
			<Loader show={loader} onHide={() => setLoader(false)} />
		</>
	);
}
