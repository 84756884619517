import axios from './BaseService';
const API_URL = process.env.REACT_APP_API_URL;

export const sendCall = async (data, token) => {
	const endPoint = `${API_URL}/calls/add`;
	const headers = {
		'Content-Type': 'application/json',
		Authorization: `${token}`,
	};
	const response = await axios.post(endPoint, data, { headers });
	return response.data;
};

export const getCalls = async (token, page, limit, search) => {
	const endPoint = `${API_URL}/calls/list${page ? `?page=${page}` : ''}`;
	const headers = {
		'Content-Type': 'application/json',
		Authorization: `${token}`,
	};
	const response = await axios.post(endPoint, { limit, search }, { headers });
	return response.data;
};

export const getCallMessages = async (callId, token) => {
	const endPoint = `${API_URL}/calls/call/${callId}`;
	const headers = {
		'Content-Type': 'application/json',
		Authorization: `${token}`,
	};
	const response = await axios.get(endPoint, { headers });
	return response.data;
};

export const sendCallMessages = async (data, token) => {
	const endPoint = `${API_URL}/calls/reply`;
	const headers = {
		'Content-Type': 'application/json',
		Authorization: `${token}`,
	};
	const response = await axios.post(endPoint, data, { headers });
	return response.data;
};
