import React from 'react';
import { i18n } from '../../utils/translates/i18n';

const Footer = () => {
	return (
		<>
			<div className='footer_part'>
				<div className='container-fluid'>
					<div className='row'>
						<div className='col-lg-12'>
							<div className='footer_iner text-center'>
								<p>
									{new Date().getFullYear()} © {process.env.REACT_APP_NAME}. {i18n.t('allRightsReserved')}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Footer;
