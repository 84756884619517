import { positionsCss } from './positionsCss';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

export default function MandalaImg({ data }) {
	return (
		<div style={{ position: 'relative', height: '370px' }}>
			<img
				src={`/img/mandala/mandala.svg`}
				alt={`mandala`}
				crossOrigin='anonymous'
				style={{ position: 'absolute', opacity: '0.3' }}
			/>
			{data.positions?.map((p, i) => (
				<OverlayTrigger
					placement='left'
					overlay={
						<Popover id='popover-basic'>
							<Popover.Header as='h3'>{`${p.position}º ${p.user?.nick}`}</Popover.Header>
							<Popover.Body>
								<div className='text-center'>
									<img
										src={p.user?.photo ? `${process.env.REACT_APP_API_URL}/images/${p.user?.photo}` : '/fotos/user.png'}
										alt={p.user?.nick}
										className={`img-thumbnail img-fluid`}
										style={{ width: '90px', height: '90px' }}
										crossOrigin='anonymous'
									/>
									<p className='text-dark fw-bold'>{p.user?.name}</p>
								</div>
							</Popover.Body>
						</Popover>
					}>
					<div
						key={i}
						// className={`badge bg-${positionsCss.filter(f => f.position === p.position)[0]?.bg}`}
						style={{
							...positionsCss.filter(f => f.position === p.position)[0]?.css,
							position: 'absolute',
							// zIndex: '999',
						}}>
						<img
							src={p.user?.photo ? `${process.env.REACT_APP_API_URL}/images/${p.user?.photo}` : '/fotos/user.png'}
							alt={p.user?.nick}
							style={{ width: '40px', height: '40px' }}
							className={`rounded-circle bg-${positionsCss.filter(f => f.position === p.position)[0]?.bg}`}
							crossOrigin='anonymous'
						/>
					</div>
				</OverlayTrigger>
			))}
			{/* <pre>{JSON.stringify(data.positions, null, 4)}</pre> */}
		</div>
	);
}
