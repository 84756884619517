/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const SettingPage = ({ setting }) => {
    const defaultLocation = useLocation();
    const useQuery = () => new URLSearchParams(defaultLocation.search);
    const query = useQuery();
    const [page, setPage] = useState(query.get("page") ? parseInt(query.get("page")) : 1);
    useEffect(() => setPage(query.get("page")), [query]);
    useEffect(() => setting(page), [page]);
};
