import axios from './BaseService';
const API_URL = process.env.REACT_APP_API_URL;

export const getDashMain = async token => {
	const endPoint = `${API_URL}/main/getDashMain`;
	const headers = {
		'Content-Type': 'application/json',
		Authorization: `${token}`,
	};
	const response = await axios.get(endPoint, { headers });
	return response.data;
};
