/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { i18n } from '../utils/translates/i18n';
import { CheckPrivateAdmin } from '../utils/CheckPrivateAdmin';

const NotFound = () => {
	const navigate = useNavigate();

	useEffect(() => {
		document.title = `${i18n.t('not_authorized')} | ${process.env.REACT_APP_NAME}`;

		const doCheck = async () => {
			const token = sessionStorage.getItem('token');
			if (!token) navigate('/logout');
			const check = await CheckPrivateAdmin(token);
			if (!check) navigate('/logout');
		};
		doCheck();
	}, []);

	return (
		<div
			style={{
				padding: '50px',
				minWidth: '100%',
				height: '100%',
				background: 'url(/assets/images/space-bg.jpg) no-repeat center',
				backgroundSize: 'cover',
			}}>
			<div className='row'>
				<div
					style={{ background: 'rgba(0,0,0,.5)', padding: '30px', borderRadius: '50px' }}
					className='col-md-6 ms-md-5 text-center'>
					<i className='fas fa-triangle-exclamation fa-5x text-warning'></i>
					<h3 className='text-light display-5 fw-bold'>{i18n.t('not_authorized')}</h3>
					<h4 className='text-light'>{i18n.t('not_permission')}</h4>
					<Link to='/dashboard' className='btn btn-warning text-dark me-3 mt-3'>
						<i className='fas fa-home'></i> {i18n.t('home')}
					</Link>
					<Link to='/logout' className='btn btn-danger text-light me-3 mt-3'>
						<i className='fas fa-sign-out'></i> {i18n.t('logout')}
					</Link>
				</div>
				<div className='col-md-3 m-auto d-none d-md-block'>
					<img src='/logos/logo.png' alt='logo' className='img-fluid' />
				</div>
			</div>
		</div>
	);
};

export default NotFound;
