/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Ticker from './Ticker/Ticker';
import Book from './Book/Book';
import useWebSocket from 'react-use-websocket';
import Wallet from '../../components/Wallet/Wallet';
import NewOrderButton from '../../components/NewOrder/NewOrderButton';
import NewOrderModal from '../../components/NewOrder/NewOrderModal';
import CandleChart from './CandleChart';
import SelectSymbol from '../../components/SelectSymbol/SelectSymbol';
import Toast from '../../components/Toast/Toast';
import { CheckPrivateAdmin } from '../../utils/CheckPrivateAdmin';

import LoaderPage from '../../utils/LoaderPage';
import { i18n } from '../../utils/translates/i18n';

function Dashboard() {
	const navigate = useNavigate();
	const [show, setShow] = useState(false);
	const [tickerState, setTickerState] = useState({});

	const [balanceState, setBalanceState] = useState({});

	const [bookState, setBookState] = useState({});

	const [wallet, setWallet] = useState({});

	const [chartSymbol, setChartSymbol] = useState('BTCUSDT');

	const [notification, setNotification] = useState({ type: '', text: '' });

	const { lastJsonMessage } = useWebSocket(process.env.REACT_APP_WS_URL, {
		onOpen: () => {
			console.log(`Connected to App WS`);
		},
		onMessage: () => {
			if (lastJsonMessage) {
				if (lastJsonMessage.ticker) setTickerState(lastJsonMessage.ticker);
				else if (lastJsonMessage.balance) {
					setBalanceState(lastJsonMessage.balance);
				} else if (lastJsonMessage.book) {
					lastJsonMessage.book.forEach(b => (bookState[b.symbol] = b));
					setBookState(bookState);
				}
			}
		},
		queryParams: { token: sessionStorage.getItem('token') },
		onError: event => {
			console.error(event);
			setNotification({ type: 'error', text: event });
		},
		shouldReconnect: closeEvent => true,
		reconnectInterval: 3000,
	});

	function onWalletUpdate(walletObj) {
		setWallet(walletObj);
	}

	function onSubmitOrder(order) {
		navigate('/traderbot/orders/' + order.symbol);
	}

	function onChangeSymbol(event) {
		setChartSymbol(event.target.value);
	}

	useEffect(() => {
		const doCheck = async () => {
			document.title = `${i18n.t('dashboard')} | ${process.env.REACT_APP_NAME}`;
			const token = sessionStorage.getItem('token');
			if (!token) navigate('/logout');
			const check = await CheckPrivateAdmin(token);
			if (!check || !check.isActive) navigate('/notAuthorized');
			if (!check.accessKey) navigate('/traderbot/settings');
			setShow(true);
		};
		doCheck();
	}, []);

	return !show ? (
		<LoaderPage />
	) : (
		<React.Fragment>
			<section className='section'>
				<div className='container-fluid'>
					<div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
						<div className='d-block mb-4 mb-md-0'>
							<span className='h4'>{i18n.t('dashboard')}</span>
						</div>
						<div className='btn-toolbar mb-md-0'>
							<div className='ms-2 ms-lg-3 d-inline-flex align-items-center'>
								<SelectSymbol onChange={onChangeSymbol} symbol={chartSymbol} />
							</div>
							<div className='ms-2 ms-lg-3 d-inline-flex align-items-center'>
								<NewOrderButton />
							</div>
							<div className='ms-2 ms-lg-3 d-inline-flex align-items-center'>
								<Link className='btn btn-primary d-inline-flex align-items-center' to='/traderbot/orders'>
									<i className='fas fa-credit-card me-2'></i>
									<span className='d-none d-md-flex'>{i18n.t('b_orders')}</span>
								</Link>
							</div>
							{/* <div className='ms-2 ms-lg-3 d-none d-md-flex d-inline-flex align-items-center'>
								<Link className='btn btn-primary' to='/traderbot/automations'>
									{i18n.t('automations')}
								</Link>
							</div>
							<div className='ms-2 ms-lg-3 d-none d-md-flex d-inline-flex align-items-center'>
								<Link className='btn btn-primary' to='/traderbot/orderTemplates'>
									{i18n.t('actionTemplates')}
								</Link>
							</div> */}
						</div>
					</div>
					<CandleChart symbol={chartSymbol} />
					<div className='row'>
						<div className='col-12'>
							<Ticker data={tickerState} />
						</div>
					</div>
					<div className='row'>
						<Book data={bookState} />
						<Wallet data={balanceState} onUpdate={onWalletUpdate} />
					</div>
				</div>
			</section>
			<NewOrderModal wallet={wallet} onSubmit={onSubmitOrder} />
			<Toast type={notification.type} text={notification.text} />
		</React.Fragment>
	);
}

export default Dashboard;
