/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NewWithdrawTemplateButton from './NewWithdrawTemplateButton';
import WithdrawTemplateRow from './WithdrawTemplateRow';
import WithdrawTemplateModal from './WithdrawTemplateModal/WithdrawTemplateModal';
import Toast from '../../components/Toast/Toast';
import { getWithdrawTemplates, deleteWithdrawTemplate } from '../../services/WithdrawTemplatesService';
import { doWithdraw } from '../../services/ExchangeService';

import { i18n } from '../../utils/translates/i18n';
import Pagination from '../../utils/pagination/Pagination';
import { SettingPage } from '../../utils/pagination/Settings';
import { CheckPrivateAdmin } from '../../utils/CheckPrivateAdmin';

import LoaderPage from '../../utils/LoaderPage';

function WithdrawTemplates() {
	const navigate = useNavigate();
	const [show, setShow] = useState(false);

	const [withdrawTemplates, setWithdrawTemplates] = useState([]);
	const [notification, setNotification] = useState([]);
	const [editWithdrawTemplate, setEditWithdrawTemplate] = useState({});

	// PARA PAGINAÇÃO
	const token = sessionStorage.getItem('token');
	const [search, setSearch] = useState('');
	const [count, setCount] = useState(0);
	const [page, setPage] = useState(1);
	const [lim, setLims] = useState(10);
	SettingPage({ setting: setPage });

	const loading = async () => {
		getWithdrawTemplates('', page || 1)
			.then(result => {
				setWithdrawTemplates(result.rows ? result.rows : []);
				setCount(result.count);
				setEditWithdrawTemplate(result.rows && result.rows.length > 0 ? result.rows[0] : {});
			})
			.catch(err => {
				console.error(err.response ? err.response.data : err.message);
				setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
			});
	};

	const doSearch = () => {
		setPage(1);
		loading();
	};

	useEffect(() => {
		loading();
	}, [token, page, lim]);
	// FIM PARA PAGINAÇÃO

	function onEditClick(event) {
		const id = event.target.id.replace('edit', '');
		// eslint-disable-next-line
		const template = withdrawTemplates.find(o => o.id == id);
		setEditWithdrawTemplate({ ...template });
	}

	function onDeleteClick(event) {
		const id = event.target.id.replace('delete', '');
		deleteWithdrawTemplate(id)
			.then(result => loading())
			.catch(err => {
				console.error(err.response ? err.response.data : err.message);
				setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
			});
	}

	function onRunClick(event) {
		const id = event.target.id.replace('run', '');
		doWithdraw(id)
			.then(result => setNotification({ type: 'success', text: `Withdrawal #${result.id} successful!` }))
			.catch(err => {
				console.error(err.response ? err.response.data : err.message);
				setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
			});
	}

	function onWithdrawTemplateSubmit(template) {
		loading();
	}

	useEffect(() => {
		const doCheck = async () => {
			document.title = `${i18n.t('withdrawal_templates')} | ${process.env.REACT_APP_NAME}`;
			const token = sessionStorage.getItem('token');
			if (!token) navigate('/logout');
			const check = await CheckPrivateAdmin(token);
			if (!check || !check.isActive) navigate('/notAuthorized');
			if (!check.accessKey) navigate('/traderbot/settings');
			setShow(true);
		};
		doCheck();
	}, []);

	return !show ? (
		<LoaderPage />
	) : (
		<React.Fragment>
			<section className='section'>
				<div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
					<div className='d-block mb-4 mb-md-0'>
						<h2 className='h4'>Withdraw Templates</h2>
					</div>
					<div className='btn-toolbar mb-2 mb-md-0'>
						<div className='d-inline-flex align-items-center'>
							<NewWithdrawTemplateButton />
						</div>
					</div>
				</div>
				<Pagination count={count} pageSize={lim}>
					<span>
						<svg className='icon icon-xs' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
							<path d='M3 4a1 1 0 011-1h12a1 1 0 011 1v2a1 1 0 01-1 1H4a1 1 0 01-1-1V4zM3 10a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H4a1 1 0 01-1-1v-6zM14 9a1 1 0 00-1 1v6a1 1 0 001 1h2a1 1 0 001-1v-6a1 1 0 00-1-1h-2z' />
						</svg>
					</span>
					<div className='input-group mx-3'>
						<input
							type='text'
							className='form-control'
							placeholder={`${i18n.t('search')} ${i18n.t('subject')} / ${i18n.t('description')}`}
							value={search}
							onChange={e => setSearch(e.target.value)}
							onBlur={doSearch}
						/>
						<button className='btn input-group-text btn-dark' onClick={doSearch}>
							<i className='fas fa-search'></i>
						</button>
					</div>
					<select className='form-select w-50' value={lim} onChange={e => setLims(e.target.value)}>
						<option className='bg-light' value='5'>
							5 {i18n.t('registers')}
						</option>
						<option className='bg-light' value='10'>
							10 {i18n.t('registers')}
						</option>
						<option className='bg-light' value='25'>
							25 {i18n.t('registers')}
						</option>
						<option className='bg-light' value='50'>
							50 {i18n.t('registers')}
						</option>
						<option className='bg-light' value='100'>
							100 {i18n.t('registers')}
						</option>
					</select>
				</Pagination>
				<div className='card card-body border-0 shadow table-wrapper table-responsive'>
					<table className='table table-hover'>
						<thead>
							<tr>
								<th className='border-gray-200'>Coin</th>
								<th className='border-gray-200'>Name</th>
								<th className='border-gray-200'>Amount</th>
								<th className='border-gray-200'>Actions</th>
							</tr>
						</thead>
						<tbody>
							{withdrawTemplates && withdrawTemplates.length ? (
								withdrawTemplates.map(ot => (
									<WithdrawTemplateRow
										key={ot.id}
										data={ot}
										onEditClick={onEditClick}
										onDeleteClick={onDeleteClick}
										onRunClick={onRunClick}
									/>
								))
							) : (
								<React.Fragment></React.Fragment>
							)}
						</tbody>
					</table>
				</div>
			</section>
			<WithdrawTemplateModal data={editWithdrawTemplate} onSubmit={onWithdrawTemplateSubmit} />
			<Toast type={notification.type} text={notification.text} />
		</React.Fragment>
	);
}

export default WithdrawTemplates;
