/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { i18n } from '../../utils/translates/i18n';
import { useNavigate } from 'react-router-dom';
import LoaderPage from '../../utils/LoaderPage';
import Toast from '../../components/Toast/Toast';
import { editUser, getMyUserData } from '../../services/mmn/UsersService';

export default function BotSettings() {
	// const token = process.env.REACT_APP_USER_TOKEN;
	const token = sessionStorage.getItem('token');
	const navigate = useNavigate();

	const DEFAULT_DATA = {
		id: '',
		accessKey: '',
		secretKey: '',
		futuresKey: '',
		futuresSecret: '',
	};

	const [data, setData] = useState(DEFAULT_DATA);
	const [show, setShow] = useState(false);
	const [notification, setNotification] = useState({});
	const [copySuccess, setCopySuccess] = useState('');
	const [hint, setHint] = useState(false);

	const doGetDatas = async () => {
		const success = await getMyUserData(token);
		if (success.status)
			setData({
				id: success.user.id,
				accessKey: success.user.accessKey,
				secretKey: '',
				futuresKey: success.user.futuresKey,
				futuresSecret: success.user.futuresSecret,
			});
		if (!success.user.accessKey) setHint(true);
		else setHint(false);
	};

	const onInputChange = (i, v) => setData(p => ({ ...p, [i]: v }));

	const doSaveForm = async () => {
		setShow(false);
		const success = await editUser(data.id, data, token);
		if (success.status) {
			await doGetDatas();
			setShow(true);
		} else {
			setShow(true);
			setNotification({ text: i18n.t('not_self_register'), type: 'error' });
		}
	};

	function copyIpToClipboard() {
		navigator.clipboard.writeText('172.105.78.139');
		navigator.clipboard
			.readText()
			.then(t => console.log(t))
			.catch(e => console.log(e));
		setCopySuccess(i18n.t('copied'));
	}

	useEffect(() => {
		const doCheck = async () => {
			document.title = `${i18n.t('settings')} Binance | ${process.env.REACT_APP_NAME}`;
			if (!token) navigate('/logout');
			await doGetDatas();
			setShow(true);
		};
		doCheck();
	}, []);

	return !show ? (
		<LoaderPage />
	) : (
		<>
			<section className='section'>
				<div className='container-fluid'>
					<div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
						<div className='d-block mb-4 mb-md-0'>
							<span className='h4'>{i18n.t('settings')} Binance</span>
							{/* <pre>{JSON.stringify(data, null, 4)}</pre> */}
						</div>
					</div>
					{hint && (
						<div className='alert alert-default bg-light shadow'>
							<div className='row'>
								<div className='col-md-2 d-flex align-items-center'>
									<button className='btn btn-primary h-100 ms-auto' onClick={copyIpToClipboard}>
										<i className='fa fa-copy'></i> {copySuccess ? copySuccess : i18n.t('copy')} IP
									</button>
								</div>
								<div className='col-md-10'>
									<a
										href='https://www.binance.com/pt-BR/support/faq/como-criar-uma-api-360002502072'
										target='_blank'
										rel='noopener noreferrer'
										className='btn btn-primary mb-2'>
										<i className='fas fa-question rounded-circle bg-light px-2 py-1 me-2'></i>
										{i18n.t('how_create_binance_apikey')}
									</a>
									<br />
									<span className=' fw-bold' style={{ whiteSpace: 'pre-line' }}>
										{i18n.t('text_hint_binance')}
									</span>
								</div>
							</div>
						</div>
					)}
					<div className='row'>
						<div className='col-12'>
							<div className='card card-body border-0 shadow mb-4'>
								<div className='row'>
									<div className='col-12 mb-3'>
										<div className='form-group'>
											<label>Spot Access Key</label>
											<input
												className='form-control'
												id='accessKey'
												type='text'
												placeholder='Enter the Spot API Access Key'
												value={data.accessKey || ''}
												onChange={e => onInputChange(e.target.id, e.target.value)}
											/>
										</div>
									</div>
								</div>
								<div className='row'>
									<div className='col-12 mb-3'>
										<div className='form-group'>
											<label>{i18n.t('new')} Spot Secret Key</label>
											<input
												className='form-control'
												id='secretKey'
												type='password'
												placeholder='Enter your new Spot API Secret Key'
												value={data.secretKey || ''}
												onChange={e => onInputChange(e.target.id, e.target.value)}
											/>
										</div>
									</div>
								</div>
								{data.limitBot?.hasFutures && (
									<>
										<div className='row'>
											<div className='col-12 mb-3'>
												<div className='form-group'>
													<label>Futures Access Key</label>
													<input
														className='form-control'
														id='futuresKey'
														type='text'
														placeholder='Enter the Futures API Access Key'
														value={data.futuresKey || ''}
														onChange={e => onInputChange(e.target.id, e.target.value)}
													/>
												</div>
											</div>
										</div>
										<div className='row'>
											<div className='col-12 mb-3'>
												<div className='form-group'>
													<label>New Futures Secret Key</label>
													<input
														className='form-control'
														id='futuresSecret'
														type='password'
														placeholder='Enter your new Futures API Secret Key'
														value={data.futuresSecret || ''}
														onChange={e => onInputChange(e.target.id, e.target.value)}
													/>
												</div>
											</div>
										</div>
									</>
								)}
								<div className='row'>
									<div className='d-flex justify-content-between flex-wrap flex-md-nowrap'>
										<div className='col-sm-3'>
											<button className='btn btn-primary mt-2' type='button' onClick={doSaveForm}>
												{i18n.t('save')}
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<Toast text={notification.text} type={notification.type} />
		</>
	);
}
