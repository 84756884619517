const messages = {
	ru: {
		translations: {
			a_crase: '',
			about: 'О нас',
			above_the_same: 'Сверху точно так же',
			action: 'Действие',
			actionTemplates: 'Стандартные модели',
			activate_date: 'дата активации',
			ad: 'Объявление',
			add: 'Присоединение',
			add_option: 'добавить опцию',
			add_payment: 'Добавить платеж',
			add_question: 'Добавить вопрос',
			add_receipt: 'Добавить квитанцию',
			add_wallet: 'Добавить кошелек',
			address: 'Адрес',
			admin: 'Администрация',
			advance: 'Продвигать',
			affected: 'достиг',
			after: 'После',
			air_transport: 'Воздушный транспорт',
			ajust_limite: 'Регулировка предела',
			ajust_saldo: 'Регулировка баланса',
			all: 'Все',
			all_departments: 'Все отделы',
			allies_of: 'союзники',
			allRightsReserved: 'Все права защищены.',
			almost_there: 'Почти готово',
			already_offset: 'уже компенсировано',
			already_registered: 'Уже зарегистрирован? Нажмите здесь и введите',
			amount: 'Ценить',
			and: 'Это',
			and_more: 'и более',
			answer: 'Ответ',
			ao: 'к',
			approve: 'Согласовать',
			April: 'апрель',
			asc: 'Асцендент',
			at: 'в',
			August: 'Август',
			automations: 'Автоматизация',
			awaiting_email_confirmation: 'Ожидание подтверждения по электронной почте!',
			b_order: 'Заказ',
			b_orders: 'заказы',
			back: 'Вернуться назад',
			bairroInteresse: 'Район',
			balance: 'Баланс',
			balance_available: 'Доступный баланс',
			banned: 'Запрещено',
			before_contact: 'Прежде чем связаться с нами',
			binary: 'Бинарный',
			birth: 'Рождение',
			blind_man: 'перевернутый',
			body_monthly:
				'Срок действия вашей ежемесячной платы истек, и ваш BOT TRADER был деактивирован, пожалуйста, оплатите денежный перевод и снова наслаждайтесь своими преимуществами.',
			bonus_saldo_trader: 'Бонус баланса трейдера',
			but_possible_register:
				'Тем не менее, зарегистрироваться можно, но это не будет связано ни с одним из пригласивших друзей.',
			buyer: 'Покупатель',
			by: 'за',
			bye: 'Увидимся позже',
			call: 'Называется',
			call_to: 'Позвонить',
			calls: 'называется',
			cancel: 'Отмена',
			canceled: 'Отменено',
			career_plan: 'Карьерная лестница',
			casa_verde_amarela: 'желтый зеленый дом',
			cash_payment: 'Наличный расчет',
			cash_payment_return: 'Возврат денежных средств',
			cash_receipt: 'кассовый чек',
			change_indicant: 'Индикатор изменения',
			change_photo: 'Измени фотографию',
			change_plan: 'изменить план',
			check_payment: 'Оплата чеком',
			check_payment_return: 'Возврат платежа чеком',
			check_receipt: 'получение по чеку',
			choose: 'Выбирать',
			cidadeInteresse: 'Город',
			city: 'Город',
			claims: 'Претензии',
			clean: 'Чистить',
			click_and_know: 'Нажмите и узнайте',
			click_to_copy_the_wallet: 'Нажмите, чтобы скопировать портфолио',
			client: 'Клиент',
			clients: 'Клиенты',
			cnh: 'Водительское удостоверение (водительское удостоверение)',
			cnhDate: 'Дата выдачи водительских прав',
			cnpj: 'CNPJ',
			cnpj_invalid: 'Недействительный CNPJ',
			color: 'Цвет',
			commercial: 'Коммерческий',
			company: 'Название компании',
			complement: 'Дополнение',
			comporRenda: 'Иди сочиняй доход с кем-нибудь',
			compRenda: 'Тип подтверждения дохода',
			confirm_approve: 'подтвердить одобрение',
			confirm_delete: 'подтвердить удаление',
			confirm_new_password: 'Подтвердите новый пароль',
			confirm_new_password_security: 'Подтвердите новый пароль безопасности',
			confirm_password: 'Подтвердите пароль доступа',
			confirm_password_security: 'Подтвердите пароль безопасности',
			confirmed: 'Подтвержденный',
			confirmed_email: 'подтвержденный адрес электронной почты',
			congratulations: 'Поздравления',
			contact: 'Контакт',
			contract: 'Договор',
			copied: 'скопировано',
			copy: 'Копировать',
			copy_success: 'Успешно скопировано!',
			correspondences: 'Переписка',
			countries: 'страны',
			country: 'Страна',
			cpf: 'КПФ',
			cpf_invalid: 'Неверный CPF',
			create_order_success: 'Заказ успешно создан!',
			created: 'Зарегистрировано в',
			cred: 'Кредит',
			cred_binario: 'Бинарный кредит',
			cred_deb: 'Кредиты/дебеты',
			cred_game_donate: 'Игра пожертвовать кредит',
			cred_ind_direta: 'Косвенный реферальный кредит',
			cred_ind_indireta: 'Прямой реферальный кредит',
			cred_mandala: 'Мандала Кредит',
			cred_pagar_com_saldo: 'Кредитная оплата с баланса',
			cred_pontos: 'Кредитные очки',
			cred_recb_trader: 'Кредит Получить Трейдер',
			cred_renovacao: 'Продление кредита',
			cred_saldo_disp: 'Доступный баланс кредита',
			cred_upgrade: 'Повышение кредита',
			credit_card_payment: 'Оплата кредитной картой',
			credit_card_payment_return: 'Возврат оплаты кредитной картой',
			credit_card_receipt: 'Квитанция кредитной карты',
			current: 'Текущий',
			current_password: 'Текущий пароль',
			current_wallet: 'Текущий портфель',
			dashboard: 'Панель управления',
			data_added: 'Добавлены данные',
			data_saved: 'Сохраненные данные',
			datas: 'Данные',
			date: 'Дата',
			date_first_installment: 'Дата первого платежа',
			deb_de_trader: 'Долг трейдера',
			deb_game_donate: 'Игра Пожертвовать Дебет',
			deb_saldo: 'Дебетовый баланс',
			deb_saldo_disp: 'Доступный дебетовый баланс',
			deb_val_indev: 'Дебетовать неправильную сумму',
			debit_card_payment: 'Оплата дебетовой картой',
			debit_card_payment_return: 'Возврат оплаты дебетовой картой',
			debit_card_receipt: 'Квитанция дебетовой карты',
			debt: 'Дебет',
			debt_td: 'долг',
			December: 'Декабрь',
			decImpRenda: 'объявить подоходный налог',
			deleted_document: 'Документ был удален из вашего реестра',
			department: 'Отделение',
			dependents: 'иждивенцы',
			desc: 'Вниз',
			description: 'Описание',
			details: 'Подробности',
			dev_econom: 'Экономическое развитие',
			developer: 'Разработчик',
			directly_indicated: 'прямо указано',
			directly_indicateds: 'Непосредственно назначенный',
			directs: 'прямой',
			district: 'Район / Район',
			divorced: 'В разводе',
			doa_men_receb: 'Получено ежемесячное пожертвование платформы',
			doacao_td: 'Пожертвование',
			doctorate_complete: 'Докторантура - Полная',
			doctorate_incomplete: 'Докторантура - неполная',
			document: 'Документ',
			document_already_registered: 'Документ уже зарегистрирован',
			documents: 'Документы',
			donate: 'Пожертвовать',
			donor: 'Донор',
			dont_know_zip: 'Не знаете почтовый индекс? кликните сюда',
			dou_fe: 'Я подтверждаю, что вышеуказанная информация верна.',
			downloads: 'Скачать файлы',
			drop_images: 'Нажмите или перетащите изображения сюда',
			due_date: 'Зрелость',
			edit: 'Редактировать',
			edit_option: 'вариант редактирования',
			edit_question: 'редактировать вопрос',
			email: 'Электронная почта',
			email_already_registered: 'Электронная почта уже зарегистрирована',
			email_and_or_password_wrong: 'Неверный адрес электронной почты и/или пароль!',
			email_for_login: 'Этот адрес электронной почты будет вашим логином',
			email_or_password_wrong: 'Неверный адрес электронной почты или пароль',
			employee: 'Сотрудник',
			employees: 'Сотрудники',
			empty: 'Пустой',
			entrada: 'Имеет ли он входную ценность? Если ДА, то какое значение',
			entries_until_today: 'Записи на сегодняшний день',
			error_confirm_password: 'Подтверждение и новый пароль должны совпадать',
			error_email: 'Неверный формат электронной почты',
			error_password: 'Неверный пароль',
			error_phone: 'Неправильный номер телефона',
			estadoInteresse: 'состояние',
			exchange: 'Замена',
			expired: 'Истекший',
			extorno_td: 'возвращаться',
			extra: 'Дополнительный',
			extract: 'Извлекать',
			extracts: 'экстракты',
			fantasy: 'Фантазия',
			fatherName: 'Имя Отца',
			February: 'февраль',
			fee: 'Комиссия за снятие средств',
			feedback: 'Возвращаться',
			feminine: 'Женский',
			fgts: 'Стоимость вашего ФГТС',
			field_required: 'обязательное поле',
			final: 'Финал',
			finance: 'финансовый',
			financial: 'финансовый',
			financial_payment: 'финансовый платеж',
			financial_payment_return: 'Возврат финансового платежа',
			financial_receipt: 'финансовый чек',
			financing: 'Финансирование',
			financings: 'Финансирование',
			first_10_pages: 'первые 10 страниц',
			fisica: 'Физическое лицо',
			for_year: 'Ежегодно',
			forget_password: 'я забыл пароль',
			forgot_password: 'Забыли пароль',
			form: 'Форма',
			form_of_payment: 'Форма оплаты',
			found: 'Найденный',
			founds: 'Найденный',
			friend_indicated: 'Рекомендовано моими друзьями',
			friend_indicateds: 'Рекомендовано моими друзьями',
			fuel: 'Топливо',
			full_name: 'Полное имя',
			funcPublico: 'Вы государственный служащий?',
			fundamental_complete: 'Элементарный - Полный',
			fundamental_incomplete: 'Элементарный - неполный',
			ganh_diario: 'Ежедневный доход',
			gender: 'Пол',
			grand_total: 'общий итог',
			group: 'Группа',
			has_withdraw_today: 'Вывод средств уже был запрошен сегодня',
			home: 'Начинать',
			how_it_works: 'Как это работает',
			how_to_call: 'Как вы хотите, чтобы вас называли?',
			id_td: 'Прямое указание',
			images: 'Изображений',
			in_construction: 'В разработке',
			in_order: 'Чтобы',
			in_stock: 'В наличии',
			indicant: 'Индикатор',
			indicated_by: 'Вы номинированы',
			indicated_not_found: 'Указанный Индикатор не найден...',
			indicateds: 'Указанный',
			indirects: 'Косвенный',
			info: 'Информационные бюллетени',
			info_contact: 'Контактная информация',
			initial: 'Исходный',
			insert_new: 'Вставить новый',
			installments: 'рассрочка',
			intelligence: 'Интеллект',
			interesse: 'Что представляет собой интересующий регион',
			invalid_contact: 'Неверный формат контакта',
			invalid_images: 'недействительные изображения',
			invalid_token: 'Неверный токен',
			invite: 'Пригласить',
			invite_friends: 'Пригласить друзей',
			January: 'январь',
			July: 'Июль',
			June: 'Июнь',
			juridica: 'Законопослушный гражданин',
			know_exact_result: 'Вы знаете точное значение?',
			lead: 'Вместе мы будем строить новое будущее.',
			learn_more: 'Узнать больше',
			left: 'Левый',
			left_preferential: 'Предпочтение левой стороне',
			legalNature: 'Правовая природа',
			level: 'Уровень',
			limite_excedido: 'Превышение лимита дебета',
			linear: 'Линейный',
			link_email: 'Нажмите на ссылку, отправленную на зарегистрированный адрес электронной почты!',
			link_invite_copy: 'Ссылка на общий доступ успешно скопирована',
			liquid: 'Жидкость',
			list: 'Список',
			loading: 'Загрузка',
			location: 'Расположение',
			login: 'Войти',
			logout: 'Выйти',
			male: 'мужской род',
			manage_users: 'Управление пользователями',
			manager: 'Менеджер',
			mandala: 'Бизнес-группа',
			mandalas: 'Бизнес -группы',
			manufacturer: 'Производитель',
			March: 'Маршировать',
			maritalStatus: 'Семейное положение',
			market: 'Рынок',
			married: 'Женатый',
			master_complete: 'Магистр - Полный',
			master_incomplete: 'Магистр - неполный',
			May: 'Может',
			message: 'Сообщение',
			messageNotSent: 'Не удалось отправить ваше сообщение',
			messageSent: 'Ваше сообщение было отправлено успешно',
			middle_complete: 'Средний - Полный',
			middle_incomplete: 'Средний — неполный',
			min_amount_error: 'Значение должно быть не менее:',
			min_withdraw: 'Минимальная сумма для вывода',
			missing: 'Отсутствующий',
			mission: 'Миссия',
			mission_vision_values: 'Миссия, видение и ценности',
			model: 'Модель',
			monitors: 'Мониторы',
			monthly: 'Ежемесячно оплата',
			motherName: 'имя матери',
			movements: 'Движения',
			msg_renovacao: 'Вы достигли предела заработка, обновите пакет.',
			my_account: 'Мой счет',
			my_wallet: 'Мой бумажник',
			mystock: 'Мой запас',
			name: 'Имя',
			nascMaisVelhoRenda2: 'Какова дата рождения старшего?',
			nationality: 'Национальность (Страна рождения)',
			naturalness: 'Место рождения (Город, где вы родились)',
			nature: 'Природа',
			network: 'Сеть',
			new: 'Новый',
			new_call: 'Новое призвание',
			new_financing: 'Новое финансирование',
			new_order: 'Новый заказ',
			new_password: 'Новый пароль',
			new_password_security: 'Новый пароль безопасности',
			new_seller: 'Новый продавец',
			new_wallet: 'Новое портфолио',
			news: 'Новости',
			next: 'Следующий',
			nick: 'Пользователь',
			nis: 'ПИС/НИШ',
			nis_already_registered: 'PIS/NIS уже зарегистрированы',
			no: 'Нет',
			no_approve: 'все еще не одобряю',
			no_delete: 'не исключай',
			no_literate: 'неграмотный',
			no_repeat: 'Данные не могут быть повторены',
			no_results_found: 'результатов не найдено',
			no_services_or_products_added: 'Нет добавленных услуг или продуктов',
			no_settings: 'Перейдите в «Настройки» и заполните свои данные.',
			none_friends_indicated: 'Я еще не пригласил друзей',
			not_authorized: 'Не авторизован',
			not_found: 'Не найдено',
			not_login: 'Не удалось войти, проверьте данные и повторите попытку!',
			not_orders: 'Нет денежных переводов',
			not_permission: 'У вас нет разрешения на доступ к этой странице.',
			not_registered: 'Еще не зарегистрированы? Нажмите здесь и зарегистрируйтесь',
			not_results: 'Записей не найдено',
			not_self_register: 'Не удалось зарегистрироваться',
			not_today_entry: 'У нас до сих пор нет билетов сегодня',
			notfound_ad: 'Объявлений не найдено',
			November: 'ноябрь',
			number: 'Число',
			obs: 'Комментарии',
			October: 'Октябрь',
			of: 'в',
			off: 'Уничтожено',
			on_total: 'от общего',
			open: 'Открыть',
			opening: 'Открытие',
			operation: 'Производительность',
			ordenation: 'рукоположение',
			order: 'Заказ',
			order_not_found: 'заказ не найден',
			orders: 'Запросы',
			other: 'Другой',
			other_services_or_products: 'Другие услуги или продукты',
			paid: 'Выплаченый',
			paid_date: 'день оплаты',
			partial: 'Частично оплачено',
			passport: 'Заграничный пасспорт',
			password: 'Пароль',
			password_security: 'пароль безопасности',
			pay: 'Платить',
			pay_generate: 'генерировать платеж',
			pay_selected: 'Оплатить выбранный',
			pay_value_in: 'оплатить сумму в',
			payable: 'Платить',
			payer: 'Плательщик',
			payment: 'Оплата',
			ped_retirada: 'Запрос на вывод средств',
			ped_retirada_gan_diario: 'Запрос на вывод ежедневной прибыли',
			pendent: 'В ожидании',
			people: 'Люди',
			person: 'Человек',
			person_found_in_other_prefecture: 'Лицо, уже зарегистрированное в другом муниципалитете',
			personal: 'Ребята',
			personal_addresses: 'Адреса',
			personal_contacts: 'Контакты',
			personal_data: 'Личные данные',
			personal_infos: 'Информация',
			personal_pcva: 'Программа «Зеленый и желтый дом»',
			phone: 'Телефон',
			pix: 'ПИКС',
			pix_payment: 'Оплата в PIX',
			pix_payment_return: 'Возврат платежа в PIX',
			pix_receipt: 'Квитанция в формате PIX',
			pl_carreira: 'Карьерная лестница',
			plan: 'Плоский',
			plates: 'Тарелки',
			please_wait: 'пожалуйста, подождите',
			plus: 'плюс',
			point: 'Точка',
			points: 'Точки',
			portfolio: 'портфолио',
			pos_complete: 'Лато сенсу - Полный',
			pos_incomplete: 'Lato sensu - неполный',
			prefecture: 'Ратуша',
			prefecture_addresses: 'Адреса',
			prefecture_contacts: 'Контакты',
			prefecture_data: 'Данные мэрии',
			prefecture_infos: 'Информация',
			prefectures: 'мэрии',
			prev: 'Предыдущий',
			price: 'Цена',
			print: 'Распечатать',
			privacy_policies: 'политика конфиденциальности',
			private_works: 'Частные Работы',
			profession: 'Профессия',
			profile: 'Профиль',
			program: 'Программа',
			programs: 'Программное обеспечение',
			project: 'Проект',
			projects: 'Проекты',
			public_works: 'Общественные работы',
			purchase: 'Покупка',
			qualify_binary: 'Бинарная квалификация',
			qualquer: 'Любой',
			quantity: 'Количество',
			quotation: 'Цена',
			read: 'Читать',
			receivable: 'Получать',
			recipient: 'получатель',
			recommendations: 'Рекомендации',
			redefine_password: 'Переопределить пароль',
			register: 'Записывать',
			register_people: 'Регистрация людей',
			register_wallet: 'Зарегистрируйте кошелек BTC для снятия средств',
			registers: 'Рекорды',
			registers_users: 'Регистрация пользователя',
			reject: 'Отклонять',
			remaining: 'Оставшийся',
			remove: 'Удалять',
			rendaIndividual: 'Индивидуальный валовой доход',
			renew: 'Продлить',
			renew_mandala: 'Обновление мандалы',
			reply: 'Ответить',
			reports: 'отчеты',
			required_data_not_sent: 'Необходимые данные не отправлены',
			resend_email: 'отправить электронное письмо повторно',
			residential: 'Жилой',
			responded: 'Ответил',
			restricao: 'У вас есть ограничение на ваше имя? (СПЦ/Сераса)',
			result: 'Результат',
			results: 'Полученные результаты',
			resume_data: 'Сводка данных',
			revoked: 'отозван',
			rg: 'Общая регистрация (идентификация)',
			rgAgency: 'эмитент РГ',
			rgDate: 'Дата выдачи RG',
			rgUf: 'Идентификационный статус',
			right: 'Верно',
			right_preferential: 'Предпочтение правой стороне',
			roi: 'Ежедневный доход',
			sale: 'Распродажа',
			saq_duplicado: 'Повторный вывод средств',
			save: 'Сохранить',
			schooling: 'Образование',
			search: 'Поиск',
			secretary: 'Секретарь',
			security_password: 'пароль безопасности',
			see: 'Чтобы увидеть',
			see_advents: 'посмотреть объявления',
			see_call: 'увидеть звонок',
			see_details: 'смотрите подробности',
			see_details_wallet: 'Просмотр сведений о портфолио',
			see_financing: 'Посмотреть финансирование',
			see_order: 'Посмотреть заказ',
			see_user: 'Просмотр пользователей',
			select_vehicle: 'выберите автомобиль',
			self_register: 'регистр',
			seller: 'Продавец',
			send_call: 'Отправить билет',
			send_to: 'Отправить',
			September: 'Сентябрь',
			settings: 'настройки',
			share_your_link: 'Поделитесь своей ссылкой',
			show: 'явиться к',
			show_from: 'показ от',
			side: 'Сторона',
			sign_in_to_start_your_session: 'Войдите, чтобы начать сеанс',
			sign_up: 'регистр',
			single: 'Одинокий',
			sold: 'Продал',
			state: 'состояние',
			status: 'Положение дел',
			strategies: 'Стратегии',
			street: 'Дорога',
			subject: 'Предмет',
			submit: 'Отправлять',
			submit_placeholder: 'Пожалуйста, заполните свой адрес электронной почты',
			subscribe: 'Подпишитесь, чтобы получать наши новости',
			subtitle: 'Подпись',
			success_update_user: 'Пользователь успешно обновлен!',
			success_wallet: 'Портфолио успешно обновлено!',
			suggestions: 'предложения',
			superior_complete: 'Окончил',
			superior_incomplete: 'Неполное высшее',
			supervisor: 'Руководитель',
			support: 'Поддерживать',
			support_materials: 'Вспомогательные материалы',
			suspended: 'Приостановленный',
			symbols: 'Символы',
			system: 'Система',
			target: 'Цель',
			tempoCarteira: 'Как долго запись находится в портфолио',
			terms: 'Я принимаю условия использования',
			this_value_in: 'Это значение в',
			title: 'Заголовок',
			to: 'До',
			to_pay: 'Выключать',
			to_receive: 'дебиторская задолженность',
			today_entry: 'Сегодняшняя запись',
			token_not_found_or_already_used: 'Токен не найден или уже используется',
			total: 'Общий',
			total_entry: 'Всего вход',
			traderbot: 'бот-трейдер',
			transf_credito: 'Кредитный перевод',
			transf_debito: 'Дебетовый перевод',
			transfer_payment: 'перевод платежа',
			transfer_payment_return: 'Возврат платежа за перевод',
			transfer_receipt: 'расписка в передаче',
			transparency: 'Прозрачность',
			tx_adm_cred: 'Администрирование налога',
			tx_adm_deb: 'Администрирование налога',
			tx_retirada: 'Комиссия за снятие средств',
			tx_retirada_gan_diario: 'Ежедневная скорость вывода средств',
			type: 'Тип',
			update: 'Обновить',
			update_wallet: 'Обновить портфолио',
			upgrade: 'Улучшение',
			used: 'Использовал',
			user: 'Пользователь',
			user_active: 'активный пользователь',
			user_already_exists: 'Пользователь уже существует!',
			user_and_or_password_wrong: 'Неверный логин и/или пароль!',
			user_inactive: 'неактивный пользователь',
			user_not_found: 'Пользователь не найден',
			user_not_logged: 'Пользователь не авторизован',
			user_or_password_wrong: 'Неверное имя пользователя или пароль',
			users: 'Пользователи',
			users_list: 'Список пользователей',
			valid_images: 'действительные изображения',
			valorRenda2: 'Какова стоимость второго дохода',
			value: 'Ценить',
			value_donate_on: 'Сумма пожертвования в',
			value_financed: 'финансируемая сумма',
			value_installment: 'стоимость взноса',
			values: 'Ценности',
			vehicle: 'Транспортное средство',
			vehicles: 'Транспорт',
			view_order: 'Посмотреть заказ',
			vision: 'Зрение',
			visitor: 'Посетитель',
			voucher: 'Ваучер',
			wait_confirm: 'Ожидание подтверждения',
			waiting: 'Ожидающий',
			waiting_email: 'Регистрация прошла успешно, теперь осталось только подтвердить почту.',
			waiting_payment: 'Ожидание оплаты',
			wallet: 'портфолио',
			wallet_generate: 'Создать портфолио',
			wallets: 'кошельки',
			want_donate: 'я хочу пожертвовать',
			want_now_more_about: 'я хочу узнать больше о',
			warning: 'ВНИМАНИЕ',
			we_are: 'Являются',
			webhooks: 'вебхуки',
			welcome: 'Добро пожаловать',
			who_we_are: 'Кто мы',
			who_we_are_subtitle: 'Краткое описание о',
			widower: 'вдовец',
			will_expire_in: 'истекает через',
			with: 'с',
			withdraw: 'Снятие',
			withdraw_sended_email:
				'Запрошен вывод средств, проверьте свою электронную почту и нажмите на ссылку для авторизации!',
			withdrawal: 'Снятие средств',
			withdrawals: 'Снятие средств',
			year: 'Год',
			years: 'Годы',
			yes: 'Да',
			your_country: 'Твоя страна',
			your_dashboard: 'Это ваша панель управления.',
			your_link: 'Ваша ссылка',
			zipcode: 'Почтовый индекс',
			amount_above_balance: 'Недостаточный баланс',
			symbol: 'Символ',
			close: 'Закрытие',
			high: 'Альто',
			low: 'Низкий',
			order_book: 'Книга заказов',
			coin: 'Монета',
			free: 'Книга',
			locked: 'Заблокированный',
			you_have: 'У вас есть',
			buy_volume: 'Купить объем',
			sell_volume: 'Продавать объем',
			estimate: 'Оцененный',
			photo: 'Фото',
			telegram_chat_id: 'Telegram чата идентификатор',
			pendent_order: 'У вас есть заказы',
			active_automations: 'Активные автоматизации',
			active_monitors: 'Активные мониторы',
			active_users: 'Активные пользователи',
			active_connections: 'Активные соединения',
			ticker_health: 'Здоровье тикера',
			book_health: 'Книга здоровья',
			enter_your_new_password: 'Введите свой новый пароль',
			confirm_your_new_password: 'Введите свой новый пароль еще раз',
			alerts: 'Предупреждение',
			alert: 'Тревога',
			how_create_binance_apikey: 'Как создать ключ API в Binance',
			text_hint_binance:
				'При создании ключа API в Binance вкладывает ограничения доступа к IP:\n172.105.78.139\nЗатем проверьте параметры:\n• ENABLE EUROPEAN OPTIONS\n• ENABLE SPOT & MARGIN TRADING',
		},
	},
};

export { messages };
