/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFullBalance } from '../../services/ExchangeService';
import WalletRow from './WalletRow';
import Toast from '../../components/Toast/Toast';
import NewOrderModal from '../../components/NewOrder/NewOrderModal';
import NewOrderButton from '../../components/NewOrder/NewOrderButton';
import SelectFiat, { setDefaultFiat, getDefaultFiat } from '../../components/SelectFiat/SelectFiat';
import { CheckPrivateAdmin } from '../../utils/CheckPrivateAdmin';
import LoaderPage from '../../utils/LoaderPage';
import { i18n } from '../../utils/translates/i18n';

function Wallet() {
	const navigate = useNavigate();
	const [show, setShow] = useState(false);

	const [balances, setBalances] = useState([]);
	const [fiat, setFiat] = useState(getDefaultFiat());
	const [totalFiat, setTotalFiat] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [isFuture, setIsFuture] = useState(false);

	const [notification, setNotification] = useState({ type: '', text: '' });

	const loading = () => {
		if (!fiat) return;

		setIsLoading(true);
		getFullBalance(fiat, isFuture)
			.then(info => {
				const balances = Object.entries(info)
					.map(item => {
						return {
							symbol: item[0],
							available: item[1].available,
							onOrder: item[1].onOrder,
							fiatEstimate: item[1].fiatEstimate,
							avg: item[1].avg,
						};
					})
					.sort((a, b) => {
						if (a.symbol > b.symbol) return 1;
						if (a.symbol < b.symbol) return -1;
						return 0;
					});

				setBalances(balances.filter(b => b.available));
				setTotalFiat(info.fiatEstimate);
				setIsLoading(false);
			})
			.catch(err => {
				console.error(err.response ? err.response.data : err.message);
				setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
				setIsLoading(false);
			});
	};

	useEffect(() => {
		loading();
	}, [fiat, isFuture]);

	function onOrderSubmit(order) {
		loading();
	}

	function onFiatChange(event) {
		setDefaultFiat(event.target.value);
		setFiat(event.target.value);
	}

	useEffect(() => {
		const doCheck = async () => {
			document.title = `${i18n.t('wallet')} | ${process.env.REACT_APP_NAME}`;
			const token = sessionStorage.getItem('token');
			if (!token) navigate('/logout');
			const check = await CheckPrivateAdmin(token);
			if (!check || !check.isActive) navigate('/notAuthorized');
			if (!check.accessKey) navigate('/traderbot/settings');
			setShow(true);
		};
		doCheck();
	}, []);

	return !show ? (
		<LoaderPage />
	) : (
		<React.Fragment>
			<section className='section'>
				<div className='container-fluid'>
					<div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
						<div className='d-block mb-4 mb-md-0'>
							<h2 className='h4'>{i18n.t('wallet')}</h2>
						</div>
						<div className='btn-toolbar mb-2 mb-md-0'>
							{sessionStorage.getItem('hasFutures') === 'true' ? (
								<div className='me-2'>
									<button className='btn btn-secondary me-2' onClick={() => setIsFuture(!isFuture)}>
										{isFuture ? 'Futures' : 'Spot'}
									</button>
								</div>
							) : (
								<React.Fragment></React.Fragment>
							)}
							<div className='me-2'>
								<SelectFiat onChange={onFiatChange} fiat={fiat} />
							</div>
							<NewOrderButton />
						</div>
					</div>
					<div className='card card-body border-0 shadow table-wrapper table-responsive'>
						<table className='table table-hover'>
							<thead>
								<tr>
									<th className='border-gray-200'>{i18n.t('symbol')}</th>
									<th className='border-gray-200'>{i18n.t('free')}</th>
									<th className='border-gray-200'>{i18n.t('locked')}</th>
									<th className='border-gray-200'>FIAT {i18n.t('total')}</th>
									<th className='border-gray-200'>AVG {i18n.t('price')}</th>
								</tr>
							</thead>
							<tbody>
								{!isLoading && balances && balances.length ? (
									balances
										.filter(b => parseFloat(b.available) > 0 || parseFloat(b.onOrder) > 0)
										.map(item => <WalletRow key={item.symbol} data={item} />)
								) : (
									<tr>
										<td colSpan={5}>Loading...</td>
									</tr>
								)}
							</tbody>
							<tfoot>
								<tr>
									<td></td>
									<td></td>
									<td></td>
									<td>{totalFiat}</td>
								</tr>
							</tfoot>
						</table>
					</div>
				</div>
			</section>
			<NewOrderModal onSubmit={onOrderSubmit} />
			<Toast type={notification.type} text={notification.text} />
		</React.Fragment>
	);
}

export default Wallet;
