/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../utils/Loader';

// import Modals from '../components/SubComponents/Modals';
import ModalFeature from '../components/SubComponents/ModalFeature';
import * as Fa from 'react-icons/fa';
// import { PieChart } from 'react-minimal-pie-chart';

import CardStatus from '../components/SubComponents/CardStatus';

import { i18n } from '../utils/translates/i18n';
// import { changePlan, editUser, upgradePlan } from '../services/mmn/UsersService';
import { /* FormateDate, */ FormateFiatCurrency, OnlyNumber } from '../utils/CustomFormatations';
import WorldMap from '../components/SubComponents/WorldMap';
import { getDashMain } from '../services/DashboardService';
import LoaderPage from '../utils/LoaderPage';
import MandalaImg from './Mandala/MandalaImg';
import { getMandalas } from '../services/mmn/MandalasService';
import Toast from '../components/Toast/Toast';

export default function Dashboard() {
	const navigate = useNavigate();
	const [show, setShow] = useState(false);
	const [loader, setLoader] = useState(false);
	const multiplierUsers = 1;

	const [mainDatas, setMainDatas] = useState({});

	const [linkDeCadastro, setLinkDeCadastro] = useState('');
	const [latlng, setLatlng] = useState([]);

	// const [legCheck, setLegCheck] = useState('left');

	/* const [upgradeAlertShow, setUpgradeAlertShow] = useState(false);
	const [noUpgradeAlertShow, setNoUpgradeAlertShow] = useState(false);
	const [sideAlert, setSideAlert] = useState(false);

	const [pieData, setPieData] = useState([]); */

	const [copySuccess, setCopySuccess] = useState('');

	// const [modalShow, setModalShow] = useState(false);
	const [modalFileShow, setModalFileShow] = useState(false);

	const [mandalas, setMandalas] = useState([]);
	const doGetMandalas = async token => {
		const success = await getMandalas(token);
		if (success.status) {
			setMandalas(success.mandalas.rows);
		} else navigate('/notAuthorized');
	};

	/* const doChangePlan = async selectPlan => {
		setLoader(true);
		const success = await changePlan(mainDatas.user.id, { planId: selectPlan }, token);
		if (success.status) {
			setModalShow(false);
			navigate('/dashboard/order');
			setLoader(false);
		}
	}; */

	/* const doUpgradePlan = async selectPlan => {
		setLoader(true);
		const success = await upgradePlan(mainDatas.user.id, { planId: selectPlan }, token);
		if (success.status) {
			setModalShow(false);
			navigate('/dashboard/order');
			setLoader(false);
		}
	}; */

	function copyLinkToClipboard(link) {
		let copyText = document.getElementById(`input-${link}`);
		copyText.select();
		copyText.setSelectionRange(0, 99999);
		navigator.clipboard.writeText(copyText.value.toString());
		navigator.clipboard
			.readText()
			.then(t => console.log(t))
			.catch(e => console.log(e));

		setCopySuccess(i18n.t('copied'));
	}

	/* const selectSide = async data => {
		setLoader(true);
		const success = await editUser(mainDatas.user.id, { sidePreferential: data }, token);
		if (success.status) {
			setLegCheck(success.user.sidePreferential);
			setSideAlert(true);
			setLoader(false);
		}
	}; */

	useEffect(() => {
		const doCheck = async () => {
			const token = sessionStorage.getItem('token');
			if (!token) navigate('/logout');
			const success = await getDashMain(token);
			if (!success.status) navigate('/logout');
			if (success.status) {
				window.document.title = `${i18n.t('dashboard')} | ${process.env.REACT_APP_NAME}`;

				setMainDatas(success.main);
				setLinkDeCadastro(`${window.location.protocol}//${window.location.host}/${success.main?.user?.nick}`);
				/* setPieData([
					{
						title: i18n.t('complete'),
						value:
							success.main?.user?.quotas && Object.keys(success.main?.user?.quotas).length > 0
								? parseFloat(success.main?.user?.quotas?.amount)
								: 0,

						percent:
							success.main?.user?.quotas && Object.keys(success.main?.user?.quotas).length > 0
								? parseFloat((success.main?.user?.quotas?.amount / success.main?.user?.quotas?.target) * 100).toFixed(1)
								: 0,
						color: 'rgba(255,255,0,1)',
					},
					{
						title: i18n.t('target'),
						value:
							success.main?.user?.quotas && Object.keys(success.main?.user?.quotas).length > 0
								? parseFloat(success.main?.user?.quotas?.target)
								: 10,
						percent: false,
						color: 'rgba(0,0,0, 0.3)',
					},
				]); */
				// setLegCheck(success.main?.user?.sidePreferential);
				let latLng = [];
				success.main?.countUsersByCountry?.map(c =>
					latLng.push({
						latLng: [c.latitude, c.longitude],
						name: `${c.name} - ${c.count * multiplierUsers} ${i18n.t('users')}`,
					}),
				);
				setLatlng(latLng);
			}
			await doGetMandalas(token);
			setShow(true);
		};
		doCheck();
	}, []);

	return !show ? (
		<LoaderPage />
	) : (
		<>
			{/* {console.log(Object.keys(mainDatas.user?.quotas).length)} */}
			<section className='section'>
				<div className='container-fluid'>
					<div className='row pt-2'>
						{mainDatas.warningMonthly && (
							<div className='col-md text-center'>
								<div className='alert alert-danger'>
									<strong>{i18n.t('body_monthly')}</strong>
									<br />
									<Link to='/dashboard/order'>
										<button className='btn btn-danger'>{i18n.t('order')}</button>
									</Link>
								</div>
							</div>
						)}
						{mainDatas.warningTarget && (
							<div className='col-md text-center'>
								<div className='alert alert-warning'>
									<strong>{i18n.t('limite_80')}</strong>
									<br />
									<Link to='/dashboard/order'>
										<button className='btn btn-warning'>{i18n.t('order')}</button>
									</Link>
								</div>
							</div>
						)}
						<div className='col-md'>
							<div className='p-2 card radius-15'>
								<div className='row'>
									<div className='col-md'>
										<div className='input-group my-1'>
											<button className='btn btn-secondary'>{i18n.t('your_link')}:</button>
											<input className='form-control' value={linkDeCadastro} id={`input-your-link`} readOnly />
											<button className='btn btn-secondary' onClick={() => copyLinkToClipboard('your-link')}>
												<Fa.FaCopy /> {copySuccess ? copySuccess : i18n.t('copy')}
											</button>
										</div>
									</div>
									{/* {mainDatas.user?.activateDate && (
										<div className='col-md'>
											<div className='input-group my-1'>
												<div className='input-group-prepend'>
													<button className='btn btn-dark' disabled>
														{i18n.t('upgrade').toUpperCase()}:
													</button>
												</div>
												<select
													value={''}
													className='form-select bg-light'
													onChange={e => doUpgradePlan(e.target.value)}>
													<option className='bg-light py-2'>
														-- {i18n.t('choose')} {i18n.t('plan')} --
													</option>
													{mainDatas.plansToUpgrade?.map((p, i) => (
														<option key={i} value={p.id} className='bg-light py-2'>{`${p.name}: ${FormateFiatCurrency(
															p.price,
														)}`}</option>
													))}
												</select>
											</div>
										</div>
									)} */}
								</div>
								{/* {upgradeAlertShow && (
									<div className='alert alert-success alert-dismissible mb-0'>
										<strong>{i18n.t('congratulations')}!</strong> {i18n.t('order')} {i18n.t('efetuado_td')}{' '}
										{i18n.t('sucesso_td')}.{' '}
										<Link to='/dashboard/order'>
											<button className='btn btn-success' size='sm'>
												{i18n.t('order')}
											</button>
										</Link>
										<button className='btn btn-close' onClick={() => setUpgradeAlertShow(false)}></button>
									</div>
								)}
								{noUpgradeAlertShow && (
									<div className='alert alert-danger alert-dismissible mb-0'>
										<strong>{i18n.t('erro_td')}!</strong> {i18n.t('order')} {i18n.t('pendente_td')}.{' '}
										<Link to='/dashboard/order'>
											<button className='btn btn-danger' size='sm'>
												{i18n.t('order')}
											</button>
										</Link>
										<button className='btn btn-close' onClick={() => setNoUpgradeAlertShow(false)}></button>
									</div>
								)} */}
							</div>
						</div>
						<div className='col-md-4 text-center'>
							<div className='p-2 card radius-15'>
								<a
									href={`https://wa.me/${OnlyNumber(mainDatas.settings?.phone)}`}
									target='_blank'
									rel='noopener noreferrer'
									className='my-1 btn btn-dark'>
									<Fa.FaWhatsapp /> {i18n.t('support')}
								</a>
							</div>
						</div>
					</div>

					{/* {mainDatas.renew && (
						<div className='alert alert-danger d-flex justify-content-around align-items-center'>
							<span>
								<h2 className='text-danger'>{i18n.t('warning')}!</h2>
								<h4>
									<strong>{FormateDate(mainDatas.user?.blockages?.date)}</strong>
									{i18n.t('msg_renovacao')}
								</h4>
							</span>
							<Link to='/dashboard/order'>
								<button className='btn btn-danger btn-lg fs-2'>{i18n.t('renew')}</button>
							</Link>
						</div>
					)} */}

					<div className='row'>
						<div className='col-md-8'>
							<div className='row'>
								<div className='col-md-12 mt-3 mb-0 d-none d-md-block'>
									<div
										className={`alert alert-${
											mainDatas.user?.orders && mainDatas.user?.orders[0]?.status?.status === 'waiting'
												? 'danger'
												: 'primary'
										} fw-bold fs-3 py-2 d-flex justify-content-between align-items-center`}>
										<span>
											{i18n.t('welcome')}, {mainDatas.user?.name}
										</span>
										{mainDatas.user?.orders && mainDatas.user?.orders[0]?.status?.status === 'waiting' && (
											<Link to='/dashboard/order' className='btn btn-danger'>
												{i18n.t('pendent_order')}
											</Link>
										)}
									</div>
								</div>
								{/* <div className='col-md-4 mb-3'>
									{mainDatas.user?.activity ? (
										<CardStatus
											title={i18n.t('plan')}
											icon='box-open'
											bg={mainDatas.user?.activity ? 'primary' : 'danger'}
											valor={mainDatas.user?.plan?.name}
											sub={FormateDate(mainDatas.user?.activateDate, true, true) || i18n.t('waiting_payment')}
										/>
									) : (
										<CardStatus
											title={i18n.t('plan')}
											icon='box-open'
											bg={mainDatas.user?.activity ? 'primary' : 'danger'}
											valor={mainDatas.user?.plan?.name}
											btn={{ label: 'change_plan', func: () => setModalShow(true) }}
										/>
									)}
								</div> */}
								{/* <div className='col-md-4 mb-3'>
									<CardStatus
										title={i18n.t('qualify_binary')}
										icon='dna'
										bg={mainDatas.user?.binaryQualify?.status ? `info` : 'warning'}
										valor={mainDatas.user?.binaryQualify?.status ? i18n.t('yes') : i18n.t('no')}
										sub={FormateDate(mainDatas.user?.binaryQualify?.date)}
									/>
								</div> */}
								{/* <div className='col-md-4 mb-3'>
									<CardStatus
										title={i18n.t('career_plan')}
										icon='stairs'
										bg='primary'
										valor={`${mainDatas.user?.career?.name?.toUpperCase()} ${
											mainDatas.user?.teamMinor
										} pts. (${parseFloat((mainDatas.user?.teamMinor / mainDatas.nextCareer?.points) * 100).toFixed(
											1,
										)}%)`}
										perc={(mainDatas.user?.teamMinor / mainDatas.nextCareer?.points) * 100}
										small={`${i18n.t('missing')} ${
											mainDatas.nextCareer?.points - mainDatas.user?.teamMinor
										} pts ${i18n.t('to')} ${mainDatas.nextCareer?.name?.toUpperCase()}`}
									/>
								</div> */}
								<div className='col-md-4 mb-3'>
									<Link to='/traderbot/dashboard'>
										<CardStatus
											title={i18n.t('traderbot')}
											icon={mainDatas.user?.isActive === true ? 'robot' : 'robot'}
											bg={mainDatas.user?.isActive === true ? 'success' : 'danger'}
											valor={i18n.t(mainDatas.user?.isActive === true ? 'user_active' : 'user_inactive')}
											// sub={mainDatas.user?.limitBot?.name}
										/>
									</Link>
								</div>
								<div className='col-md-4 mb-3'>
									<Link to='/traderbot/orders'>
										<CardStatus
											title={i18n.t('b_orders')}
											icon={'credit-card'}
											bg={'primary'}
											valor={i18n.t('traderbot')}
											// sub={mainDatas.user?.limitBot?.name}
										/>
									</Link>
								</div>
								<div className='col-md-4 mb-3'>
									<Link to='/traderbot/reports'>
										<CardStatus
											title={i18n.t('reports')}
											icon={'file'}
											bg={'warning'}
											valor={i18n.t('traderbot')}
											// sub={mainDatas.user?.limitBot?.name}
										/>
									</Link>
								</div>

								<div className='col-md-4 mb-3'>
									<CardStatus
										title={i18n.t('balance_available')}
										icon='usd'
										bg='primary'
										valor={FormateFiatCurrency(mainDatas.user?.userFinanceData?.credit)}
									/>
								</div>
								<div className='col-md-4 mb-3'>
									<CardStatus
										title={i18n.t('system')}
										icon='users'
										bg='primary'
										valor={`${mainDatas.countUsers * multiplierUsers} ${i18n.t('users')}`}
									/>
								</div>
								<div className='col-md-4 mb-3'>
									<CardStatus
										bg='warning'
										valor={FormateFiatCurrency(mainDatas.money?.in)}
										title={i18n.t('entries_until_today')}
										icon='usd'
									/>
								</div>
								{/* 
								<div className='col-md-8'>
									<CardStatus
										title={i18n.t('network')}
										bg='warning'
										icon='sitemap'
										division={[
											{
												valor: mainDatas.directs,
												sub: i18n.t('directs'),
											},
											{
												valor: mainDatas.indirects,
												sub: i18n.t('indirects'),
											},
										]}
									/>
									<div className='card radius-15 my-3 mx-0'>
										<div className='text-center card-body'>
											<h4>
												{i18n.t('points')} - {i18n.t('indicateds')}
											</h4>
											<div className='row'>
												<div className='col-sm-6'>
													<div className='card mb-3'>
														{i18n.t('points')} {i18n.t('left')}: {mainDatas.user?.teams?.left}
													</div>
												</div>
												<div className='col-sm-6'>
													<div className='card mb-3'>
														{i18n.t('points')} {i18n.t('right')}: {mainDatas.user?.teams?.right}
													</div>
												</div>
											</div>
											<div className='btn-group w-100 mt-0'>
												<button
													className={`btn btn-block btn-${legCheck === 'left' ? 'success' : 'secondary'}`}
													onClick={() => selectSide('left')}
													id='pernae'>
													{i18n.t('left')}
												</button>
												<button
													className={`btn btn-block btn-${legCheck === 'right' ? 'success' : 'secondary'}`}
													onClick={() => selectSide('right')}
													id='pernad'>
													{i18n.t('right')}
												</button>
											</div>
											{sideAlert && (
												<div className='alert alert-success alert-dismissible mt-2 mb-0'>
													<div className='text-center w-100'>
														<strong>
															{legCheck === 'left' ? i18n.t('left_preferential') : i18n.t('right_preferential')}.
														</strong>
													</div>
													<button
														type='button'
														className='btn-close'
														aria-label='Close'
														onClick={() => setSideAlert(false)}></button>
												</div>
											)}
										</div>
									</div>
								</div>
								*/}
							</div>
						</div>
						<div className='col-md-4'>
							<div className='card radius-15 my-3 mx-0'>
								<div className='card-body'>
									{mandalas.length ? (
										mandalas?.map((m, i) => (
											<Fragment key={i}>
												<MandalaImg data={m} />
											</Fragment>
										))
									) : (
										<img
											src={`/img/mandala/mandala.svg`}
											alt={`mandala`}
											crossOrigin='anonymous'
											style={{ opacity: '0.5' }}
										/>
									)}
								</div>
							</div>
							{/* <div className='card radius-15 my-3 mx-0'>
								<div className='card-header d-sm-flex d-block'>
									<h4 className='fs-20'>{i18n.t('target')}</h4>
								</div>
								<div className='card-body'>
									{pieData && (
										<PieChart
											data={pieData}
											style={{ maxHeight: '200px', margin: '0px auto 20px auto' }}
											label={({ dataEntry }) => (dataEntry.percent ? `${dataEntry.percent}%` : ``)}
											labelPosition='00'
										/>
									)}
								</div>
								<div className='card-footer'>
									<h5>
										{`${i18n.t('current')} ${
											mainDatas.user?.quotas && Object.keys(mainDatas.user?.quotas).length > 0
												? FormateFiatCurrency(mainDatas.user?.quotas?.amount)
												: FormateFiatCurrency(0)
										} (${i18n.t('target')} ${
											mainDatas.user?.quotas && Object.keys(mainDatas.user?.quotas).length > 0
												? FormateFiatCurrency(mainDatas.user?.quotas?.target)
												: FormateFiatCurrency(0)
										}
										)`}
									</h5>
								</div>
							</div> */}
						</div>
					</div>

					<div className='row'>
						<div className='col-md-12'>
							<div className='card radius-15 mx-0'>
								<div className='card-body'>
									<div className='row'>
										<div className='col-md-8'>
											<div className='mb-0 d-flex align-items-center'>
												<div>
													<h5 className='mb-0'>
														{`${mainDatas.countUsersByCountry?.length} ${i18n.t('countries')} - ${i18n.t(
															'your_country',
														)} : ${mainDatas.user?.country}`}
													</h5>
												</div>
											</div>
											<hr />
											<div style={{ width: '100%', height: '250px', zIndex: '9999' }}>
												{latlng.length > 0 && <WorldMap latlng={latlng} />}
											</div>
										</div>
										<div className='col-md-4 mb-3'>
											<div className='mb-0 d-lg-flex align-items-center'>
												<div>
													<h5 className='mb-0'>{`${i18n.t('total')} : ${
														mainDatas.countUsers * multiplierUsers
													} ${i18n.t('users')}`}</h5>
												</div>
											</div>
											<hr />
											<ul className='list-group list-group-flush' style={{ maxHeight: '250px', overflowY: 'scroll' }}>
												{mainDatas.countUsersByCountry?.map((c, i) => (
													<li
														key={i}
														className='bg-transparent list-group-item d-flex align-items-center justify-content-between'>
														<span>{c.name}</span>
														<span>{c.count * multiplierUsers}</span>
													</li>
												))}
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* <Modals
				size='lg'
				title={i18n.t('change_plan')}
				contentClassName='text-dark'
				show={modalShow}
				onHide={() => setModalShow(false)}>
				<div className='row'>
					{mainDatas.plans?.map((p, i) => {
						return (
							<div className='col-md-4' key={i}>
								<button
									className={`btn m-2 ${
										mainDatas.user?.plan?.id === p.id ? 'btn-info' : 'btn-warning'
									} btn-block w-100`}
									onClick={() => doChangePlan(p.id)}
									disabled={mainDatas.user?.plan?.id === p.id}>
									<h4 className='m-1'>{p.name?.toUpperCase()}</h4>
									<h3 className='m-1'>{FormateFiatCurrency(p.price)}</h3>
								</button>
							</div>
						);
					})}
				</div>
			</Modals> */}

			{modalFileShow && (
				<ModalFeature
					size='lg'
					show={modalFileShow}
					onHide={() => {
						sessionStorage.setItem('modalFile', 'hidden');
						setModalFileShow(false);
					}}>
					{!mainDatas.alerts ? (
						''
					) : mainDatas.alerts.type === 'video' ? (
						<video
							src={`/files/${mainDatas.alerts.img}`}
							controls
							autoplay
							style={{ width: '100%', height: '60vh' }}></video>
					) : mainDatas.alerts.type === 'application' ? (
						<iframe
							src={`/files/${mainDatas.alerts.img}`}
							title='app'
							frameBorder='0'
							style={{ width: '100%', height: '60vh' }}></iframe>
					) : mainDatas.alerts.type === 'youtube' ? (
						<>
							<iframe
								src={`https://www.youtube.com/embed/${mainDatas.alerts.link}`}
								title='yt'
								frameBorder='0'
								allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
								allowFullScreen
								style={{ width: '100%', height: '60vh' }}></iframe>
							<div className='input-group'>
								<input
									className='form-control'
									id={`input-alert-link`}
									type='text'
									readOnly
									value={mainDatas.alerts.link}
								/>
								<button className='btn btn-secondary' onClick={() => copyLinkToClipboard('alert-link')}>
									<i className='fa fa-copy'></i> {copySuccess ? copySuccess : i18n.t('copy')}
								</button>
							</div>
						</>
					) : (
						<img src={`/files/${mainDatas.alerts.img}`} className='img-fluid' alt='imgdd' />
					)}
				</ModalFeature>
			)}
			<Loader show={loader} onHide={() => setLoader(false)} />
			<Toast />
			{/* <pre>{JSON.stringify(mainData, null, 4)}</pre> */}
		</>
	);
}
