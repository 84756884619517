/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { i18n } from '../../utils/translates/i18n';
import { useNavigate } from 'react-router-dom';
import LoaderPage from '../../utils/LoaderPage';
import Toast from '../../components/Toast/Toast';
import { editPhoto, editUser, getMyUserData } from '../../services/mmn/UsersService';
import { RemoveSpaceAccents } from '../../utils/CustomFormatations';
import style from './profile.module.css';

export default function Profile() {
	// const token = process.env.REACT_APP_USER_TOKEN;
	const token = sessionStorage.getItem('token');
	const navigate = useNavigate();
	const btnFile = useRef('');

	const DEFAULT_DATA = {
		id: '',
		name: '',
		password: '',
		confirmPassword: '',
		phone: '',
		telegramChat: '',
		accessKey: '',
		secretKey: '',
		photo: '',
		email: '',
		passwordSecurity: '',
		confirmPasswordSecurity: '',
		futuresKey: '',
		futuresSecret: '',
		telegramBot: '',
	};

	const [data, setData] = useState(DEFAULT_DATA);
	const [show, setShow] = useState(false);
	const [file, setFile] = useState();
	const [notification, setNotification] = useState({});

	const doGetDatas = async () => {
		const success = await getMyUserData(token);
		if (success.status)
			setData({
				id: success.user.id,
				name: success.user.fullName,
				email: success.user.email,
				phone: success.user.phone,
				password: '',
				confirmPassword: '',
				passwordSecurity: '',
				confirmPasswordSecurity: '',
				telegramChat: success.user.telegramChat,
				accessKey: success.user.accessKey,
				secretKey: '',
				photo: success.user.photo,
				futuresKey: success.user.futuresKey,
				futuresSecret: success.user.futuresSecret,
				telegramBot: success.user.telegramBot,
				botPlan: success.user.limitBot.name,
			});
	};

	const onInputChange = (i, v) => setData(p => ({ ...p, [i]: v }));

	const checkPassword = (id, pass, confirm) => {
		if (confirm !== pass) {
			document.getElementById(id).classList.add('is-invalid');
			return false;
		} else {
			document.getElementById(id).classList.remove('is-invalid');
			return true;
		}
	};

	const doUpload = async () => {
		setShow(false);
		const formData = new FormData();
		let fileName = file.name.split('.');
		let extension = fileName[fileName.length - 1];
		formData.append(
			'file',
			file,
			`${new Date().getTime()}_${RemoveSpaceAccents(fileName.slice(0, -1).join('_'))}.${extension}`,
		);

		const success = await editPhoto(token, formData);
		if (success.status) {
			await doGetDatas();
			setFile(null);
			setShow(true);
		} else setShow(true);
	};

	const doSaveForm = async () => {
		setShow(false);
		let validate = true;
		if (data.password && !checkPassword('confirmPassword', data.password, data.confirmPassword)) {
			setShow(true);
			setNotification({ text: i18n.t('error_confirm_password'), type: 'error' });
			validate = false;
		}
		if (
			data.passwordSecurity &&
			!checkPassword('confirmPasswordSecurity', data.passwordSecurity, data.confirmPasswordSecurity)
		) {
			setShow(true);
			setNotification({ text: i18n.t('error_confirm_password'), type: 'error' });
			validate = false;
		}
		if (validate) {
			const success = await editUser(data.id, data, token);
			if (success.status) {
				await doGetDatas();
				setShow(true);
			} else {
				setShow(true);
				setNotification({ text: i18n.t('not_self_register'), type: 'error' });
			}
		}
	};

	useEffect(() => {
		const doCheck = async () => {
			document.title = `${i18n.t('profile')} | ${process.env.REACT_APP_NAME}`;
			if (!token) navigate('/logout');
			await doGetDatas();
			setShow(true);
		};
		doCheck();
	}, []);

	return !show ? (
		<LoaderPage />
	) : (
		<>
			<section className='section'>
				<div className='container-fluid'>
					<div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
						<div className='d-block mb-4 mb-md-0'>
							<span className='h4'>{i18n.t('profile')}</span>
							{/* <pre>{JSON.stringify(data, null, 4)}</pre> */}
						</div>
					</div>
					<div className='row'>
						<div className='col-12'>
							<div className='card card-body border-0 shadow mb-4'>
								<div className='row'>
									<div className='col-md-2'>
										<div className='mt-3'>
											<div className={`text-center ${style.userPhoto}`} onClick={() => btnFile.current.click()}>
												<img
													src={
														file ? URL.createObjectURL(file) : `${process.env.REACT_APP_API_URL}/images/${data.photo}`
													}
													crossOrigin='anonymous'
													alt='user'
													className={`img-thumbnail img-fluid shadow`}
												/>
											</div>
											<div className='text-center'>
												<input
													type='file'
													id='file'
													ref={btnFile}
													className='d-none'
													onChange={e => setFile(e.target.files[0])}
												/>
												{file ? (
													<button className='btn btn-primary btn-sm' onClick={doUpload}>
														{`${i18n.t('save')} ${i18n.t('photo')}`}
													</button>
												) : (
													<button className='btn btn-outline-primary btn-sm' onClick={() => btnFile.current.click()}>
														{`${i18n.t('choose')} ${i18n.t('photo')}`}
													</button>
												)}
											</div>
										</div>
									</div>
									<div className='col-md-10'>
										<h2 className='h5 mb-4'>{i18n.t('personal_data')}</h2>
										<div className='row'>
											<div className='col-md-12 mb-3'>
												<div className='form-group'>
													<label>{i18n.t('name')}</label>
													<input
														className='form-control'
														id='name'
														type='text'
														placeholder={i18n.t('name')}
														value={data.name || ''}
														onChange={e => onInputChange(e.target.id, e.target.value)}
													/>
												</div>
											</div>
										</div>
										<div className='row'>
											<div className='col-md-6 mb-3'>
												<div className='form-group'>
													<label>{i18n.t('email')}</label>
													<input
														className='form-control'
														id='email'
														type='email'
														placeholder='name@company.com'
														value={data.email || ''}
														onChange={e => onInputChange(e.target.id, e.target.value)}
													/>
												</div>
											</div>
											<div className='col-md-6 mb-3'>
												<div className='form-group'>
													<label>{i18n.t('phone')}</label>
													<input
														className='form-control'
														id='phone'
														type='text'
														placeholder='51 12345-6789'
														value={data.phone || ''}
														onChange={e => onInputChange(e.target.id, e.target.value)}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className='row'>
									<div className='col-md-6 mb-3'>
										<div>
											<label>{i18n.t('new_password')}</label>
											<input
												className='form-control'
												id='password'
												type='password'
												placeholder={i18n.t('new_password')}
												value={data.password || ''}
												onChange={e => onInputChange(e.target.id, e.target.value)}
											/>
										</div>
									</div>
									<div className='col-md-6 mb-3'>
										<div className='form-group'>
											<label>{i18n.t('confirm_new_password')}</label>
											<input
												className='form-control'
												id='confirmPassword'
												type='password'
												placeholder={i18n.t('confirm_new_password')}
												value={data.confirmPassword || ''}
												onChange={e => onInputChange(e.target.id, e.target.value)}
												onBlur={e => checkPassword(e.target.id, data.password, e.target.value)}
											/>
											<div className='invalid-feedback bg-danger p-1 mt-0'>{i18n.t('error_confirm_password')}</div>
										</div>
									</div>
								</div>
								<div className='row'>
									<div className='col-md-6 mb-3'>
										<div>
											<label>{i18n.t('new_password_security')}</label>
											<input
												className='form-control'
												id='passwordSecurity'
												type='password'
												placeholder={i18n.t('new_password_security')}
												value={data.passwordSecurity || ''}
												onChange={e => onInputChange(e.target.id, e.target.value)}
											/>
										</div>
									</div>
									<div className='col-md-6 mb-3'>
										<div className='form-group'>
											<label>{i18n.t('confirm_new_password_security')}</label>
											<input
												className='form-control'
												id='confirmPasswordSecurity'
												type='password'
												placeholder={i18n.t('confirm_new_password_security')}
												value={data.confirmPasswordSecurity || ''}
												onChange={e => onInputChange(e.target.id, e.target.value)}
												onBlur={e => checkPassword(e.target.id, data.passwordSecurity, e.target.value)}
											/>
											<div className='invalid-feedback bg-danger p-1 mt-0'>{i18n.t('error_confirm_password')}</div>
										</div>
									</div>
								</div>
								<div className='row'>
									<div className='col-md-6 mb-3'>
										<div className='form-group'>
											<label>{i18n.t('telegram_chat_id')}</label>
											<a
												href={'https://t.me/' + data.telegramBot}
												target='_blank'
												rel='noopener noreferrer'
												className='badge bg-secondary py-1 ms-1'>
												?
											</a>
											<input
												className='form-control'
												id='telegramChat'
												type='text'
												placeholder='Enter the Telegram Chat ID'
												value={data.telegramChat || ''}
												onChange={e => onInputChange(e.target.id, e.target.value)}
											/>
										</div>
									</div>
									<div className='col-md-6 mb-3'>
										<div className='form-group'>
											<label>Bot Plan</label>
											<input className='form-control' id='limit' type='text' disabled={true} value={data.botPlan} />
										</div>
									</div>
								</div>
								<div className='row'>
									<div className='d-flex justify-content-between flex-wrap flex-md-nowrap'>
										<div className='col-sm-3'>
											<button className='btn btn-primary mt-2' type='button' onClick={doSaveForm}>
												{i18n.t('save')}
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col-12'>
							<div className='card card-body border-0 shadow mb-4'>
								<h2 className='h5 my-4'>{i18n.t('settings')} Binance</h2>
								<div className='row'>
									<div className='col-12 mb-3'>
										<div className='form-group'>
											<label>Spot Access Key</label>
											<input
												className='form-control'
												id='accessKey'
												type='text'
												placeholder='Enter the Spot API Access Key'
												value={data.accessKey || ''}
												onChange={e => onInputChange(e.target.id, e.target.value)}
											/>
										</div>
									</div>
								</div>
								<div className='row'>
									<div className='col-12 mb-3'>
										<div className='form-group'>
											<label>{i18n.t('new')} Spot Secret Key</label>
											<input
												className='form-control'
												id='secretKey'
												type='password'
												placeholder='Enter your new Spot API Secret Key'
												value={data.secretKey || ''}
												onChange={e => onInputChange(e.target.id, e.target.value)}
											/>
										</div>
									</div>
								</div>
								{data.limitBot?.hasFutures && (
									<>
										<div className='row'>
											<div className='col-12 mb-3'>
												<div className='form-group'>
													<label>Futures Access Key</label>
													<input
														className='form-control'
														id='futuresKey'
														type='text'
														placeholder='Enter the Futures API Access Key'
														value={data.futuresKey || ''}
														onChange={e => onInputChange(e.target.id, e.target.value)}
													/>
												</div>
											</div>
										</div>
										<div className='row'>
											<div className='col-12 mb-3'>
												<div className='form-group'>
													<label>New Futures Secret Key</label>
													<input
														className='form-control'
														id='futuresSecret'
														type='password'
														placeholder='Enter your new Futures API Secret Key'
														value={data.futuresSecret || ''}
														onChange={e => onInputChange(e.target.id, e.target.value)}
													/>
												</div>
											</div>
										</div>
									</>
								)}
								<div className='row'>
									<div className='d-flex justify-content-between flex-wrap flex-md-nowrap'>
										<div className='col-sm-3'>
											<button className='btn btn-primary mt-2' type='button' onClick={doSaveForm}>
												{i18n.t('save')}
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<Toast text={notification.text} type={notification.type} />
		</>
	);
}
