import axios from 'axios';

export const CheckPrivateAdmin = async token => {
	if (token) {
		const success = await axios.get(`${process.env.REACT_APP_API_URL}/usersmmn/token`, {
			headers: { Authorization: `${token}` },
		});
		if (!success) return false;
		if (!success.data.status) return false;
		return {
			groupType: success.data.user.group.type,
			groupName: success.data.user.group.name,
			groupId: success.data.user.group.id,
			userNick: success.data.user.nick,
			isActive: success.data.user.isActive,
			accessKey: success.data.user.accessKey,
		};
	}
};
