import React, { useEffect } from 'react';
import { i18n } from '../utils/translates/i18n';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
	const navigate = useNavigate();
	useEffect(() => {
		const toTop = () => {
			document.title = `${i18n.t('not_found')} | ${process.env.REACT_APP_NAME}`;
		};
		toTop();
	}, []);

	return (
		<div
			style={{
				padding: '50px',
				minWidth: '100%',
				height: '100%',
				background: 'url(/assets/images/space-bg.jpg) no-repeat center',
				backgroundSize: 'cover',
			}}>
			<div className='row'>
				<div
					style={{ background: 'rgba(0,0,0,.3)', padding: '30px', borderRadius: '50px' }}
					className='col-md-6 ms-md-5 text-center'>
					<i className='fas fa-triangle-exclamation fa-5x text-warning'></i>
					<h3 className='text-light display-5 fw-bold'>
						404 <br /> {i18n.t('not_found')}
					</h3>
					<button className='btn btn-warning text-dark mt-3' onClick={() => navigate(-1)}>
						<i className='fas fa-arrow-left'></i> {i18n.t('back')}
					</button>
				</div>
				<div className='col-md-3 m-auto d-none d-md-block'>
					<img src='/logos/logo.png' alt='logo' className='img-fluid' />
				</div>
			</div>
		</div>
	);
};

export default NotFound;
