/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { i18n } from '../utils/translates/i18n';
import { useNavigate } from 'react-router-dom';
import LoaderPage from '../utils/LoaderPage';
import { addWallet, getWalletPreferred, isValidWallet } from '../services/mmn/WalletsService';
import { checkSecurityPassword } from '../services/mmn/AuthService';
import Toast from '../components/Toast/Toast';

export default function UpdateWallet() {
	const token = sessionStorage.getItem('token');
	const navigate = useNavigate();
	const [show, setShow] = useState(false);

	const [alertMsg, setAlertMsg] = useState({ show: false, bg: 'success', msg: '' });

	const [wallet, setWallet] = useState();
	const [securityPassword, setSecurityPassword] = useState('');
	const [newWallet, setNewWallet] = useState('');

	const doGetWallet = async () => {
		const success = await getWalletPreferred(token);
		if (success.status) {
			setWallet(success.wallet.wallet);
		} else navigate('/logout');
	};

	const validateSecurityPassword = async () => {
		const success = await checkSecurityPassword(token, securityPassword);
		if (!success.status) {
			setSecurityPassword('');
			setAlertMsg({ show: true, bg: 'danger', msg: success.error });
		} else setAlertMsg({ show: false, bg: 'danger', msg: '' });
	};

	const doIsValidWallet = async () => {
		const success = await isValidWallet(token, newWallet);
		if (!success.status) setAlertMsg({ show: true, bg: 'danger', msg: 'invalid_wallet' });
		else setAlertMsg({ show: false, bg: 'danger', msg: '' });
	};

	const doAddWallet = async () => {
		const success = await addWallet(token, newWallet, securityPassword);
		if (success.status) {
			doGetWallet();
			setAlertMsg({ show: true, bg: 'success', msg: i18n.t('success_wallet') });
		} else setAlertMsg({ show: true, bg: 'danger', msg: success.error });
	};

	useEffect(() => {
		const doCheck = async () => {
			document.title = `${i18n.t('withdrawal')} | ${process.env.REACT_APP_NAME}`;
			const token = sessionStorage.getItem('token');
			if (!token) navigate('/logout');
			doGetWallet();
			setShow(true);
		};
		doCheck();
	}, []);

	return !show ? (
		<LoaderPage />
	) : (
		<>
			<section className='section'>
				<div className='container-fluid'>
					<div className='card card-body border-0 shadow mb-4 mt-4'>
						<div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
							<div className='d-block md-4 mb-md-0'>
								<h2 className='h4'>{i18n.t('update_wallet')}</h2>
							</div>
						</div>
						<div className='form-group'>
							{wallet && (
								<div className='row'>
									<div className='col-8 mb-3'>
										<label>{i18n.t('current_wallet')}:</label>
										<div className={`alert alert-default border border-1 fw-bold mb-0`}>{wallet}</div>
									</div>
								</div>
							)}
							<div className='row'>
								<div className='col-md-4 mb-3'>
									<label>{i18n.t('new_wallet')}:</label>
									<input
										type='text'
										className={`form-control border border-1 border-dark fw-bold mb-0 h-75`}
										onChange={e => setNewWallet(e.target.value)}
										onBlur={doIsValidWallet}
									/>
								</div>
								<div className='col-md-4 mb-3'>
									<label>{i18n.t('security_password')}:</label>
									<input
										type='password'
										value={securityPassword}
										className={`form-control border border-1 border-dark fw-bold mb-0 h-75`}
										onChange={e => setSecurityPassword(e.target.value)}
										onBlur={validateSecurityPassword}
									/>
								</div>
							</div>
							{alertMsg.show && (
								<div className={`alert alert-${alertMsg.bg} h4 mt-2`}>{i18n.t(alertMsg.msg).toUpperCase()}</div>
							)}
							<div className='row'>
								<div className='col-md-6 mb-3'>
									<button
										type='button'
										className='btn btn-primary'
										disabled={!newWallet || !securityPassword}
										onClick={doAddWallet}>
										{i18n.t('add_wallet')}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<Toast />
		</>
	);
}
