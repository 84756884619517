import React from 'react';
import { i18n } from '../../utils/translates/i18n';

function NewOrderButton() {
	return (
		<React.Fragment>
			{sessionStorage.getItem('hasFutures') === 'true' ? (
				<React.Fragment>
					<button
						id='btnNewOrder'
						className='btn btn-primary dropdown-toggle d-inline-flex align-items-center'
						data-bs-toggle='dropdown'>
						<i className='fas fa-plus me-2'></i>
						<span className='d-none d-md-flex '>{i18n.t('new')}</span>
					</button>
					<ul className='dropdown-menu'>
						<li>
							<a id='linkSpot' className='dropdown-item' href='#!' data-bs-toggle='modal' data-bs-target='#modalOrder'>
								Spot
							</a>
						</li>
						<li>
							<a id='linkFutures' className='dropdown-item' href='/traderbot/futures'>
								Futures
							</a>
						</li>
					</ul>
				</React.Fragment>
			) : (
				<button
					id='btnNewOrder'
					className='btn btn-primary d-inline-flex align-items-center'
					data-bs-toggle='modal'
					data-bs-target='#modalOrder'>
					<i className='fas fa-plus me-2'></i>
					<span className='d-none d-md-flex '>{i18n.t('new')}</span>
				</button>
			)}
		</React.Fragment>
	);
}

export default NewOrderButton;
