/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { i18n } from '../utils/translates/i18n';
import LoaderPage from '../utils/LoaderPage';
import { getWalletPreferred } from '../services/mmn/WalletsService';
import { FormateFiatCurrency } from '../utils/CustomFormatations';
import { checkSecurityPassword } from '../services/mmn/AuthService';
import { sendWithdraw } from '../services/mmn/WithdrawsService';
import Toast from '../components/Toast/Toast';

export default function WithdrawalBtc() {
	const token = sessionStorage.getItem('token');
	const navigate = useNavigate();
	const [show, setShow] = useState(false);

	const [alertMsg, setAlertMsg] = useState({ show: false, bg: 'success', msg: '' });

	const [wallet, setWallet] = useState();
	const [fee, setFee] = useState(0);
	const [balance, setBalance] = useState(0);
	const [minAmount, setMinAmount] = useState(0);
	const [securityPassword, setSecurityPassword] = useState('');
	const [amount, setAmount] = useState(0);

	const doGetWallet = async () => {
		const success = await getWalletPreferred(token);
		if (success.status) {
			setFee(5);
			setMinAmount(10);
			setAmount(10);
			setWallet(success.wallet.wallet);
			setBalance(success.balance);
		} else navigate('/logout');
	};

	const validateSecurityPassword = async () => {
		const success = await checkSecurityPassword(token, securityPassword);
		if (!success.status) {
			setSecurityPassword('');
			setAlertMsg({ show: true, bg: 'danger', msg: success.error });
		}
	};

	const validateAmount = () => {
		if (amount < minAmount) setAmount(minAmount);
		if (amount > balance) setAmount(balance);
		return;
	};

	const doWithdraw = async () => {
		setShow(false);
		await validateSecurityPassword();
		if (!securityPassword) setShow(true);
		else if (!amount || amount < minAmount) {
			setAlertMsg({ show: true, bg: 'danger', msg: `${i18n.t('min_amount_error')} ${FormateFiatCurrency(minAmount)}` });
			setShow(true);
		} else {
			const success = await sendWithdraw(token, amount, securityPassword);
			if (success.status) {
				setShow(true);
				setAlertMsg({ show: true, bg: 'success', msg: 'withdraw_sended_email' });
				doGetWallet();
			} else {
				setShow(true);
				setAlertMsg({ show: true, bg: 'danger', msg: success.error });
				doGetWallet();
			}
		}
	};

	useEffect(() => {
		const doCheck = async () => {
			document.title = `${i18n.t('withdrawal')} | ${process.env.REACT_APP_NAME}`;
			const token = sessionStorage.getItem('token');
			if (!token) navigate('/logout');
			doGetWallet();
			setShow(true);
		};
		doCheck();
	}, []);

	return !show ? (
		<LoaderPage />
	) : (
		<>
			{wallet ? (
				<section className='section'>
					<div className='container-fluid'>
						<div className='card card-body border-0 shadow mb-4 mt-4'>
							<div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
								<div className='d-block md-4 mb-md-0'>
									<h2 className='h4'>{i18n.t('withdrawal')}</h2>
								</div>
							</div>
							<div className='form-group'>
								<div className='row'>
									<div className='col-md-3 mb-3'>
										<label>{i18n.t('balance_available')} :</label>
										<br />
										<div className={`alert bg-default border border-1 fw-bold mb-0 `}>
											{FormateFiatCurrency(balance)}
										</div>
									</div>
									<div className='col-md-3 mb-3'>
										<label>{i18n.t('min_withdraw')} :</label>
										<br />
										<div className={`alert bg-default border border-1 fw-bold mb-0 `}>
											{FormateFiatCurrency(minAmount)}
										</div>
									</div>
									<div className='col-md-3 mb-3'>
										<label>{i18n.t('fee')}:</label>
										<div className={`alert alert-default border border-1 fw-bold mb-0`}> {fee}%</div>
									</div>
								</div>
								<div className='row'>
									<div className='col-8 mb-3'>
										<label>{i18n.t('wallet')}:</label>
										<div className={`alert alert-default border border-1 fw-bold mb-0`}>{wallet}</div>
									</div>
								</div>
								<div className='row'>
									<div className='col-md-4 mb-3'>
										<label>{i18n.t('security_password')}:</label>
										<input
											type='password'
											value={securityPassword}
											className={`form-control border border-1 border-dark fw-bold mb-0 h-75`}
											onChange={e => setSecurityPassword(e.target.value)}
											onBlur={validateSecurityPassword}
										/>
									</div>
									<div className='col-md-4 mb-3'>
										<label>{i18n.t('amount')}:</label>
										<input
											type='number'
											min={10}
											max={balance}
											step={1}
											value={amount}
											className={`form-control border border-1 border-dark fw-bold mb-0 h-75`}
											onChange={e => setAmount(e.target.value)}
											onBlur={validateAmount}
										/>
									</div>
								</div>
								{alertMsg.show && (
									<div className={`alert alert-${alertMsg.bg} h4 mt-2`}>{i18n.t(alertMsg.msg).toUpperCase()}</div>
								)}
								<div className='row'>
									<div className='col-md-1 mb-3'>
										<button
											type='button'
											className='btn btn-primary h-100'
											disabled={!securityPassword || !amount || amount < minAmount}
											onClick={doWithdraw}>
											{i18n.t('withdraw')}
										</button>
									</div>
									<div className='col-md-11 mb-3'>
										<div className='alert alert-default border shadow fs-5 fw-bold h-100'>{`${i18n.t(
											'withdraw',
										)}: ${FormateFiatCurrency(amount)} - ${i18n.t('fee')}: ${FormateFiatCurrency(
											(amount * fee) / 100,
										)} = ${i18n.t('total')}: ${FormateFiatCurrency(amount - (amount * fee) / 100)}`}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			) : (
				<section className='section'>
					<div className='container-fluid'>
						<div className='card card-body border-0 shadow mb-4 mt-4'>
							<div className='alert alert-warning d-flex justify-content-between align-items-center m-3'>
								<h4 className='text-dark'>
									<strong>{i18n.t('register_wallet')}</strong>
								</h4>
								<Link to='/dashboard/updatewallet'>
									<button className='btn btn-warning btn-block'>{i18n.t('update_wallet')}</button>
								</Link>
							</div>
						</div>
					</div>
				</section>
			)}
			<Toast />
		</>
	);
}
