import { Link } from 'react-router-dom';

export default function SubMenu({ item, ToggleBtn }) {
	const getClassName = itemName => (window.location.pathname === itemName ? 'bg-black' : '');

	return item.subNav ? (
		<li>
			<a className='has-arrow' href='#!' aria-expanded='false'>
				<div className='nav_icon_small'>{item.icon}</div>
				<div className='nav_title'>
					<span>{item.title}</span>
				</div>
			</a>
			<ul>
				{item.subNav?.map((subItem, si) =>
					subItem.subNav ? (
						<li key={si}>
							<a className='has-arrow' href='#!' aria-expanded='false'>
								<div className='nav_icon_small'>{subItem.icon}</div>
								<div className='nav_title'>
									<span>{subItem.title}</span>
								</div>
							</a>
							<ul>
								{subItem.subNav?.map((subSubItem, ssi) => (
									<li key={ssi} className={getClassName(subSubItem.path)}>
										<Link to={subSubItem.path} onClick={ToggleBtn} className='ms-3'>
											<div className='nav_icon_small'>{subSubItem.icon}</div>
											<div className='nav_title'>
												<span>{subSubItem.title}</span>
											</div>
										</Link>
									</li>
								))}
							</ul>
						</li>
					) : (
						<li key={si} className={getClassName(subItem.path)}>
							<Link to={subItem.path} onClick={ToggleBtn}>
								<div className='nav_icon_small'>{subItem.icon}</div>
								<div className='nav_title'>
									<span>{subItem.title}</span>
								</div>
							</Link>
						</li>
					),
				)}
			</ul>
		</li>
	) : (
		<li className={getClassName(item.path)}>
			<Link to={item.path} onClick={ToggleBtn}>
				<div className='nav_icon_small'>{item.icon}</div>
				<div className='nav_title'>
					<span>{item.title}</span>
				</div>
			</Link>
		</li>
	);
}
