const messages = {
	en: {
		translations: {
			a_crase: 'The',
			about: 'About Us',
			above_the_same: 'Above exactly the same',
			action: 'Action',
			actionTemplates: 'Stock Models',
			activate_date: 'activation date',
			ad: 'Announcement',
			add: 'Accession',
			add_option: 'add option',
			add_payment: 'Add Payment',
			add_question: 'Add Question',
			add_receipt: 'Add Receipt',
			add_wallet: 'Add Wallet',
			address: 'Address',
			admin: 'Administration',
			advance: 'Advance',
			affected: 'reached',
			after: 'After',
			air_transport: 'Air Transport',
			ajust_limite: 'Limit Adjustment',
			ajust_saldo: 'Balance Adjustment',
			all: 'All',
			all_departments: 'All Departments',
			allies_of: 'allies of',
			allRightsReserved: 'All rights reserved.',
			almost_there: 'Almost there',
			already_offset: 'already compensated',
			already_registered: 'Already Registered? Click Here and Enter',
			amount: 'Value',
			and: 'It is',
			and_more: 'and more',
			answer: 'Response',
			ao: 'to the',
			approve: 'To approve',
			April: 'April',
			asc: 'Ascendant',
			at: 'in',
			August: 'August',
			automations: 'Automations',
			awaiting_email_confirmation: 'Awaiting email confirmation!',
			b_order: 'Order',
			b_orders: 'orders',
			back: 'To go back',
			bairroInteresse: 'Neighborhood',
			balance: 'Balance',
			balance_available: 'Balance available',
			banned: 'Banned',
			before_contact: 'Before contacting us',
			binary: 'Binary',
			birth: 'Birth',
			blind_man: 'reversed',
			body_monthly:
				'Your monthly fee has expired and your BOT TRADER has been inactivated, please pay the Money Order and enjoy your benefits again',
			bonus_saldo_trader: 'Trader Balance Bonus',
			but_possible_register: 'Even so, it is possible to register, but it will not be related to any referring friend.',
			buyer: 'Buyer',
			by: 'per',
			bye: 'See you later',
			call: 'Called',
			call_to: 'Call to',
			calls: 'called',
			cancel: 'Cancel',
			canceled: 'Canceled',
			career_plan: 'Career path',
			casa_verde_amarela: 'yellow green house',
			cash_payment: 'Cash payment',
			cash_payment_return: 'Cash payment return',
			cash_receipt: 'cash receipt',
			change_indicant: 'Change Indicator',
			change_photo: 'Change Photo',
			change_plan: 'change plan',
			check_payment: 'Payment in check',
			check_payment_return: 'Return payment by check',
			check_receipt: 'receipt by check',
			choose: 'To choose',
			cidadeInteresse: 'City',
			city: 'City',
			claims: 'Claims',
			clean: 'To clean',
			click_and_know: 'Click and Know',
			click_to_copy_the_wallet: 'Click to Copy Portfolio',
			client: 'Client',
			clients: 'Customers',
			cnh: "Driver's License (Driver's License)",
			cnhDate: "Driver's License Issue Date",
			cnpj: 'CNPJ',
			cnpj_invalid: 'Invalid CNPJ',
			color: 'Color',
			commercial: 'Commercial',
			company: 'Company Name',
			complement: 'Complement',
			comporRenda: 'Go compose income with someone',
			compRenda: 'Type of Proof of Income',
			confirm_approve: 'confirm approval',
			confirm_delete: 'confirm deletion',
			confirm_new_password: 'Confirm New Password',
			confirm_new_password_security: 'Confirm new security password',
			confirm_password: 'Confirm Access Password',
			confirm_password_security: 'Confirm security password',
			confirmed: 'Confirmed',
			confirmed_email: 'confirmed email',
			congratulations: 'Congratulations',
			contact: 'Contact',
			contract: 'Contract',
			copied: 'copied',
			copy: 'Copy',
			copy_success: 'Successfully copied!',
			correspondences: 'Correspondence',
			countries: 'countries',
			country: 'Country',
			cpf: 'CPF',
			cpf_invalid: 'Invalid CPF',
			create_order_success: 'Order Created Successfully!',
			created: 'Registered in',
			cred: 'Credit',
			cred_binario: 'Binary Credit',
			cred_deb: 'Credits/Debits',
			cred_game_donate: 'Game Donate Credit',
			cred_ind_direta: 'Indirect referral credit',
			cred_ind_indireta: 'Direct Referral Credit',
			cred_mandala: 'Mandala Credit',
			cred_pagar_com_saldo: 'Credit Pay with Balance',
			cred_pontos: 'Credit Points',
			cred_recb_trader: 'Credit Receive Trader',
			cred_renovacao: 'Renewal Credit',
			cred_saldo_disp: 'Credit Available Balance',
			cred_upgrade: 'UpGrade Credit',
			credit_card_payment: 'Credit card payment',
			credit_card_payment_return: 'Credit card payment return',
			credit_card_receipt: 'Credit card receipt',
			current: 'Current',
			current_password: 'Current password',
			current_wallet: 'Current Portfolio',
			dashboard: 'Control Panel',
			data_added: 'Added data',
			data_saved: 'Saved Data',
			datas: 'Data',
			date: 'Date',
			date_first_installment: 'First payment date',
			deb_de_trader: 'Trader Debt',
			deb_game_donate: 'Game Donate Debit',
			deb_saldo: 'Debit Balance',
			deb_saldo_disp: 'Debit Available Balance',
			deb_val_indev: 'Debit Improper Amount',
			debit_card_payment: 'Debit card payment',
			debit_card_payment_return: 'Debit card payment return',
			debit_card_receipt: 'Debit card receipt',
			debt: 'Debit',
			debt_td: 'debt',
			December: 'December',
			decImpRenda: 'declare income tax',
			deleted_document: 'Document has been deleted from your register',
			department: 'Department',
			dependents: 'Dependents',
			desc: 'Downward',
			description: 'Description',
			details: 'Details',
			dev_econom: 'Economic Development',
			developer: 'Developer',
			directly_indicated: 'directly indicated',
			directly_indicateds: 'Directly appointed',
			directs: 'Direct',
			district: 'Neighborhood / district',
			divorced: 'Divorced',
			doa_men_receb: 'Monthly Platform Donation Received',
			doacao_td: 'Donation',
			doctorate_complete: 'Doctorate - Complete',
			doctorate_incomplete: 'Doctorate - Incomplete',
			document: 'Document',
			document_already_registered: 'Document already registered',
			documents: 'Documents',
			donate: 'Donate',
			donor: 'Donor',
			dont_know_zip: "Don't know the zip code? Click here",
			dou_fe: 'I confirm that the above information is correct.',
			downloads: 'Download Files',
			drop_images: 'Click or Drag the images here',
			due_date: 'Maturity',
			edit: 'To edit',
			edit_option: 'edit option',
			edit_question: 'edit question',
			email: 'Email',
			email_already_registered: 'Email already registered',
			email_and_or_password_wrong: 'Wrong email and/or password!',
			email_for_login: 'This email will be your login',
			email_or_password_wrong: 'Incorrect email or password',
			employee: 'Employee',
			employees: 'Employees',
			empty: 'Empty',
			entrada: 'Does it have entry value? If YES, what value',
			entries_until_today: 'Entries to date',
			error_confirm_password: 'Confirmation and New Password must be the same',
			error_email: 'Invalid Email Format',
			error_password: 'Incorrect Password',
			error_phone: 'Invalid Phone Number',
			estadoInteresse: 'state',
			exchange: 'Replacement',
			expired: 'Expired',
			extorno_td: 'refund',
			extra: 'Extra',
			extract: 'Extract',
			extracts: 'extracts',
			fantasy: 'Fantasy',
			fatherName: "Father's name",
			February: 'February',
			fee: 'Withdrawal Fee',
			feedback: 'Return',
			feminine: 'Feminine',
			fgts: 'Value of your FGTS',
			field_required: 'mandatory field',
			final: 'Final',
			finance: 'Financial',
			financial: 'Financial',
			financial_payment: 'financial payment',
			financial_payment_return: 'Return of financial payment',
			financial_receipt: 'financial receipt',
			financing: 'Financing',
			financings: 'Financing',
			first_10_pages: 'first 10 pages',
			fisica: 'Physical person',
			for_year: 'Per annum',
			forget_password: 'I forgot the password',
			forgot_password: 'Forgot password',
			form: 'Form',
			form_of_payment: 'Form of payment',
			found: 'Found',
			founds: 'Found',
			friend_indicated: 'Recommended by my friends',
			friend_indicateds: 'Recommended by my friends',
			fuel: 'Fuel',
			full_name: 'Full name',
			funcPublico: 'Are you a public servant?',
			fundamental_complete: 'Elementary - Complete',
			fundamental_incomplete: 'Elementary - Incomplete',
			ganh_diario: 'Daily Earnings',
			gender: 'Gender',
			grand_total: 'Grand total',
			group: 'Group',
			has_withdraw_today: 'A withdrawal has already been requested today',
			home: 'Start',
			how_it_works: 'How it works',
			how_to_call: 'What do you want to be called?',
			id_td: 'Direct Indication',
			images: 'Images',
			in_construction: 'Under construction',
			in_order: 'In order',
			in_stock: 'In stock',
			indicant: 'Indicator',
			indicated_by: 'You are being nominated by',
			indicated_not_found: 'The indicated Indicator was not found...',
			indicateds: 'Indicated',
			indirects: 'Indirect',
			info: 'Newsletters',
			info_contact: 'Contact information',
			initial: 'Initial',
			insert_new: 'Insert New',
			installments: 'installments',
			intelligence: 'Intelligence',
			interesse: 'What is the region of interest',
			invalid_contact: 'Invalid contact format',
			invalid_images: 'invalid images',
			invalid_token: 'Invalid Token',
			invite: 'To invite',
			invite_friends: 'Invite your friends',
			January: 'January',
			July: 'July',
			June: 'June',
			juridica: 'Legal person',
			know_exact_result: 'Do you know the exact value?',
			lead: 'Together we will be building a new future.',
			learn_more: 'Know more',
			left: 'Left',
			left_preferential: 'Preferring the left side',
			legalNature: 'Legal Nature',
			level: 'Level',
			limite_excedido: 'Exceeded limit debit',
			linear: 'Linear',
			link_email: 'Click on the Link Sent to the Registered Email!',
			link_invite_copy: 'Share link successfully copied',
			liquid: 'Liquid',
			list: 'List',
			loading: 'Loading',
			location: 'Location',
			login: 'To enter',
			logout: 'To go out',
			male: 'Masculine',
			manage_users: 'Manage Users',
			manager: 'Manager',
			mandala: 'Business Group',
			mandalas: 'Business Groups',
			manufacturer: 'Manufacturer',
			March: 'March',
			maritalStatus: 'Marital status',
			market: 'Market',
			married: 'Married',
			master_complete: "Master's - Complete",
			master_incomplete: "Master's - Incomplete",
			May: 'May',
			message: 'Message',
			messageNotSent: 'It was not possible to send your message',
			messageSent: 'Your message has been sent successfully',
			middle_complete: 'Medium - Complete',
			middle_incomplete: 'Medium - Incomplete',
			min_amount_error: 'The value must be at least:',
			min_withdraw: 'Minimum Amount for Withdrawal',
			missing: 'Missing',
			mission: 'Mission',
			mission_vision_values: 'Mission, vision and values',
			model: 'Model',
			monitors: 'Monitors',
			monthly: 'Monthly payment',
			motherName: "mother's name",
			movements: 'Movements',
			msg_renovacao: 'You have reached your earning limit, renew your package.',
			my_account: 'My account',
			my_wallet: 'My wallet',
			mystock: 'My Stock',
			name: 'Name',
			nascMaisVelhoRenda2: "What is the oldest's date of birth?",
			nationality: 'Nationality (Country of birth)',
			naturalness: 'Birthplace (City where you were born)',
			nature: 'Nature',
			network: 'Network',
			new: 'New',
			new_call: 'New Calling',
			new_financing: 'New Financing',
			new_order: 'New order',
			new_password: 'New Password',
			new_password_security: 'New security password',
			new_seller: 'New Seller',
			new_wallet: 'New Portfolio',
			news: 'News',
			next: 'Next',
			nick: 'User',
			nis: 'PIS/NIS',
			nis_already_registered: 'PIS/NIS already registered',
			no: 'No',
			no_approve: 'still not approve',
			no_delete: 'do not exclude',
			no_literate: 'not literate',
			no_repeat: 'Data cannot be repeated',
			no_results_found: 'no results found',
			no_services_or_products_added: 'No services or products added',
			no_settings: 'Go to Settings and fill in your details.',
			none_friends_indicated: "I haven't referred any friends yet",
			not_authorized: 'Not authorized',
			not_found: 'Not found',
			not_login: 'It was not possible to enter, check the data and try again!',
			not_orders: 'No Money Orders',
			not_permission: "You don't have permission to access this page.",
			not_registered: 'Not registered yet? Click here and register',
			not_results: 'No Records Found',
			not_self_register: 'Unable to register',
			not_today_entry: "We still haven't had tickets today",
			notfound_ad: 'No ads found',
			November: 'November',
			number: 'Number',
			obs: 'Comments',
			October: 'October',
			of: 'in',
			off: 'Wiped out',
			on_total: 'of the total',
			open: 'Open',
			opening: 'Opening',
			operation: 'Performance',
			ordenation: 'ordination',
			order: 'Order',
			order_not_found: 'order not found',
			orders: 'Requests',
			other: 'Other',
			other_services_or_products: 'Other Services or Products',
			paid: 'Paid out',
			paid_date: 'Pay day',
			partial: 'Partially Paid',
			passport: 'Passport',
			password: 'Password',
			password_security: 'security password',
			pay: 'Pay',
			pay_generate: 'generate payment',
			pay_selected: 'Pay Selected',
			pay_value_in: 'pay the amount in',
			payable: 'Pay',
			payer: 'Payer',
			payment: 'Payment',
			ped_retirada: 'Withdrawal Request',
			ped_retirada_gan_diario: 'Request for Withdrawal of Daily Earnings',
			pendent: 'Pending',
			people: 'People',
			person: 'Person',
			person_found_in_other_prefecture: 'Person already registered in another municipality',
			personal: 'Guys',
			personal_addresses: 'Adresses',
			personal_contacts: 'Contacts',
			personal_data: 'Personal data',
			personal_infos: 'Information',
			personal_pcva: 'Green and Yellow House Program',
			phone: 'phone',
			pix: 'PIX',
			pix_payment: 'Payment in PIX',
			pix_payment_return: 'Payment return in PIX',
			pix_receipt: 'Receipt in PIX',
			pl_carreira: 'Career path',
			plan: 'Flat',
			plates: 'Plates',
			please_wait: 'Please wait',
			plus: 'plus',
			point: 'Point',
			points: 'Points',
			portfolio: 'portfolio',
			pos_complete: 'Lato sensu - Complete',
			pos_incomplete: 'Lato sensu - Incomplete',
			prefecture: 'City hall',
			prefecture_addresses: 'Adresses',
			prefecture_contacts: 'Contacts',
			prefecture_data: 'City Hall Data',
			prefecture_infos: 'Information',
			prefectures: 'city halls',
			prev: 'Previous',
			price: 'Price',
			print: 'Print out',
			privacy_policies: 'Privacy Policy',
			private_works: 'Private Works',
			profession: 'Profession',
			profile: 'Profile',
			program: 'Program',
			programs: 'Software',
			project: 'Project',
			projects: 'Projects',
			public_works: 'Public works',
			purchase: 'Purchase',
			qualify_binary: 'Binary Qualification',
			qualquer: 'Any',
			quantity: 'Amount',
			quotation: 'Price',
			read: 'To read',
			receivable: 'To receive',
			recipient: 'receiver',
			recommendations: 'Recommendations',
			redefine_password: 'Redefine password',
			register: 'Record',
			register_people: 'People Registration',
			register_wallet: 'Register a BTC Wallet to make withdrawals',
			registers: 'Records',
			registers_users: 'User Registration',
			reject: 'Reject',
			remaining: 'Remaining',
			remove: 'To remove',
			rendaIndividual: 'Individual Gross Income',
			renew: 'Renew',
			renew_mandala: 'Mandala Renewal',
			reply: 'To respond',
			reports: 'Reports',
			required_data_not_sent: 'Required data not sent',
			resend_email: 'resend the email',
			residential: 'Residential',
			responded: 'Answered',
			restricao: 'Do you have restriction in your name? (SPC/Serasa)',
			result: 'Result',
			results: 'Results',
			resume_data: 'Data summary',
			revoked: 'revoked',
			rg: 'General Registration (Identity)',
			rgAgency: 'RG issuer',
			rgDate: 'RG Issuance Date',
			rgUf: 'ID status',
			right: 'Right',
			right_preferential: 'Preferring the right side',
			roi: 'Daily Earnings',
			sale: 'Sale',
			saq_duplicado: 'Duplicate Withdrawal',
			save: 'To save',
			schooling: 'Education',
			search: 'Search',
			secretary: 'Secretary',
			security_password: 'security password',
			see: 'To see',
			see_advents: 'see the ads',
			see_call: 'see call',
			see_details: 'see details',
			see_details_wallet: 'View Portfolio Details',
			see_financing: 'See funding',
			see_order: 'View order',
			see_user: 'View Users',
			select_vehicle: 'select the vehicle',
			self_register: 'Register',
			seller: 'Seller',
			send_call: 'Send Ticket',
			send_to: 'Send to',
			September: 'September',
			settings: 'settings',
			share_your_link: 'Share your Link',
			show: 'show up to',
			show_from: 'showing from',
			side: 'Side',
			sign_in_to_start_your_session: 'Log in to start your session',
			sign_up: 'Register',
			single: 'Single',
			sold: 'Sold',
			state: 'state',
			status: 'Status',
			strategies: 'Strategies',
			street: 'Road',
			subject: 'Subject',
			submit: 'To send',
			submit_placeholder: 'Please fill in your email',
			subscribe: 'Sign up to receive our news',
			subtitle: 'Caption',
			success_update_user: 'User updated successfully!',
			success_wallet: 'Portfolio Updated Successfully!',
			suggestions: 'suggestions',
			superior_complete: 'Graduated',
			superior_incomplete: 'Incomplete higher',
			supervisor: 'Supervisor',
			support: 'Support',
			support_materials: 'Support Materials',
			suspended: 'Suspended',
			symbols: 'Symbols',
			system: 'System',
			target: 'Goal',
			tempoCarteira: 'How long has the record been in the portfolio',
			terms: 'I accept the terms of use',
			this_value_in: 'This value in',
			title: 'Title',
			to: 'Until',
			to_pay: 'To switch off',
			to_receive: 'receivable',
			today_entry: "Today's Entry",
			token_not_found_or_already_used: 'Token not found or already used',
			total: 'Total',
			total_entry: 'Total Entry',
			traderbot: 'bot trader',
			transf_credito: 'Credit Transfer',
			transf_debito: 'Debit Transfer',
			transfer_payment: 'transfer payment',
			transfer_payment_return: 'Transfer payment return',
			transfer_receipt: 'receipt in transfer',
			transparency: 'Transparency',
			tx_adm_cred: 'Administrate tax',
			tx_adm_deb: 'Administrate tax',
			tx_retirada: 'Withdrawal Fee',
			tx_retirada_gan_diario: 'Daily Earnings Withdrawal Rate',
			type: 'Type',
			update: 'To update',
			update_wallet: 'Update Portfolio',
			upgrade: 'Improvement',
			used: 'Used',
			user: 'User',
			user_active: 'active user',
			user_already_exists: 'User already exists!',
			user_and_or_password_wrong: 'Wrong username and/or password!',
			user_inactive: 'inactive user',
			user_not_found: 'User not found',
			user_not_logged: 'User not logged in',
			user_or_password_wrong: 'incorrect username or password',
			users: 'Users',
			users_list: 'User List',
			valid_images: 'valid images',
			valorRenda2: 'What is the value of the second income',
			value: 'Value',
			value_donate_on: 'Donation Value in',
			value_financed: 'financed amount',
			value_installment: 'installment value',
			values: 'Values',
			vehicle: 'Vehicle',
			vehicles: 'Vehicles',
			view_order: 'See Order',
			vision: 'Vision',
			visitor: 'Visitor',
			voucher: 'Voucher',
			wait_confirm: 'Waiting confirmation',
			waiting: 'Waiting',
			waiting_email: 'The registration was carried out successfully, now all that remains is to confirm the email.',
			waiting_payment: 'Awaiting payment',
			wallet: 'Portfolio',
			wallet_generate: 'Generate Portfolio',
			wallets: 'wallets',
			want_donate: 'I want to donate',
			want_now_more_about: 'I want to know more about',
			warning: 'ATTENTION',
			we_are: 'Are',
			webhooks: 'webhooks',
			welcome: 'Welcome',
			who_we_are: 'Who we are',
			who_we_are_subtitle: 'A brief description about the',
			widower: 'Widower',
			will_expire_in: 'will expire in',
			with: 'with',
			withdraw: 'Withdrawal',
			withdraw_sended_email: 'Withdrawal requested, check your email and click on the link to authorize!',
			withdrawal: 'Withdrawals',
			withdrawals: 'Withdrawals',
			year: 'Year',
			years: 'Years',
			yes: 'Yes',
			your_country: 'Your country',
			your_dashboard: 'This is your Control Panel.',
			your_link: 'Your Link',
			zipcode: 'Zip code',
			amount_above_balance: 'Insufficient balance',
			symbol: 'Symbol',
			close: 'Close',
			high: 'High',
			low: 'Low',
			order_book: 'Order book',
			coin: 'Coin',
			free: 'Free',
			locked: 'Locked',
			you_have: 'You have',
			buy_volume: 'Buy Volume',
			sell_volume: 'Sell Volume',
			estimate: 'Estimate',
			photo: 'Photo',
			telegram_chat_id: 'Telegram Chat ID',
			pendent_order: 'You have pending orders',
			active_automations: 'Active automations',
			active_monitors: 'Active monitors',
			active_users: 'Active users',
			active_connections: 'Active connections',
			ticker_health: 'Ticker Health',
			book_health: 'Book Health',
			enter_your_new_password: 'Enter your new password',
			confirm_your_new_password: 'Enter your new password again',
			alerts: 'Warning',
			alert: 'Alert',
			how_create_binance_apikey: 'How to create the API key at Binance',
			text_hint_binance:
				'When creating the API key at Binance inserts the IP access restrictions:\n172.105.78.139\nThen check the options:\n• ENABLE EUROPEAN OPTIONS\n• ENABLE SPOT & MARGIN TRADING',
		},
	},
};

export { messages };
