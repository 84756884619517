/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { i18n } from '../../utils/translates/i18n';
import Translate from '../../utils/translates/components/Translate';
import { FormateDate } from '../../utils/CustomFormatations';
import { getCalls } from '../../services/mmn/CallsService';
import { getMyUserData } from '../../services/mmn/UsersService';

export default function TopBar() {
	const [show, setShow] = useState(false);
	const [calls, setCalls] = useState([]);
	const [count, setCount] = useState(0);
	const [user, setUser] = useState({});

	const ReadCall = data => {
		sessionStorage.setItem('idCall', data);
		window.location = '/dashboard/readcall';
	};
	const listCalls = async token => {
		const doGetCalls = await getCalls(token, 1, 10);
		if (doGetCalls.status) {
			setCount(doGetCalls.list.count);
			setCalls(doGetCalls.list.rows);
		}
		const doGetUser = await getMyUserData(token);
		if (doGetUser.status) setUser(doGetUser.user);
	};

	const ToggleBtn = () => document.querySelector('.Menu_NOtification_Wrap').classList.toggle('active');

	const miniSide = () => {
		document.querySelector('.sidebar').classList.toggle('mini_sidebar');
		document.querySelector('.main_content ').classList.toggle('full_main_content');
		document.querySelector('.footer_part ').classList.toggle('full_footer');
	};

	const sidebarIcon = () => document.querySelector('.sidebar').classList.toggle('active_sidebar');

	useEffect(() => {
		const token = sessionStorage.getItem('token');
		if (token) {
			const init = async () => {
				await listCalls(token);
				setShow(true);
			};
			init();
		}
	}, []);

	const viewPage = useMemo(
		() => (
			<div className='container-fluid g-0'>
				<div className='row'>
					<div className='col-lg-12 p-0'>
						<div className='header_iner d-flex justify-content-between align-items-center'>
							<div className='sidebar_icon d-lg-none' style={{ cursor: 'pointer' }} onClick={sidebarIcon}>
								<i className='fas fa-bars'></i>
							</div>
							<div
								className='line_icon open_miniSide d-none d-lg-block'
								style={{ cursor: 'pointer' }}
								onClick={miniSide}>
								<img src='/assets/img/line_img.png' alt='bar' />
							</div>
							<div className='header_right d-flex justify-content-between align-items-center'>
								{show && (
									<>
										<div className='header_notification_warp d-flex align-items-center'>
											<li>
												<Link to='/traderbot/dashboard' className='bg-light p-0'>
													<i className='fas fa-robot m-0'></i>
												</Link>
											</li>
											<li>
												<a
													className={`bell_notification_clicker ${count > 0 && 'nav-link-notify'}`}
													href='#!'
													onClick={ToggleBtn}>
													<img src='/assets/img/icon/msg.svg' alt='' />
												</a>

												<div className='Menu_NOtification_Wrap' id='notmsg'>
													<div className='notification_Header'>
														<h4>Notifications</h4>
													</div>
													<div className='Notification_body'>
														{calls?.map((c, i) => (
															<div key={i} className='single_notify d-flex align-items-center'>
																<div className='notify_thumb'>
																	<a href='#!' alt='msgs' onClick={() => ReadCall(c.id)}>
																		<span className={`badge bg-${c.statuses?.bg}`}>
																			<i className='fas fa-envelope fa-2x me-2'></i>
																			<br />
																			{i18n.t(c.statuses?.status)}
																			<br />
																			<small>{FormateDate(c.createdAt)}</small>
																		</span>
																	</a>
																</div>
																<div className='notify_content'>
																	<a href='#!' onClick={() => ReadCall(c.id)}>
																		<h5>{c.subject?.toUpperCase()}</h5>
																	</a>
																	<p>{c.description}</p>
																</div>
															</div>
														))}
													</div>
													<div className='nofity_footer'>
														<div className='submit_button text-center pt_20'>
															<Link to='/dashboard/call' className='btn_1 green' onClick={ToggleBtn}>
																{i18n.t('all')}
															</Link>
														</div>
													</div>
												</div>
											</li>
										</div>
										<div className='profile_info d-flex align-items-center'>
											<div className='profile_thumb mr_20'>
												<img
													src={`${process.env.REACT_APP_API_URL}/images/${user?.photo}`}
													alt='user avatar'
													style={{ width: '30px' }}
													crossOrigin='anonymous'
												/>
											</div>
											<div className='author_name d-none d-md-flex'>
												<h4 className='f_s_15 f_w_500 mb-0'>{user?.name}</h4>
											</div>
											<div className='profile_info_iner'>
												<div className='profile_author_name'>
													<p>{user?.nick}</p>
													<h5>{user?.name}</h5>
												</div>
												<div className='profile_info_details'>
													<Link to='/dashboard/profile'>{i18n.t('personal_data')}</Link>
													<Link to='/dashboard/order'>{`${i18n.t('orders')}`}</Link>
													<Link to='/traderbot/dashboard'>{`${i18n.t('traderbot')}`}</Link>
													<Link to='/logout'>{i18n.t('logout')}</Link>
												</div>
											</div>
										</div>
									</>
								)}
								<Translate lngName={'none'} classVar={''} />
							</div>
						</div>
					</div>
				</div>
			</div>
		),
		[show],
	);

	return viewPage;
}
