import { i18n } from '../../utils/translates/i18n';

export const SidebarData = [
	{
		title: i18n.t('dashboard'),
		path: '/dashboard',
		icon: <i className='fas fa-home'></i>,
	},
	{
		title: i18n.t('traderbot'),
		icon: <i className='fas fa-robot'></i>,
		subNav: [
			{
				title: i18n.t('dashboard'),
				path: '/traderbot/dashboard',
				icon: (
					<svg className='icon icon-xs' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
						<path d='M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z'></path>
						<path d='M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z'></path>
					</svg>
				),
			},
			{
				title: i18n.t('b_orders'),
				path: '/traderbot/orders',
				icon: (
					<svg className='icon icon-xs' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
						<path d='M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z'></path>
						<path
							fillRule='evenodd'
							d='M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z'
							clipRule='evenodd'></path>
					</svg>
				),
			},
			/* {
				title: i18n.t('strategies'),
				path: '/traderbot/strategies',
				icon: (
					<svg className='icon icon-xs' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
						<path d='M10 3.5a1.5 1.5 0 013 0V4a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-.5a1.5 1.5 0 000 3h.5a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-.5a1.5 1.5 0 00-3 0v.5a1 1 0 01-1 1H6a1 1 0 01-1-1v-3a1 1 0 00-1-1h-.5a1.5 1.5 0 010-3H4a1 1 0 001-1V6a1 1 0 011-1h3a1 1 0 001-1v-.5z' />
					</svg>
				),
			}, */
			{
				title: i18n.t('automations'),
				path: '/traderbot/automations',
				icon: (
					<svg className='icon icon-xs' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
						<path d='M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z'></path>
					</svg>
				),
			},
			/* {
				title: i18n.t('actionTemplates'),
				path: '/traderbot/orderTemplates',
				icon: (
					<svg className='icon icon-xs' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
						<path d='M3 4a1 1 0 011-1h12a1 1 0 011 1v2a1 1 0 01-1 1H4a1 1 0 01-1-1V4zM3 10a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H4a1 1 0 01-1-1v-6zM14 9a1 1 0 00-1 1v6a1 1 0 001 1h2a1 1 0 001-1v-6a1 1 0 00-1-1h-2z' />
					</svg>
				),
			}, */
			/* {
				title: i18n.t('actionTemplates'),
				icon: (
					<svg className='icon icon-xs' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
						<path d='M3 4a1 1 0 011-1h12a1 1 0 011 1v2a1 1 0 01-1 1H4a1 1 0 01-1-1V4zM3 10a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H4a1 1 0 01-1-1v-6zM14 9a1 1 0 00-1 1v6a1 1 0 001 1h2a1 1 0 001-1v-6a1 1 0 00-1-1h-2z' />
					</svg>
				),
				subNav: [
					{
						title: i18n.t('b_orders'),
						path: '/traderbot/orderTemplates',
						icon: <i className='fas fa-chevron-right'></i>,
					},
					{
						title: i18n.t('webhooks'),
						path: '/traderbot/webhooks',
						icon: <i className='fas fa-chevron-right'></i>,
					},
					{
						title: i18n.t('withdraw'),
						path: '/traderbot/withdrawTemplates',
						icon: <i className='fas fa-chevron-right'></i>,
					},
				],
			}, */
			/* {
				title: i18n.t('monitors'),
				path: '/traderbot/monitors',
				icon: (
					<svg className='icon icon-xs' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
						<path
							fillRule='evenodd'
							d='M3 5a2 2 0 012-2h10a2 2 0 012 2v8a2 2 0 01-2 2h-2.22l.123.489.804.804A1 1 0 0113 18H7a1 1 0 01-.707-1.707l.804-.804L7.22 15H5a2 2 0 01-2-2V5zm5.771 7H5V5h10v7H8.771z'
							clipRule='evenodd'
						/>
					</svg>
				),
			}, */
			/* {
				title: i18n.t('settings'),
				icon: (
					<svg className='icon icon-xs' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
						<path d='M3 4a1 1 0 011-1h12a1 1 0 011 1v2a1 1 0 01-1 1H4a1 1 0 01-1-1V4zM3 10a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H4a1 1 0 01-1-1v-6zM14 9a1 1 0 00-1 1v6a1 1 0 001 1h2a1 1 0 001-1v-6a1 1 0 00-1-1h-2z' />
					</svg>
				),
				subNav: [
					{
						title: i18n.t('personal'),
						path: '/traderbot/settings',
						icon: <i className='fas fa-chevron-right'></i>,
					},
					{
						title: i18n.t('symbols'),
						path: '/traderbot/symbols',
						icon: <i className='fas fa-chevron-right'></i>,
					},
				],
			}, */
			{
				title: i18n.t('reports'),
				path: '/traderbot/reports',
				icon: (
					<svg
						xmlns='http://www.w3.org/2000/svg'
						className='icon icon-xs'
						fill='currentColor'
						viewBox='0 0 24 24'
						strokeWidth='1.5'
						stroke='#1a1a1a'>
						<path
							strokeLinecap='round'
							strokeLinejoin='round'
							d='M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z'
						/>
					</svg>
				),
			},
			{
				title: i18n.t('wallet'),
				path: '/traderbot/wallet',
				icon: (
					<svg
						xmlns='http://www.w3.org/2000/svg'
						fill='currentColor'
						viewBox='0 0 24 24'
						strokeWidth='1.5'
						stroke='#1a1a1a'
						className='icon icon-xs'>
						<path
							strokeLinecap='round'
							strokeLinejoin='round'
							d='M21 12a2.25 2.25 0 00-2.25-2.25H15a3 3 0 11-6 0H5.25A2.25 2.25 0 003 12m18 0v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 9m18 0V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v3'
						/>
					</svg>
				),
			},
		],
	},
	{
		title: i18n.t('network'),
		icon: <i className='fas fa-sitemap'></i>,
		path: '/dashboard/businessgroup',
		/* 
		subNav: [
			{
				title: i18n.t('mandala'),
				icon: <i className='fas fa-chevron-right'></i>,
			},
			{
				title: i18n.t('directs'),
				path: '/dashboard/directs',
				icon: <i className='fas fa-chevron-right'></i>,
			},
			{
				title: i18n.t('linear'),
				path: '/dashboard/linear',
				icon: <i className='fas fa-chevron-right'></i>,
			},
			{
				title: i18n.t('binary'),
				path: '/dashboard/binary',
				icon: <i className='fas fa-chevron-right'></i>,
			}, 
		],
		*/
	},
	{
		title: i18n.t('finance'),
		icon: <i className='fas fa-dollar-sign'></i>,
		subNav: [
			{
				title: i18n.t('orders'),
				path: '/dashboard/order',
				icon: <i className='fas fa-chevron-right'></i>,
			},
			{
				title: i18n.t('extract'),
				path: '/dashboard/extract',
				icon: <i className='fas fa-chevron-right'></i>,
			},
			{
				title: i18n.t('update_wallet'),
				path: '/dashboard/updatewallet',
				icon: <i className='fas fa-chevron-right'></i>,
			},
			{
				title: i18n.t('withdrawal') + ' BTC',
				path: '/dashboard/withdrawalbtc',
				icon: <i className='fas fa-chevron-right'></i>,
			},
			/* 
			{
				title: i18n.t('balance'),
				path: '/dashboard/paywithbalance',
				icon: <i className='fas fa-chevron-right'></i>,
			},
			*/
		],
	},
	{
		title: i18n.t('calls'),
		path: '/dashboard/call',
		icon: <i className='fas fa-phone'></i>,
	},
	{
		title: i18n.t('personal_data'),
		icon: <i className='fas fa-user-check'></i>,
		path: '/dashboard/profile',
	},
	/* {
		title: i18n.t('support_materials'),
		path: '/dashboard/materials',
		icon: <i className='fas fa-file-download'></i>,
	}, */

	{
		title: i18n.t('logout'),
		path: '/logout',
		icon: <i className='fas fa-sign-out-alt'></i>,
	},
];
