import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { i18n } from '../utils/translates/i18n';
import Loader from '../utils/Loader';
import Translate from '../utils/translates/components/Translate';
import { sendToForget } from '../services/mmn/AuthService';

const Forget = () => {
	const [loader, setLoader] = useState(false);

	const [nick, setNick] = useState('');

	const sendMailPassword = async () => {
		setLoader(true);
		const success = await sendToForget(nick);
		if (success.status) {
			setLoader(false);
			window.location.href = '/waitconfirm';
		} else {
			setLoader(false);
		}
	};

	useEffect(() => {
		const toTop = () => {
			document.title = `${i18n.t('forget')} | ${process.env.REACT_APP_NAME}`;
		};
		toTop();
	}, []);

	return (
		<>
			<div className='wrapper vh-100 d-flex align-items-center'>
				<div className='mx-auto col-md-4 mb-3'>
					<Link to='/'>
						<img src='/logos/logo500w.png' alt='logo' className='img-fluid' />
					</Link>
					<div className='card'>
						<div className='card-body'>
							<div className='d-flex mx-2 align-items-center justify-content-between'>
								<p className='h3'>{i18n.t('forgot_password')}?</p>
								<Translate />
							</div>

							<label>{i18n.t('nick')}:</label>
							<div className='input-group mb-3'>
								<div className='input-group-text'>
									<span className='fas fa-user'></span>
								</div>
								<input
									type='text'
									className='form-control border'
									placeholder={`*${i18n.t('nick')}`}
									onChange={e => setNick(e.target.value)}
								/>
							</div>
							<div className='d-flex justify-content-between'>
								<button className='btn btn-dark border-light' disabled={!nick} onClick={sendMailPassword}>
									{i18n.t('submit')}
								</button>
								<div className='ms-2'>
									<Link className='btn btn-sm' to='/login'>
										{i18n.t('already_registered')}
									</Link>
									<Link className='btn btn-sm' to='/register'>
										{i18n.t('not_registered')}
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Loader show={loader} onHide={() => setLoader(false)} />
		</>
	);
};

export default Forget;
