import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { i18n } from '../utils/translates/i18n';
import Loader from '../utils/Loader';
import { doReconfirmEmail } from '../services/mmn/AuthService';

const WaitReConfirm = () => {
	const [loader, setLoader] = useState(false);

	let { nick } = useParams();
	const [sended, setSended] = useState(false);
	const [email, setEmail] = useState('');

	const search = async () => {
		setLoader(true);
		const success = await doReconfirmEmail(nick);
		if (success.status) {
			setEmail(success.email);
			setSended(true);
			setLoader(false);
		} else {
			setSended(false);
			setLoader(false);
		}
	};

	useEffect(() => {
		const toTop = () => {
			document.title = `${i18n.t('wait_confirm')} | ${process.env.REACT_APP_NAME}`;
		};
		toTop();
	}, []);

	useEffect(() => {
		search();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [nick]);

	return (
		<>
			<div
				style={{
					padding: '50px',
					minWidth: '100%',
					height: '100%',
					background: 'url(/assets/images/space-bg.jpg) no-repeat center',
					backgroundSize: 'cover',
				}}>
				<div className='row'>
					{sended ? (
						<div className=' alert bg-primary p-5 rounded-lg col-md-6 ms-md-5 text-center'>
							<p className='h1 text-light fw-bold'>QUASE LÁ!</p>
							<p className='h3 text-light fw-bold'>Foi reenviado um email para {email},</p>
							<br />
							<p className='h3 text-light fw-bold'>agora só falta confirmar.</p>
							<br />
							<p className='h3 text-light fw-bold'>Clique no link enviado para o email {email}!</p>
						</div>
					) : (
						<div className=' alert bg-danger p-5 rounded-lg col-md-6 ms-md-5 text-center'>
							<p className='h1 text-light fw-bold'>NÃO DEU CERTO!</p>
							<p className='h3 text-light fw-bold'>Não Foi enviado um email para {nick},</p>
							<p className='h3 text-light fw-bold'>provavelmente ele não foi cadastrado.</p>
						</div>
					)}
					<div className='col-md-3 m-auto d-none d-md-block'>
						<img src='/logos/logo.png' alt='logo' className='img-fluid' />
					</div>
				</div>
			</div>
			<Loader show={loader} onHide={() => setLoader(false)} />
		</>
	);
};

export default WaitReConfirm;
