/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import SearchSymbol from '../../components/SearchSymbol/SearchSymbol';
import NewOrderTemplateButton from './NewOrderTemplateButton';
import OrderTemplateRow from './OrderTemplateRow';
import { getOrderTemplates, deleteOrderTemplate } from '../../services/OrderTemplatesService';
import Toast from '../../components/Toast/Toast';

import { i18n } from '../../utils/translates/i18n';
import Pagination from '../../utils/pagination/Pagination';
import { SettingPage } from '../../utils/pagination/Settings';
import { CheckPrivateAdmin } from '../../utils/CheckPrivateAdmin';
import LoaderPage from '../../utils/LoaderPage';

function OrderTemplates() {
	const navigate = useNavigate();
	const [show, setShow] = useState(false);
	const { symbol } = useParams();
	const [refresh, setRefresh] = useState(0);
	const [orderTemplates, setOrderTemplates] = useState([]);
	const [notification, setNotification] = useState([]);

	// PARA PAGINAÇÃO
	const token = sessionStorage.getItem('token');
	const [search, setSearch] = useState(symbol ? symbol : '');
	const [count, setCount] = useState(0);
	const [page, setPage] = useState(1);
	const [lim, setLims] = useState(10);
	SettingPage({ setting: setPage });

	const loading = async () => {
		getOrderTemplates(search, page || 1, showFutures())
			.then(result => {
				setOrderTemplates(result.rows ? result.rows : []);
				setCount(result.count);
			})
			.catch(err => {
				console.error(err.response ? err.response.data : err.message);
				setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
			});
	};

	const doSearch = () => {
		setPage(1);
		loading();
	};

	useEffect(() => {
		loading();
	}, [token, page, lim, refresh]);
	// FIM PARA PAGINAÇÃO

	function onDeleteClick(event) {
		const id = event.target.id.replace('delete', '');
		deleteOrderTemplate(id)
			.then(template => {
				loading();
			})
			.catch(err => {
				console.error(err.response ? err.response.data : err.message);
				setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
			});
	}

	function showFutures() {
		return window.location.href.indexOf('fOrderTemplates') !== -1;
	}

	function toggleMarket() {
		if (showFutures()) navigate('/traderbot/orderTemplates/');
		else navigate('/traderbot/fOrderTemplates/');
		setRefresh(Date.now());
	}

	useEffect(() => {
		const doCheck = async () => {
			document.title = `${i18n.t('order_template')} | ${process.env.REACT_APP_NAME}`;
			const token = sessionStorage.getItem('token');
			if (!token) navigate('/logout');
			const check = await CheckPrivateAdmin(token);
			if (!check || !check.isActive) navigate('/notAuthorized');
			if (!check.accessKey) navigate('/traderbot/settings');
			setShow(true);
		};
		doCheck();
	}, []);

	return !show ? (
		<LoaderPage />
	) : (
		<React.Fragment>
			<section className='section'>
				<div className='container-fluid'>
					<div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
						<div className='d-block mb-4 mb-md-0'>
							<h2 className='h4'>{showFutures() ? 'Futures ' : 'Spot '}Order Templates</h2>
						</div>
						{/* <div className='btn-toolbar mb-2 mb-md-0'>
							{sessionStorage.getItem('hasFutures') === 'true' ? (
								<div className='me-2 mb-3'>
									<button className='btn btn-secondary me-2' onClick={toggleMarket}>
										{showFutures() ? 'Futures' : 'Spot'}
									</button>
								</div>
							) : (
								<React.Fragment></React.Fragment>
							)}
							<div className='d-inline-flex align-items-center me-2 mb-3'>
								<NewOrderTemplateButton />
							</div>
							<div className='btn-group'>
								<SearchSymbol onBlur={doSearch} placeholder={search} />
							</div>
						</div> */}
					</div>
					<Pagination count={count} pageSize={lim}>
						<span>
							<svg className='icon icon-xs' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
								<path d='M3 4a1 1 0 011-1h12a1 1 0 011 1v2a1 1 0 01-1 1H4a1 1 0 01-1-1V4zM3 10a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H4a1 1 0 01-1-1v-6zM14 9a1 1 0 00-1 1v6a1 1 0 001 1h2a1 1 0 001-1v-6a1 1 0 00-1-1h-2z' />
							</svg>
						</span>
						<div className='input-group mx-3'>
							<input
								type='text'
								className='form-control'
								placeholder={`${i18n.t('search')} ${i18n.t('subject')} / ${i18n.t('description')}`}
								value={search}
								onChange={e => setSearch(e.target.value)}
								onBlur={doSearch}
							/>
							<button className='btn input-group-text btn-dark' onClick={doSearch}>
								<i className='fas fa-search'></i>
							</button>
						</div>
						<select className='form-select w-50' value={lim} onChange={e => setLims(e.target.value)}>
							<option className='bg-light' value='5'>
								5 {i18n.t('registers')}
							</option>
							<option className='bg-light' value='10'>
								10 {i18n.t('registers')}
							</option>
							<option className='bg-light' value='25'>
								25 {i18n.t('registers')}
							</option>
							<option className='bg-light' value='50'>
								50 {i18n.t('registers')}
							</option>
							<option className='bg-light' value='100'>
								100 {i18n.t('registers')}
							</option>
						</select>
					</Pagination>
					<div className='card card-body border-0 shadow table-wrapper table-responsive'>
						<table className='table table-hover'>
							<thead>
								<tr>
									<th className='border-gray-200'>Symbol</th>
									<th className='border-gray-200'>Name</th>
									<th className='border-gray-200'>Side</th>
									<th className='border-gray-200'>Type</th>
									<th className='border-gray-200'>Actions</th>
								</tr>
							</thead>
							<tbody>
								{orderTemplates && orderTemplates.length ? (
									orderTemplates.map(ot => <OrderTemplateRow key={ot.id} data={ot} onDeleteClick={onDeleteClick} />)
								) : (
									<React.Fragment></React.Fragment>
								)}
							</tbody>
						</table>
					</div>
				</div>
			</section>
			<Toast type={notification.type} text={notification.text} />
		</React.Fragment>
	);
}

export default OrderTemplates;
