const messages = {
	cn: {
		translations: {
			a_crase: '这',
			about: '关于我们',
			above_the_same: '上面完全一样',
			action: '行动',
			actionTemplates: '股票模型',
			activate_date: '激活日期',
			ad: '公告',
			add: '加入',
			add_option: '添加选项',
			add_payment: '添加付款',
			add_question: '添加问题',
			add_receipt: '添加收据',
			add_wallet: '添加钱包',
			address: '地址',
			admin: '行政',
			advance: '进步',
			affected: '到达',
			after: '后',
			air_transport: '航空运输',
			ajust_limite: '限制调整',
			ajust_saldo: '平衡调整',
			all: '全部',
			all_departments: '所有部门',
			allies_of: '的盟友',
			allRightsReserved: '版权所有。',
			almost_there: '差不多了',
			already_offset: '已经补偿',
			already_registered: '已经注册？ 单击此处并输入',
			amount: '价值',
			and: '这是',
			and_more: '和更多',
			answer: '回复',
			ao: '到',
			approve: '审批',
			April: '四月',
			asc: '方兴未艾',
			at: '在',
			August: '八月',
			automations: '自动化',
			awaiting_email_confirmation: '等待电子邮件确认！',
			b_order: '命令',
			b_orders: '命令',
			back: '回去',
			bairroInteresse: '邻里',
			balance: '平衡',
			balance_available: '可用余额',
			banned: '禁止',
			before_contact: '联系我们之前',
			binary: '二进制',
			birth: '出生',
			blind_man: '颠倒的',
			body_monthly: '您的月费已到期，您的BOT TRADER已被停用，请支付Money Order重新享受您的福利',
			bonus_saldo_trader: '交易者余额赠金',
			but_possible_register: '即使这样，也可以注册，但不会与任何推荐的朋友相关联。',
			buyer: '买方',
			by: '每',
			bye: '回头见',
			call: '称为',
			call_to: '拨电至',
			calls: '称为',
			cancel: '取消',
			canceled: '取消',
			career_plan: '职业路线',
			casa_verde_amarela: '黄绿色的房子',
			cash_payment: '现金支付',
			cash_payment_return: '现金支付返还',
			cash_receipt: '现金收据',
			change_indicant: '变化指标',
			change_photo: '更改照片',
			change_plan: '改变计划',
			check_payment: '支票付款',
			check_payment_return: '通过支票退回付款',
			check_receipt: '支票收据',
			choose: '选择',
			cidadeInteresse: '城市',
			city: '城市',
			claims: '理赔',
			clean: '清洁',
			click_and_know: '点击了解',
			click_to_copy_the_wallet: '点击复制投资组合',
			client: '客户',
			clients: '顾客',
			cnh: "驾驶执照（Driver's License）",
			cnhDate: '驾驶执照签发日期',
			cnpj: 'CNPJ',
			cnpj_invalid: '无效的 CNPJ',
			color: '颜色',
			commercial: '商业的',
			company: '公司名称',
			complement: '补充',
			comporRenda: '和某人一起去赚钱',
			compRenda: '收入证明类型',
			confirm_approve: '确认批准',
			confirm_delete: '确认删除',
			confirm_new_password: '确认新密码',
			confirm_new_password_security: '确认新的安全密码',
			confirm_password: '确认访问密码',
			confirm_password_security: '确认安全密码',
			confirmed: '确认的',
			confirmed_email: '确认邮件',
			congratulations: '恭喜',
			contact: '接触',
			contract: '合同',
			copied: '复制',
			copy: '复制',
			copy_success: '复制成功！',
			correspondences: '一致',
			countries: '国家',
			country: '国家',
			cpf: '公积金',
			cpf_invalid: '无效公积金',
			create_order_success: '订单创建成功！',
			created: '注册于',
			cred: '信用',
			cred_binario: '二元信用',
			cred_deb: '学分/借记',
			cred_game_donate: '游戏捐赠积分',
			cred_ind_direta: '间接推荐信用',
			cred_ind_indireta: '直接推荐信用',
			cred_mandala: '曼陀罗信用',
			cred_pagar_com_saldo: '信用卡余额支付',
			cred_pontos: '学分',
			cred_recb_trader: '信用接收交易员',
			cred_renovacao: '更新信用',
			cred_saldo_disp: '贷方可用余额',
			cred_upgrade: '升级信用',
			credit_card_payment: '信用卡支付',
			credit_card_payment_return: '信用卡还款返还',
			credit_card_receipt: '信用卡收据',
			current: '当前的',
			current_password: '当前密码',
			current_wallet: '当前投资组合',
			dashboard: '控制面板',
			data_added: '添加数据',
			data_saved: '保存数据',
			datas: '数据',
			date: '日期',
			date_first_installment: '首次付款日期',
			deb_de_trader: '交易员债务',
			deb_game_donate: '游戏捐赠借记',
			deb_saldo: '借方余额',
			deb_saldo_disp: '借方可用余额',
			deb_val_indev: '借记金额不当',
			debit_card_payment: '借记卡支付',
			debit_card_payment_return: '借记卡付款返还',
			debit_card_receipt: '借记卡收据',
			debt: '借方',
			debt_td: '债务',
			December: '十二月',
			decImpRenda: '申报所得税',
			deleted_document: '文档已从您的注册表中删除',
			department: '部门',
			dependents: '家属',
			desc: '向下',
			description: '描述',
			details: '细节',
			dev_econom: '经济发展',
			developer: '开发商',
			directly_indicated: '直接指示',
			directly_indicateds: '直接任命',
			directs: '直接的',
			district: '邻里/区',
			divorced: '离婚',
			doa_men_receb: '每月收到平台捐款',
			doacao_td: '捐款',
			doctorate_complete: '博士学位 - 完成',
			doctorate_incomplete: '博士学位 - 未完成',
			document: '文档',
			document_already_registered: '文件已经注册',
			documents: '文件',
			donate: '捐',
			donor: '捐赠者',
			dont_know_zip: '不知道邮政编码？ 点击这里',
			dou_fe: '本人确认以上信息正确无误。',
			downloads: '下载文件',
			drop_images: '单击或将图像拖到此处',
			due_date: '到期',
			edit: '编辑',
			edit_option: '编辑选项',
			edit_question: '编辑问题',
			email: '电子邮件',
			email_already_registered: '邮箱已经注册',
			email_and_or_password_wrong: '错误的电子邮件和/或密码！',
			email_for_login: '此电子邮件将是您的登录信息',
			email_or_password_wrong: '错误的邮箱帐号或密码',
			employee: '员工',
			employees: '雇员',
			empty: '空的',
			entrada: '有入门价值吗？ 如果是，什么值',
			entries_until_today: '迄今为止的条目',
			error_confirm_password: '确认密码和新密码必须相同',
			error_email: '电子邮件格式无效',
			error_password: '密码错误',
			error_phone: '无效的电话号码',
			estadoInteresse: '状态',
			exchange: '替代品',
			expired: '已到期',
			extorno_td: '返回',
			extra: '额外的',
			extract: '提炼',
			extracts: '提取物',
			fantasy: '幻想',
			fatherName: '父亲的名字',
			February: '二月',
			fee: '取款费',
			feedback: '返回',
			feminine: '女人味',
			fgts: 'FGTS 的价值',
			field_required: '必填项',
			final: '最终的',
			finance: '金融的',
			financial: '金融的',
			financial_payment: '财务支付',
			financial_payment_return: '财务支付返还',
			financial_receipt: '财务收据',
			financing: '融资',
			financings: '融资',
			first_10_pages: '前 10 页',
			fisica: '自然人',
			for_year: '每年',
			forget_password: '我忘记了密码',
			forgot_password: '忘记密码',
			form: '形式',
			form_of_payment: '付款方式',
			found: '成立',
			founds: '成立',
			friend_indicated: '我朋友推荐的',
			friend_indicateds: '我朋友推荐的',
			fuel: '燃料',
			full_name: '全名',
			funcPublico: '你是公务员吗？',
			fundamental_complete: '初级 - 完成',
			fundamental_incomplete: '初级 - 不完整',
			ganh_diario: '每日收入',
			gender: '性别',
			grand_total: '累计',
			group: '团体',
			has_withdraw_today: '今天已经要求提款',
			home: '开始',
			how_it_works: '怎么运行的',
			how_to_call: '你想叫什么？',
			id_td: '直接指示',
			images: '图片',
			in_construction: '建设中',
			in_order: '为了',
			in_stock: '有存货',
			indicant: '指标',
			indicated_by: '你被提名',
			indicated_not_found: '未找到指示的指标...',
			indicateds: '表明的',
			indirects: '间接',
			info: '时事通讯',
			info_contact: '联系信息',
			initial: '最初的',
			insert_new: '插入新的',
			installments: '分期付款',
			intelligence: '智力',
			interesse: '什么是感兴趣区域',
			invalid_contact: '联系方式无效',
			invalid_images: '无效图片',
			invalid_token: '令牌无效',
			invite: '邀请',
			invite_friends: '邀请你的朋友',
			January: '一月',
			July: '七月',
			June: '六月',
			juridica: '法人',
			know_exact_result: '你知道确切的价值吗？',
			lead: '我们将共同建设一个新的未来。',
			learn_more: '了解更多',
			left: '左边',
			left_preferential: '偏爱左边',
			legalNature: '法律性质',
			level: '等级',
			limite_excedido: '超出借记限额',
			linear: '线性的',
			link_email: '单击发送到注册电子邮件的链接！',
			link_invite_copy: '分享链接复制成功',
			liquid: '液体',
			list: '列表',
			loading: '加载中',
			location: '地点',
			login: '进入',
			logout: '出去',
			male: '男性',
			manage_users: '管理用户',
			manager: '经理',
			mandala: '业务组',
			mandalas: '业务组',
			manufacturer: '制造商',
			March: '行进',
			maritalStatus: '婚姻状况',
			market: '市场',
			married: '已婚',
			master_complete: '硕士 - 完成',
			master_incomplete: '硕士 - 未完成',
			May: '可能',
			message: '信息',
			messageNotSent: '无法发送您的消息',
			messageSent: '您的留言已成功发送',
			middle_complete: '中 - 完整',
			middle_incomplete: '中 - 不完整',
			min_amount_error: '该值必须至少为：',
			min_withdraw: '最低提款金额',
			missing: '丢失的',
			mission: '使命',
			mission_vision_values: '使命、愿景和价值观',
			model: '模型',
			monitors: '监视器',
			monthly: '每月支付',
			motherName: '母亲的名字',
			movements: '动作',
			msg_renovacao: '您已达到您的收入限额，更新您的套餐。',
			my_account: '我的账户',
			my_wallet: '我的钱包',
			mystock: '我的库存',
			name: '姓名',
			nascMaisVelhoRenda2: '最大的生日是几号？',
			nationality: '国籍（出生国）',
			naturalness: '出生地（您出生的城市）',
			nature: '自然',
			network: '网络',
			new: '新的',
			new_call: '新使命',
			new_financing: '新融资',
			new_order: '新命令',
			new_password: '新密码',
			new_password_security: '新的安全密码',
			new_seller: '新卖家',
			new_wallet: '新投资组合',
			news: '消息',
			next: '下一个',
			nick: '用户',
			nis: '个人信息系统/国家信息系统',
			nis_already_registered: 'PIS/NIS 已经注册',
			no: '不',
			no_approve: '还是不赞成',
			no_delete: '不排除',
			no_literate: '不识字',
			no_repeat: '数据不能重复',
			no_results_found: '未找到结果',
			no_services_or_products_added: '没有添加服务或产品',
			no_settings: '转到设置并填写您的详细信息。',
			none_friends_indicated: '我还没有介绍任何朋友',
			not_authorized: '未经授权',
			not_found: '未找到',
			not_login: '无法进入，查资料再试！',
			not_orders: '没有汇票',
			not_permission: '您无权访问此页面。',
			not_registered: '还没有注册？ 点击此处注册',
			not_results: '没有找到记录',
			not_self_register: '无法注册',
			not_today_entry: '今天还没买票',
			notfound_ad: '没有找到广告',
			November: '十一月',
			number: '数字',
			obs: '评论',
			October: '十月',
			of: '在',
			off: '歼灭',
			on_total: '总数的',
			open: '打开',
			opening: '开幕式',
			operation: '表现',
			ordenation: '按立',
			order: '命令',
			order_not_found: '找不到订单',
			orders: '要求',
			other: '其他',
			other_services_or_products: '其他服务或产品',
			paid: '支付',
			paid_date: '发薪日',
			partial: '部分支付',
			passport: '护照',
			password: '密码',
			password_security: '安全密码',
			pay: '支付',
			pay_generate: '生成付款',
			pay_selected: '支付选择',
			pay_value_in: '支付金额',
			payable: '支付',
			payer: '付款人',
			payment: '支付',
			ped_retirada: '提款请求',
			ped_retirada_gan_diario: '要求提取每日收入',
			pendent: '待办的',
			people: '人们',
			person: '人',
			person_found_in_other_prefecture: '已经在另一个城市登记的人',
			personal: '伙计们',
			personal_addresses: '地址',
			personal_contacts: '联系人',
			personal_data: '个人资料',
			personal_infos: '信息',
			personal_pcva: '绿色和黄色的房子计划',
			phone: '电话',
			pix: 'PIX',
			pix_payment: 'PIX支付',
			pix_payment_return: 'PIX 中的付款返回',
			pix_receipt: 'PIX收据',
			pl_carreira: '职业路线',
			plan: '平坦的',
			plates: '盘子',
			please_wait: '请稍等',
			plus: '加',
			point: '观点',
			points: '积分',
			portfolio: '文件夹',
			pos_complete: 'Lato sensu - 完整',
			pos_incomplete: 'Lato sensu - 不完整',
			prefecture: '市政府',
			prefecture_addresses: '地址',
			prefecture_contacts: '联系人',
			prefecture_data: '市政厅数据',
			prefecture_infos: '信息',
			prefectures: '市政厅',
			prev: '以前的',
			price: '价格',
			print: '打印出',
			privacy_policies: '隐私政策',
			private_works: '私人作品',
			profession: '职业',
			profile: '轮廓',
			program: '程序',
			programs: '软件',
			project: '项目',
			projects: '项目',
			public_works: '公共工程',
			purchase: '购买',
			qualify_binary: '二进制资格',
			qualquer: '任何',
			quantity: '数量',
			quotation: '价格',
			read: '读书',
			receivable: '受到',
			recipient: '接收者',
			recommendations: '建议',
			redefine_password: '重新定义密码',
			register: '记录',
			register_people: '人员登记',
			register_wallet: '注册 BTC 钱包进行提款',
			registers: '记录',
			registers_users: '用户注册',
			reject: '拒绝',
			remaining: '其余的',
			remove: '去除',
			rendaIndividual: '个人总收入',
			renew: '更新',
			renew_mandala: '曼陀罗更新',
			reply: '回复',
			reports: '报告',
			required_data_not_sent: '未发送所需数据',
			resend_email: '重新发送邮件',
			residential: '住宅',
			responded: '已回答',
			restricao: '你的名字有限制吗？ (SPC/Serasa)',
			result: '结果',
			results: '结果',
			resume_data: '数据汇总',
			revoked: '撤销',
			rg: '一般注册（身份）',
			rgAgency: 'RG发行人',
			rgDate: 'RG 发行日期',
			rgUf: '身份证状态',
			right: '正确的',
			right_preferential: '偏爱右边',
			roi: '每日收入',
			sale: '销售',
			saq_duplicado: '重复提款',
			save: '保存',
			schooling: '教育',
			search: '搜索',
			secretary: '秘书',
			security_password: '安全密码',
			see: '查看',
			see_advents: '看广告',
			see_call: '看电话',
			see_details: '查看具体信息',
			see_details_wallet: '查看投资组合详情',
			see_financing: '查看资金',
			see_order: '查看订单',
			see_user: '查看用户',
			select_vehicle: '选择车辆',
			self_register: '登记',
			seller: '卖方',
			send_call: '发送工单',
			send_to: '发给',
			September: '九月',
			settings: '设置',
			share_your_link: '分享您的链接',
			show: '出现在',
			show_from: '显示自',
			side: '边',
			sign_in_to_start_your_session: '登录以开始您的会话',
			sign_up: '登记',
			single: '单身的',
			sold: '卖',
			state: '状态',
			status: '地位',
			strategies: '攻略',
			street: '路',
			subject: '主题',
			submit: '发送',
			submit_placeholder: '请填写您的邮箱',
			subscribe: '注册接收我们的新闻',
			subtitle: '标题',
			success_update_user: '用户更新成功！',
			success_wallet: '投资组合更新成功！',
			suggestions: '建议',
			superior_complete: '已毕业',
			superior_incomplete: '不完全高',
			supervisor: '导师',
			support: '支持',
			support_materials: '支持材料',
			suspended: '暂停',
			symbols: '符号',
			system: '系统',
			target: '目标',
			tempoCarteira: '记录在投资组合中存在多长时间',
			terms: '我接受使用条款',
			this_value_in: '这个值在',
			title: '标题',
			to: '直到',
			to_pay: '关闭',
			to_receive: '应收款',
			today_entry: '今天的条目',
			token_not_found_or_already_used: '未找到或已使用令牌',
			total: '全部的',
			total_entry: '参赛总人数',
			traderbot: '机器人交易员',
			transf_credito: '学分转移',
			transf_debito: '借方转账',
			transfer_payment: '转移支付',
			transfer_payment_return: '转移支付返还',
			transfer_receipt: '转账收据',
			transparency: '透明度',
			tx_adm_cred: '征税',
			tx_adm_deb: '征税',
			tx_retirada: '取款费',
			tx_retirada_gan_diario: '每日收益提取率',
			type: '类型',
			update: '更新',
			update_wallet: '更新投资组合',
			upgrade: '改进',
			used: '用过的',
			user: '用户',
			user_active: '活跃用户',
			user_already_exists: '用户已存在！',
			user_and_or_password_wrong: '错误的用户名和/或密码！',
			user_inactive: '非活跃用户',
			user_not_found: '找不到用户',
			user_not_logged: '用户未登录',
			user_or_password_wrong: '用户名或密码错误',
			users: '用户',
			users_list: '用户列表',
			valid_images: '有效图片',
			valorRenda2: '第二收入的价值是多少',
			value: '价值',
			value_donate_on: '捐赠价值',
			value_financed: '融资金额',
			value_installment: '分期付款价值',
			values: '价值观',
			vehicle: '车辆',
			vehicles: '汽车',
			view_order: '查看订单',
			vision: '想象',
			visitor: '游客',
			voucher: '代金券',
			wait_confirm: '等待确认',
			waiting: '等待',
			waiting_email: '注册已成功进行，现在剩下的就是确认电子邮件。',
			waiting_payment: '等待付款',
			wallet: '文件夹',
			wallet_generate: '生成投资组合',
			wallets: '钱包',
			want_donate: '我要捐款',
			want_now_more_about: '我想了解更多',
			warning: '注意力',
			we_are: '是',
			webhooks: '网络钩子',
			welcome: '欢迎',
			who_we_are: '我们是谁',
			who_we_are_subtitle: '关于的简要说明',
			widower: '鳏夫',
			will_expire_in: '将在',
			with: '和',
			withdraw: '退出',
			withdraw_sended_email: '提款请求，检查您的电子邮件并单击链接以授权！',
			withdrawal: '取款',
			withdrawals: '取款',
			year: '年',
			years: '年',
			yes: '是的',
			your_country: '你的国家',
			your_dashboard: '这是您的控制面板。',
			your_link: '你的链接',
			zipcode: '邮政编码',
			amount_above_balance: '平衡不足',
			symbol: '象征',
			close: '关闭',
			high: '阿尔托',
			low: '低的',
			order_book: '订单书',
			coin: '硬币',
			free: '书',
			locked: '阻止',
			you_have: '你有',
			buy_volume: '购买音量',
			sell_volume: '出售数量',
			estimate: '估计的',
			photo: '照片',
			telegram_chat_id: '电报聊天ID',
			pendent_order: '你有未决订单',
			active_automations: '主动自动化',
			active_monitors: '主动监视器',
			active_users: '活跃的用户',
			active_connections: '主动连接',
			ticker_health: '股票健康',
			book_health: '预订健康',
			enter_your_new_password: '输入新密码',
			confirm_your_new_password: '再次输入新密码',
			alerts: '警告',
			alert: '警报',
			how_create_binance_apikey: '如何在Binance时创建API键',
			text_hint_binance:
				'在Binance创建API键时插入IP访问限制:\n172.105.78.139\n然后检查选项：\n• ENABLE EUROPEAN OPTIONS\n• ENABLE SPOT & MARGIN TRADING',
		},
	},
};

export { messages };
