/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import validator from 'validator';
import Loader from '../utils/Loader';
import { i18n } from '../utils/translates/i18n';
import Translate from '../utils/translates/components/Translate';
import { doCheckIndicant, doGetCountries, doGetPlans, doRegister, doVerifyNick } from '../services/mmn/AuthService';
import { FormateFiatCurrency, OnlyNumber } from '../utils/CustomFormatations';

const Register = () => {
	const navigate = useNavigate();

	window.document.title = `${i18n.t('register')} | ${process.env.REACT_APP_NAME}`;

	const [loader, setLoader] = useState(false);

	const DEFAULTDATA = {
		plan: 2,
		country: 179,
		nick: '',
		name: '',
		email: '',
		password: '',
		confirmPassword: '',
		passwordSecurity: '',
		indicant: '',
		terms: false,
	};
	const [data, setData] = useState(DEFAULTDATA);
	const onInputChange = (field, value) => setData(prevState => ({ ...prevState, [field]: value }));

	const { nickIndicant } = useParams();

	const [countries, setCountries] = useState([]);
	const [plans, setPlans] = useState([]);
	const [indicant, setIndicant] = useState(null);
	const [alertIndicant, setAlertIndicant] = useState(null);
	const [alertError, setAlertError] = useState(false);

	const getCountries = async () => {
		const success = await doGetCountries();
		if (success.status) setCountries(success.countries);
	};

	const getPlans = async () => {
		const success = await doGetPlans();
		if (success.status) setPlans(success.plans);
	};

	const checkPassword = () => {
		if (data.confirmPassword !== data.password) {
			onInputChange('confirmPassword', '');
			document.getElementById('confirmPassword').classList.add('is-invalid');
		} else {
			document.getElementById('confirmPassword').classList.remove('is-invalid');
		}
	};

	/* const checkPasswordSecurity = () => {
		if (data.confirmPasswordSecurity !== data.passwordSecurity) {
			onInputChange('confirmPasswordSecurity', '');
			document.getElementById('confirmPasswordSecurity').classList.add('is-invalid');
		} else {
			document.getElementById('confirmPasswordSecurity').classList.remove('is-invalid');
		}
	}; */

	const verifyNick = async () => {
		const success = await doVerifyNick(data.nick);
		console.log(success);
		if (!success.status) {
			onInputChange('nick', '');
			document.getElementById('nick').classList.add('is-invalid');
		} else {
			document.getElementById('nick').classList.remove('is-invalid');
		}
	};

	const checkEmail = () => {
		if (!validator.isEmail(data.email)) document.getElementById('email').classList.add('is-invalid');
		else document.getElementById('email').classList.remove('is-invalid');
	};

	const checkIndicant = async nick => {
		document.title = `${i18n.t('sign_up')} | ${process.env.REACT_APP_NAME}`;
		setLoader(true);
		if (!nick) {
			setIndicant(null);
			setLoader(false);
		} else {
			const success = await doCheckIndicant(nick);
			if (success.status) {
				setIndicant(success.indicant);
				onInputChange('indicant', success.indicant.id);
				setLoader(false);
			} else {
				setIndicant(null);
				onInputChange('indicant', '');
				if (nick !== 'register') setAlertIndicant(i18n.t('indicated_not_found'));
				setLoader(false);
			}
		}
	};

	const sendRegister = async () => {
		setLoader(true);
		const success = await doRegister(data);
		if (!success.status) {
			setAlertError(true);
			setLoader(false);
		}
		if (success.status) navigate('/waitconfirm');
	};

	useEffect(() => {
		checkIndicant(nickIndicant);
		getCountries();
		getPlans();
	}, [nickIndicant]);

	return (
		<>
			{/* <pre style={{ position: 'fixed' }}>{JSON.stringify(data, null, 4)}</pre> */}
			<div className='wrapper'>
				<div className='section-authentication-register d-flex align-items-center justify-content-center'>
					<div className='row'>
						<div className='mx-auto col-12 col-lg-10'>
							<Link to='/' className='d-flex justify-content-center bg-black'>
								<img src='/logos/logo500w.png' className='img-fluid' alt='' />
							</Link>
							<div className='card radius-15'>
								<div className='d-flex m-3 align-items-center justify-content-between'>
									<span className='h3 fw-bold'>{indicant ? i18n.t('registers_users') : i18n.t('indicant')}</span>
									<Translate />
								</div>
								{indicant ? (
									<>
										<div className='alert alert-warning mx-0 mx-md-5 py-1 d-flex justify-content-between align-items-center'>
											{i18n.t('indicated_by')} <span className='fw-bold mx-3'>{indicant?.nick}</span>
											<button
												type='button'
												className='btn btn-warning btn-sm'
												onClick={e => {
													setIndicant(null);
													onInputChange('indicant', '');
												}}>
												{i18n.t('change_indicant')}
											</button>
										</div>
										<div className='row no-gutters'>
											<div className='col-lg-12'>
												<div className='card-body'>
													<div className='row'>
														<div className='form-group col-md-6'>
															<label>{i18n.t('plan')}</label>
															<select
																className='form-select'
																value={data.plan}
																onChange={e => onInputChange('plan', e.target.value)}>
																{plans?.map((p, i) => (
																	<option key={i} value={p.id} className='bg-light'>
																		{p.name} - {FormateFiatCurrency(p.price)}
																	</option>
																))}
															</select>
														</div>
														<div className='form-group col-md-6'>
															<label>{i18n.t('country')}</label>
															<select
																className='form-select'
																value={data.country}
																onChange={e => onInputChange('country', e.target.value)}>
																{countries?.map((c, i) => (
																	<option key={i} value={c.id} className='bg-light'>
																		{c.name}
																	</option>
																))}
															</select>
														</div>
													</div>
													<div className='form-group mt-3'>
														<label>{i18n.t('nick')}</label>
														<input
															className='form-control'
															id='nick'
															type='text'
															placeholder={i18n.t('nick')}
															value={data.nick}
															onChange={e => onInputChange('nick', e.target.value)}
															onBlur={verifyNick}
														/>
														<div className='invalid-feedback bg-danger p-1 mt-0'>{i18n.t('user_already_exists')}</div>
													</div>
													<div className='form-group mt-3'>
														<label>{i18n.t('name')}</label>
														<input
															className='form-control'
															id='name'
															type='text'
															placeholder={i18n.t('name')}
															value={data.name}
															onChange={e => onInputChange('name', e.target.value)}
														/>
														<div className='invalid-feedback bg-danger p-1 mt-0'>{i18n.t('field_required')}</div>
													</div>
													<div className='row'>
														<div className='col-md-6'>
															<div className='form-group mt-3'>
																<label>{i18n.t('email')}</label>
																<input
																	className='form-control'
																	type='email'
																	id='email'
																	value={data.email}
																	placeholder={i18n.t('email')}
																	onChange={e => onInputChange('email', e.target.value?.toLowerCase())}
																	onBlur={checkEmail}
																/>
																<div className='invalid-feedback bg-danger p-1 mt-0'>{i18n.t('error_email')}</div>
															</div>
														</div>
														<div className='col-md-6'>
															<div className='row'>
																<div className='col-md-3'>
																	<div className='form-group mt-3'>
																		<label>{i18n.t('ddi')}</label>
																		<input
																			className='form-control'
																			type='text'
																			id='ddi'
																			maxLength={3}
																			value={data.ddi}
																			placeholder={i18n.t('ddi')}
																			onChange={e => onInputChange('ddi', OnlyNumber(e.target.value))}
																		/>
																	</div>
																</div>
																<div className='col-md-9'>
																	<div className='form-group mt-3'>
																		<label>{i18n.t('phone')}</label>
																		<input
																			className='form-control'
																			type='text'
																			id='phone'
																			value={data.phone}
																			placeholder={i18n.t('phone')}
																			onChange={e => onInputChange('phone', OnlyNumber(e.target.value))}
																		/>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className='row'>
														<div className='col-md-6'>
															<div className='form-group mt-3'>
																<label>{i18n.t('password')}</label>
																<input
																	className='form-control'
																	type='password'
																	value={data.password}
																	placeholder={i18n.t('password')}
																	onChange={e => onInputChange('password', e.target.value)}
																/>
															</div>
														</div>
														<div className='col-md-6'>
															<div className='form-group mt-3'>
																<label>{i18n.t('confirm_password')}</label>
																<input
																	className='form-control'
																	type='password'
																	id='confirmPassword'
																	value={data.confirmPassword}
																	placeholder={`${i18n.t('confirm_password')}`}
																	onChange={e => onInputChange('confirmPassword', e.target.value)}
																	onBlur={checkPassword}
																/>
																<div className='invalid-feedback bg-danger p-1 mt-0'>
																	{i18n.t('error_confirm_password')}
																</div>
															</div>
														</div>
													</div>
													{/* <div className='row'>
														<div className='col-md-6'>
															<div className='form-group mt-3'>
																<label>{i18n.t('password_security')}</label>
																<input
																	className='form-control'
																	type='password'
																	value={data.passwordSecurity}
																	placeholder={`${i18n.t('password_security')}`}
																	onChange={e => onInputChange('passwordSecurity', e.target.value)}
																/>
															</div>
														</div>
														<div className='col-md-6'>
															<div className='form-group mt-3'>
																<label>{i18n.t('confirm_password_security')}</label>
																<input
																	className='form-control'
																	type='password'
																	id='confirmPasswordSecurity'
																	value={data.confirmPasswordSecurity}
																	placeholder={`${i18n.t('confirm_password_security')}`}
																	onChange={e => onInputChange('confirmPasswordSecurity', e.target.value)}
																	onBlur={checkPasswordSecurity}
																/>
																<div className='invalid-feedback bg-danger p-1 mt-0'>
																	{i18n.t('error_confirm_password')}
																</div>
															</div>
														</div>
														<small>Senha de segurança será utilizada para realização de saques</small>
													</div> */}
													<div className='form-group'>
														<div className='form-group mt-3'>
															<div className='form-check'>
																<input
																	className='form-check-input'
																	type='checkbox'
																	checked={data.terms}
																	onChange={e => onInputChange('terms', e.target.checked)}
																/>
																<label className='form-check-label'>{i18n.t('terms')}</label>
															</div>
														</div>

														<button
															className='btn btn-primary btn-lg btn-block mt-3'
															disabled={
																!data.nick ||
																!data.name ||
																!data.email ||
																!data.confirmPassword ||
																!data.plan ||
																!data.country ||
																!data.indicant ||
																!data.terms
															}
															onClick={sendRegister}>
															{`${i18n.t('send_to')} ${i18n.t('register')}`}
														</button>
														{alertError && <div className='alert alert-danger h2'>{i18n.t('not_self_register')}</div>}
													</div>
												</div>
											</div>
										</div>
									</>
								) : (
									<>
										{alertIndicant && <div className='alert alert-danger mx-3'>{alertIndicant}</div>}
										<div className='input-group px-3'>
											<input
												className='form-control form-control-lg radius-30'
												type='text'
												placeholder={i18n.t('indicant')}
												value={data.indicant}
												autoComplete='off'
												onChange={e => onInputChange('indicant', e.target.value)}
											/>
											<button
												className='btn btn-primary btn-lg btn-block radius-30'
												onClick={e => checkIndicant(data.indicant)}>
												<i className='fas fa-search'></i> {i18n.t('search')}
											</button>
										</div>
									</>
								)}
								<div className='text-center'>
									<p className='my-3'>
										<Link to='/login' className='btn btn-sm'>
											{i18n.t('already_registered')}
										</Link>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Loader show={loader} onHide={() => setLoader(false)} />
		</>
	);
};

export default Register;
