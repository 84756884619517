/* eslint-disable jsx-a11y/role-supports-aria-props */
import React from 'react';
import MetisMenu from '@metismenu/react';
import { SidebarData } from './SidebarData';
import SubMenu from './SubMenu';

function SideBar() {
	const ToggleBtn = () => document.querySelector('.sidebar').classList.remove('active_sidebar');

	return (
		<>
			<nav className='sidebar dark_sidebar'>
				<div className='logo d-flex justify-content-between'>
					<a className='large_logo' href='/'>
						<img src='/logos/logo.png' alt='' className='img-fluid w-75' />
					</a>
					<a className='small_logo  text-center' href='/'>
						<img src='/logos/apple144.png' alt='' className='img-fluid w-25' />
					</a>
					<div className='sidebar_close_icon d-lg-none' onClick={ToggleBtn}>
						<i className='fas fa-times text-light'></i>
					</div>
				</div>

				<MetisMenu>
					<div id='sidebar_menu'>
						{SidebarData.map((item, index) => (
							<React.Fragment key={index}>
								<SubMenu item={item} ToggleBtn={ToggleBtn} />
							</React.Fragment>
						))}
					</div>
				</MetisMenu>
			</nav>
		</>
	);
}

export default SideBar;
