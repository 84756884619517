import React from 'react';
import { Modal } from 'react-bootstrap';

export default function Modals(props) {
	return (
		<Modal {...props} size={props.size} aria-labelledby='contained-modal-title-vcenter'>
			<Modal.Header closeButton>
				<Modal.Title id='contained-modal-title-vcenter'>{props.title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>{props.children}</Modal.Body>
		</Modal>
	);
}
