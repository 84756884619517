import React, { useState, useEffect } from 'react';
import { getSymbols } from '../../../services/SymbolsService';
import '../Dashboard.css';
import BookRow from './BookRow';
import SelectQuote, { filterSymbolNames, getDefaultQuote } from '../../../components/SelectQuote/SelectQuote';
import { i18n } from '../../../utils/translates/i18n';
/**
 * props:
 * - data: the market data
 */
function Book(props) {
	const [symbols, setSymbols] = useState([]);

	const [quote, setQuote] = useState(getDefaultQuote());

	function onQuoteChange(event) {
		setQuote(event.target.value);
	}

	useEffect(() => {
		getSymbols(false)
			.then(symbols => setSymbols(filterSymbolNames(symbols.rows, quote)))
			.catch(err => {
				console.error(err.response ? err.response.data : err.message);
			});
	}, [quote]);

	return (
		<React.Fragment>
			<div className='col-sm-12 col-md-6 mb-4'>
				<div className='card border-0 shadow'>
					<div className='card-header'>
						<div className='row'>
							<div className='col'>
								<h2 className='fs-5 fw-bold mb-0'>{i18n.t('order_book')}</h2>
							</div>
							<div className='col offset-md-3'>
								<SelectQuote onChange={onQuoteChange} value={quote} />
							</div>
						</div>
					</div>
					<div className='table-responsive divScroll'>
						<table className='table align-items-center table-flush table-sm table-hover tableFixHead'>
							<thead>
								<tr>
									<th className='bg-dark border-bottom col-2' scope='col'>
										{i18n.t('symbol')}
									</th>
									<th className='bg-dark border-bottom col-2' scope='col'>
										BID
									</th>
									<th className='bg-dark border-bottom col-2' scope='col'>
										ASK
									</th>
								</tr>
							</thead>
							<tbody>
								{symbols && symbols.length ? (
									symbols.map(item => <BookRow key={item} symbol={item} data={props.data[item]} />)
								) : (
									<React.Fragment></React.Fragment>
								)}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export default Book;
