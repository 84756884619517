import React, { useEffect } from 'react';
import { i18n } from '../utils/translates/i18n';

const WaitConfirm = () => {
	useEffect(() => {
		const toTop = () => {
			document.title = `${i18n.t('wait_confirm')} | ${process.env.REACT_APP_NAME}`;
		};
		toTop();
	}, []);

	return (
		<>
			<div
				style={{
					padding: '50px',
					minWidth: '100%',
					height: '100%',
					background: 'url(/assets/images/space-bg.jpg) no-repeat center',
					backgroundSize: 'cover',
				}}>
				<div className='row'>
					<div className=' alert bg-primary p-5 rounded-lg col-md-6 ms-md-5 text-center'>
						<p className='h1 fw-bold text-light'>{i18n.t('almost_there')}!</p>
						<p className='h3 fw-bold text-light'>{i18n.t('waiting_email')}</p>
						<p className='h3 fw-bold text-light'>{i18n.t('link_email')}</p>
					</div>
					<div className='col-md-3 m-auto d-none d-md-block'>
						<img src='/logos/logo.png' alt='logo' className='img-fluid' />
					</div>
				</div>
			</div>
		</>
	);
};

export default WaitConfirm;
