import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Translate from '../utils/translates/components/Translate';
import { getUserByTokenPass, redefinePassword } from '../services/mmn/AuthService';
import { i18n } from '../utils/translates/i18n';

const RedefinePassword = props => {
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [alertPassword, setAlertPassword] = useState(false);
	const [alertFail, setAlertFail] = useState(false);
	const [alertSuccess, setAlertSuccess] = useState(false);
	const { tokenPass } = useParams();
	const [user, setUser] = useState([]);
	const [displayForm, setDisplayForm] = useState(false);

	useEffect(() => {
		const toTop = () => {
			document.title = `${i18n.t('redefine_password')} | ${process.env.REACT_APP_NAME}`;
		};
		toTop();
	}, []);

	useEffect(() => {
		const getUser = async () => {
			if (tokenPass) {
				const success = await getUserByTokenPass(tokenPass);
				if (success.status) {
					setUser({ nick: success.nick });
					setDisplayForm(true);
				} else {
					setDisplayForm(false);
				}
			}
		};
		getUser();
	}, [tokenPass]);

	const checkPassword = () => {
		if (password !== confirmPassword) {
			setAlertPassword(true);
			setConfirmPassword('');
			document.querySelector('#borderConfirmPassword').style = 'border: solid 3px red';
		} else {
			setAlertPassword(false);
			document.querySelector('#borderConfirmPassword').style = 'border: transparent';
		}
	};

	const doRedefine = async () => {
		const success = await redefinePassword({ token: tokenPass, password: confirmPassword });
		if (success.status) {
			setAlertFail(false);
			setAlertSuccess(true);
		} else {
			setAlertFail(true);
			setAlertSuccess(false);
		}
	};

	return (
		<>
			<div className='mx-auto col-md-4 mb-3'>
				<div className='card card-dark bg-dark mt-md-5'>
					<Link to='/' className='mx-auto'>
						<img src='/logos/logo.png' alt='logo' className='img-fluid mt-3' style={{ width: '200px' }} />
					</Link>
					<div className='card-body'>
						<div className='d-flex mx-2 align-items-center justify-content-between mb-3'>
							{user.nick && (
								<p className='h5 fw-bold'>
									Redefina a Senha para <strong>{user.nick?.toUpperCase()}</strong>
								</p>
							)}
							<Translate />
						</div>
						{displayForm ? (
							alertSuccess ? (
								<div className='alert alert-success mt-3'>
									<h4 className='text-success'>
										<strong>Senha redefinida com sucesso!</strong>
									</h4>
									<Link to='/login' className='btn btn-success text-light btn-block'>
										<strong>Clique aqui e faça o Login</strong>
									</Link>
								</div>
							) : (
								<>
									<div className='input-group mb-3'>
										<div className='input-group-text'>{i18n.t('password')}</div>
										<input
											type='password'
											className='form-control'
											placeholder='*Password'
											value={password}
											autoComplete='off'
											onChange={e => setPassword(e.target.value)}
										/>
									</div>
									<div className='input-group mb-3'>
										<div className='input-group-text'>{i18n.t('confirm_password')}</div>
										<input
											type='password'
											className='form-control'
											placeholder='*Confirm Password'
											id='borderConfirmPassword'
											value={confirmPassword}
											autoComplete='off'
											onChange={e => setConfirmPassword(e.target.value)}
											onBlur={() => checkPassword()}
										/>
									</div>
									{alertPassword && (
										<span className='alertfail'>A Senha e a confirmação de senha devem ser iguais</span>
									)}
									<button
										className='btn btn-dark border-light'
										disabled={!password | !confirmPassword}
										id='botaocadastrar'
										onClick={doRedefine}>
										Cadastrar
									</button>
									{alertFail && <span className='alertfail'>Não foi possível cadastrar!</span>}
								</>
							)
						) : (
							<div className='alert alert-danger text-center'>
								<h1>
									<i className='fas fa-exclamation-triangle text-danger'></i>
								</h1>
								<h3 className='text-danger'>
									<strong>Esse Link é inválido, Expirado ou já foi utilizado</strong>
								</h3>
								<h4 className='text-danger'>
									<strong>Por favor, solicite um novo link para alteração de senha e clique no email enviado.</strong>
								</h4>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default RedefinePassword;
