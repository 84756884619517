import axios from './BaseService';
const API_URL = process.env.REACT_APP_API_URL;

export const getUsers = async (token, page, limit, search) => {
	const endPoint = `${API_URL}/usersmmn/list${page ? `?page=${page}` : ''}`;
	const headers = {
		'Content-Type': 'application/json',
		Authorization: `${token}`,
	};
	const response = await axios.post(endPoint, { limit, search }, { headers });
	return response.data;
};

export const getUser = async (id, token) => {
	const endPoint = `${API_URL}/usersmmn/user/${id}`;
	const headers = {
		'Content-Type': 'application/json',
		Authorization: `${token}`,
	};
	const response = await axios.get(endPoint, { headers });
	return response.data;
};

export const getMyUserData = async token => {
	const endPoint = `${API_URL}/usersmmn/token`;
	const headers = {
		'Content-Type': 'application/json',
		Authorization: `${token}`,
	};
	const response = await axios.get(endPoint, { headers });
	return response.data;
};

export const getUserGroups = async token => {
	const endPoint = `${API_URL}/usersmmn/listGroups`;
	const headers = {
		'Content-Type': 'application/json',
		Authorization: `${token}`,
	};
	const response = await axios.get(endPoint, { headers });
	return response.data;
};

export const editUser = async (userId, data, token) => {
	const endPoint = `${API_URL}/usersmmn/user/${userId}`;
	const headers = {
		'Content-Type': 'application/json',
		Authorization: `${token}`,
	};
	const response = await axios.put(endPoint, data, { headers });
	return response.data;
};

export const changePlan = async (userId, data, token) => {
	const endPoint = `${API_URL}/usersmmn/changePlan/${userId}`;
	const headers = {
		'Content-Type': 'application/json',
		Authorization: `${token}`,
	};
	const response = await axios.put(endPoint, data, { headers });
	return response.data;
};

export const upgradePlan = async (userId, data, token) => {
	const endPoint = `${API_URL}/usersmmn/upgradePlan/${userId}`;
	const headers = {
		'Content-Type': 'application/json',
		Authorization: `${token}`,
	};
	const response = await axios.put(endPoint, data, { headers });
	return response.data;
};

export const editPhoto = async (token, data) => {
	const endPoint = `${API_URL}/usersmmn/photo`;
	const headers = {
		Authorization: `${token}`,
	};
	const response = await axios.post(endPoint, data, { headers });
	return response.data;
};
