/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import NewOrderButton from '../../components/NewOrder/NewOrderButton';
import NewOrderModal from '../../components/NewOrder/NewOrderModal';
import OrderRow from './OrderRow';
import { getOrders } from '../../services/OrdersService';
import ViewOrderModal from './ViewOrderModal';
import Toast from '../../components/Toast/Toast';
import PositionsTable from '../Futures/PositionsTable';

import { i18n } from '../../utils/translates/i18n';
import Pagination from '../../utils/pagination/Pagination';
import { SettingPage } from '../../utils/pagination/Settings';
import { CheckPrivateAdmin } from '../../utils/CheckPrivateAdmin';
import LoaderPage from '../../utils/LoaderPage';

function Orders() {
	const navigate = useNavigate();
	const [show, setShow] = useState(false);
	const { symbol } = useParams();
	const [orders, setOrders] = useState([]);
	const [notification, setNotification] = useState([]);
	const [viewOrder, setViewOrder] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [refresh, setRefresh] = useState(0);

	// PARA PAGINAÇÃO
	const token = sessionStorage.getItem('token');
	const [search, setSearch] = useState(symbol ? symbol : '');
	const [count, setCount] = useState(0);
	const [page, setPage] = useState(1);
	const [lim, setLims] = useState(10);
	SettingPage({ setting: setPage });

	const loading = async () => {
		setIsLoading(true);
		getOrders(search, page || 1, lim, showFutures())
			.then(result => {
				setOrders(result.rows);
				setCount(result.count);
				setViewOrder(result.rows[0]);
				setIsLoading(false);
			})
			.catch(err => {
				console.error(err.response ? err.response.data : err.message);
				setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
				setIsLoading(false);
			});
	};

	const doSearch = () => {
		setPage(1);
		loading();
	};

	useEffect(() => {
		loading();
	}, [token, page, lim, refresh]);
	// FIM PARA PAGINAÇÃO

	function showFutures() {
		return window.location.href.indexOf('forders') !== -1;
	}

	function onViewClick(event) {
		const id = event.target.id.replace('view', '');
		const order = orders.find(o => o.id == id);
		// eslint-disable-next-line
		setViewOrder({ ...order });
	}

	function onOrderSubmit(order) {
		setRefresh(Date.now());
	}

	function toggleMarket() {
		if (showFutures()) navigate('/traderbot/orders/');
		else navigate('/traderbot/forders/');
		setRefresh(Date.now());
	}

	useEffect(() => {
		const doCheck = async () => {
			document.title = `${i18n.t('b_orders')} | ${process.env.REACT_APP_NAME}`;
			const token = sessionStorage.getItem('token');
			if (!token) navigate('/logout');
			const check = await CheckPrivateAdmin(token);
			if (!check || !check.isActive) navigate('/notAuthorized');
			if (!check.accessKey) navigate('/traderbot/settings');
			setShow(true);
		};
		doCheck();
	}, []);

	return !show ? (
		<LoaderPage />
	) : (
		<React.Fragment>
			<section className='section'>
				<div className='container-fluid'>
					<div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
						<div className='d-block mb-4 mb-md-0'>
							<h2 className='h4'>{`${i18n.t('b_orders')} ${showFutures() ? 'Futures ' : 'Spot '}`}</h2>
						</div>
						<div className='btn-toolbar mb-2 mb-md-0'>
							{sessionStorage.getItem('hasFutures') === 'true' && (
								<div className='me-2 mb-3'>
									<button className='btn btn-secondary me-2' onClick={toggleMarket}>
										{showFutures() ? 'Futures' : 'Spot'}
									</button>
								</div>
							)}
						</div>
					</div>
					<Pagination count={count} pageSize={lim}>
						<div className='input-group me-3'>
							<span className='me-3'>
								<NewOrderButton />
							</span>
							<input
								type='text'
								className='form-control'
								placeholder={`${i18n.t('search')} ${i18n.t('symbol')}`}
								value={search}
								onChange={e => setSearch(e.target.value)}
								onBlur={doSearch}
							/>
							<button className='btn input-group-text btn-dark' onClick={doSearch}>
								<i className='fas fa-search'></i>
							</button>
						</div>
						<select className='form-select w-50' value={lim} onChange={e => setLims(e.target.value)}>
							<option className='bg-light' value='5'>
								5 {i18n.t('registers')}
							</option>
							<option className='bg-light' value='10'>
								10 {i18n.t('registers')}
							</option>
							<option className='bg-light' value='25'>
								25 {i18n.t('registers')}
							</option>
							<option className='bg-light' value='50'>
								50 {i18n.t('registers')}
							</option>
							<option className='bg-light' value='100'>
								100 {i18n.t('registers')}
							</option>
						</select>
					</Pagination>
					<div className='card card-body border-0 shadow table-wrapper table-responsive'>
						<table className='table table-hover'>
							<thead>
								<tr>
									<th className='border-gray-200'>{i18n.t('b_order')}</th>
									<th className='border-gray-200'>{i18n.t('date')}</th>
									<th className='border-gray-200'>{i18n.t('quantity')}</th>
									<th className='border-gray-200'>Net</th>
									<th className='border-gray-200'>AVG {i18n.t('price')}</th>
									<th className='border-gray-200'>{i18n.t('status')}</th>
									<th className='border-gray-200 text-center'>{i18n.t('view_order')}</th>
								</tr>
							</thead>
							<tbody>
								{!isLoading && orders && orders.length ? (
									orders.map(order => <OrderRow key={order.clientOrderId} data={order} onClick={onViewClick} />)
								) : (
									<tr>
										<td colSpan={6}>Loading...</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
					{showFutures() ? <PositionsTable /> : <React.Fragment></React.Fragment>}
				</div>
			</section>
			<ViewOrderModal data={viewOrder} onUpdate={onOrderSubmit} />
			<NewOrderModal onSubmit={onOrderSubmit} />
			<Toast type={notification.type} text={notification.text} />
		</React.Fragment>
	);
}

export default Orders;
