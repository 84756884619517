export const positionsCss = [
	{
		position: 1,
		css: {
			display: 'flex',
			width: '79px',
			height: '79px',
			justifyContent: 'center',
			alignItems: 'center',
			background: 'url(/img/mandala/level1.svg) no-repeat',
			margin: '147px',
			zIndex: 10,
		},
		bg: 'success',
	},
	{
		position: 2,
		css: {
			display: 'flex',
			width: '191px',
			height: '96px',
			justifyContent: 'center',
			paddingTop: '15px',
			background: 'url(/img/mandala/level2.svg) no-repeat',
			margin: '90px',
			transform: 'rotate(0deg)',
		},
		bg: 'info',
	},
	{
		position: 3,
		css: {
			display: 'flex',
			width: '191px',
			height: '96px',
			justifyContent: 'center',
			paddingTop: '41px',
			background: 'url(/img/mandala/level3.svg) no-repeat',
			marginTop: '184px',
			marginLeft: '90px',
			transform: 'rotate(0deg)',
		},
		bg: 'info',
	},
	{
		position: 4,
		css: {
			display: 'flex',
			width: '186px',
			height: '67px',
			justifyContent: 'center',
			alignItems: 'center',
			background: 'url(/img/mandala/level4.svg) no-repeat',
			marginTop: '84px',
			marginLeft: '25px',
			transform: 'rotate(-45deg)',
		},
		bg: 'warning',
	},
	{
		position: 5,
		css: {
			display: 'flex',
			width: '186px',
			height: '67px',
			justifyContent: 'center',
			alignItems: 'center',
			background: 'url(/img/mandala/level4.svg) no-repeat',
			marginTop: '84px',
			marginLeft: '161px',
			transform: 'rotate(45deg)',
		},
		bg: 'warning',
	},
	{
		position: 6,
		css: {
			display: 'flex',
			width: '186px',
			height: '67px',
			justifyContent: 'center',
			alignItems: 'center',
			background: 'url(/img/mandala/level5.svg) no-repeat',
			marginTop: '221px',
			marginLeft: '24px',
			transform: 'rotate(45deg)',
		},
		bg: 'warning',
	},
	{
		position: 7,
		css: {
			display: 'flex',
			width: '186px',
			height: '67px',
			justifyContent: 'center',
			alignItems: 'center',
			background: 'url(/img/mandala/level5.svg) no-repeat',
			marginTop: '221px',
			marginLeft: '163px',
			transform: 'rotate(-45deg)',
		},
		bg: 'warning',
	},
	{
		position: 8,
		css: {
			display: 'flex',
			width: '144px',
			height: '54px',
			justifyContent: 'center',
			alignItems: 'center',
			background: 'url(/img/mandala/level6.svg) no-repeat',
			marginTop: '25px',
			marginLeft: '169px',
			transform: 'rotate(22deg)',
		},
		bg: 'danger',
	},
	{
		position: 9,
		css: {
			display: 'flex',
			width: '144px',
			height: '54px',
			justifyContent: 'center',
			alignItems: 'center',
			background: 'url(/img/mandala/level6.svg) no-repeat',
			marginTop: '103px',
			marginLeft: '247px',
			transform: 'rotate(67deg)',
		},
		bg: 'danger',
	},
	{
		position: 10,
		css: {
			display: 'flex',
			width: '144px',
			height: '54px',
			justifyContent: 'center',
			alignItems: 'center',
			background: 'url(/img/mandala/level7.svg) no-repeat',
			marginTop: '214px',
			marginLeft: '248px',
			transform: 'rotate(-67deg)',
		},
		bg: 'danger',
	},
	{
		position: 11,
		css: {
			display: 'flex',
			width: '144px',
			height: '54px',
			justifyContent: 'center',
			alignItems: 'center',
			background: 'url(/img/mandala/level7.svg) no-repeat',
			marginTop: '292px',
			marginLeft: '169px',
			transform: 'rotate(-22deg)',
		},
		bg: 'danger',
	},
	{
		position: 12,
		css: {
			display: 'flex',
			width: '144px',
			height: '54px',
			justifyContent: 'center',
			alignItems: 'center',
			background: 'url(/img/mandala/level7.svg) no-repeat',
			marginTop: '291px',
			marginLeft: '58px',
			transform: 'rotate(23deg)',
		},
		bg: 'danger',
	},
	{
		position: 13,
		css: {
			display: 'flex',
			width: '144px',
			height: '54px',
			justifyContent: 'center',
			alignItems: 'center',
			background: 'url(/img/mandala/level7.svg) no-repeat',
			marginTop: '213px',
			marginLeft: '-20px',
			transform: 'rotate(67deg)',
		},
		bg: 'danger',
	},
	{
		position: 14,
		css: {
			display: 'flex',
			width: '144px',
			height: '54px',
			justifyContent: 'center',
			alignItems: 'center',
			background: 'url(/img/mandala/level6.svg) no-repeat',
			marginTop: '103px',
			marginLeft: '-19px',
			transform: 'rotate(-67deg)',
		},
		bg: 'danger',
	},
	{
		position: 15,
		css: {
			display: 'flex',
			width: '144px',
			height: '54px',
			justifyContent: 'center',
			alignItems: 'center',
			background: 'url(/img/mandala/level6.svg) no-repeat',
			marginTop: '26px',
			marginLeft: '58px',
			transform: 'rotate(-23deg)',
		},
		bg: 'danger',
	},
];
