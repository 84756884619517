const messages = {
	fr: {
		translations: {
			a_crase: 'Le',
			about: 'À propos de nous',
			above_the_same: 'Ci-dessus exactement le même',
			action: 'Action',
			actionTemplates: 'Modèles de stock',
			activate_date: "Date d'activation",
			ad: 'Annonce',
			add: 'Accession',
			add_option: 'ajouter une option',
			add_payment: 'Ajouter un paiement',
			add_question: 'Ajouter une question',
			add_receipt: 'Ajouter un reçu',
			add_wallet: 'Ajouter un portefeuille',
			address: 'Adresse',
			admin: 'Administration',
			advance: 'Avance',
			affected: 'atteint',
			after: 'Après',
			air_transport: 'Transport aérien',
			ajust_limite: 'Ajustement de la limite',
			ajust_saldo: 'Ajustement de la balance',
			all: 'Tous',
			all_departments: 'Tous les départements',
			allies_of: 'alliés de',
			allRightsReserved: 'Tous les droits sont réservés.',
			almost_there: 'Presque là',
			already_offset: 'déjà compensé',
			already_registered: 'Déjà enregistré? Cliquez ici et entrez',
			amount: 'Valeur',
			and: "C'est",
			and_more: 'et plus',
			answer: 'Réponse',
			ao: 'au',
			approve: 'Approuver',
			April: 'Avril',
			asc: 'Ascendant',
			at: 'dans',
			August: 'Août',
			automations: 'Automatisations',
			awaiting_email_confirmation: 'En attente de confirmation par e-mail !',
			b_order: 'Commande',
			b_orders: 'ordres',
			back: 'Retourner',
			bairroInteresse: 'Quartier',
			balance: 'Équilibre',
			balance_available: 'Solde disponible',
			banned: 'banni',
			before_contact: 'Avant de nous contacter',
			binary: 'Binaire',
			birth: 'Naissance',
			blind_man: 'renversé',
			body_monthly:
				'Vos frais mensuels ont expiré et votre BOT TRADER a été désactivé, veuillez payer le mandat postal et profiter à nouveau de vos avantages',
			bonus_saldo_trader: 'Bonus de solde du commerçant',
			but_possible_register: "Même ainsi, il est possible de s'inscrire, mais cela ne sera lié à aucun ami parrain.",
			buyer: 'Acheteur',
			by: 'par',
			bye: 'À plus tard',
			call: 'Appelé',
			call_to: 'Appeler pour',
			calls: 'appelé',
			cancel: 'Annuler',
			canceled: 'Annulé',
			career_plan: 'Cheminement de carrière',
			casa_verde_amarela: 'maison verte jaune',
			cash_payment: 'Paiement en espèces',
			cash_payment_return: 'Retour de paiement en espèces',
			cash_receipt: 'ticket de caisse',
			change_indicant: 'Indicateur de changement',
			change_photo: 'Changer la photo',
			change_plan: 'changer de plan',
			check_payment: 'Paiement en chèque',
			check_payment_return: 'Retour de paiement par chèque',
			check_receipt: 'encaissement par chèque',
			choose: 'Choisir',
			cidadeInteresse: 'Ville',
			city: 'Ville',
			claims: 'Réclamations',
			clean: 'Nettoyer',
			click_and_know: 'Cliquez et sachez',
			click_to_copy_the_wallet: 'Cliquez pour copier le portfolio',
			client: 'Client',
			clients: 'Clients',
			cnh: 'Permis de conduire (permis de conduire)',
			cnhDate: 'Date de délivrance du permis de conduire',
			cnpj: 'CNPJ',
			cnpj_invalid: 'CNPJ invalide',
			color: 'Couleur',
			commercial: 'Commercial',
			company: "Nom de l'entreprise",
			complement: 'Complément',
			comporRenda: "Allez composer des revenus avec quelqu'un",
			compRenda: 'Type de preuve de revenu',
			confirm_approve: "confirmer l'approbation",
			confirm_delete: 'confirmer la suppression',
			confirm_new_password: 'Confirmer le nouveau mot de passe',
			confirm_new_password_security: 'Confirmer le nouveau mot de passe de sécurité',
			confirm_password: "Confirmer le mot de passe d'accès",
			confirm_password_security: 'Confirmer le mot de passe de sécurité',
			confirmed: 'Confirmé',
			confirmed_email: 'e-mail confirmé',
			congratulations: 'Toutes nos félicitations',
			contact: 'Contact',
			contract: 'Contracter',
			copied: 'copié',
			copy: 'Copie',
			copy_success: 'Copié avec succès !',
			correspondences: 'Correspondance',
			countries: 'des pays',
			country: 'Pays',
			cpf: 'PCF',
			cpf_invalid: 'CPF invalide',
			create_order_success: 'Commande créée avec succès !',
			created: 'Enregistré',
			cred: 'Crédit',
			cred_binario: 'Crédit binaire',
			cred_deb: 'Crédits/Débits',
			cred_game_donate: 'Faire un don de crédit',
			cred_ind_direta: 'Crédit de parrainage indirect',
			cred_ind_indireta: 'Crédit de parrainage direct',
			cred_mandala: 'Crédit Mandala',
			cred_pagar_com_saldo: 'Paiement à crédit avec solde',
			cred_pontos: 'Points de crédit',
			cred_recb_trader: 'Crédit Recevoir Trader',
			cred_renovacao: 'Crédit de renouvellement',
			cred_saldo_disp: 'Solde disponible du crédit',
			cred_upgrade: 'Crédit de surclassement',
			credit_card_payment: 'Paiement par carte de crédit',
			credit_card_payment_return: 'Retour de paiement par carte de crédit',
			credit_card_receipt: 'Reçu de carte de crédit',
			current: 'Actuel',
			current_password: 'Mot de passe actuel',
			current_wallet: 'Portefeuille actuel',
			dashboard: 'Panneau de commande',
			data_added: 'Données ajoutées',
			data_saved: 'Données enregistrées',
			datas: 'Données',
			date: 'Date',
			date_first_installment: 'Date du premier paiement',
			deb_de_trader: 'Dette du commerçant',
			deb_game_donate: 'Jeu Faire un don Débit',
			deb_saldo: 'Solde débiteur',
			deb_saldo_disp: 'Débiter le solde disponible',
			deb_val_indev: 'Débiter un montant incorrect',
			debit_card_payment: 'Paiement par carte de débit',
			debit_card_payment_return: 'Retour de paiement par carte de débit',
			debit_card_receipt: 'Reçu de carte de débit',
			debt: 'Débit',
			debt_td: 'dette',
			December: 'Décembre',
			decImpRenda: "déclarer l'impôt sur le revenu",
			deleted_document: 'Le document a été supprimé de votre registre',
			department: 'Département',
			dependents: 'Personnes à charge',
			desc: 'Vers le bas',
			description: 'Description',
			details: 'Détails',
			dev_econom: 'Développement économique',
			developer: 'Développeur',
			directly_indicated: 'directement indiqué',
			directly_indicateds: 'Directement nommé',
			directs: 'direct',
			district: 'Quartier / quartier',
			divorced: 'Divorcé',
			doa_men_receb: 'Don mensuel de la plateforme reçu',
			doacao_td: 'Don',
			doctorate_complete: 'Doctorat - Terminé',
			doctorate_incomplete: 'Doctorat - Incomplet',
			document: 'Document',
			document_already_registered: 'Document déjà enregistré',
			documents: 'Documents',
			donate: 'Donner',
			donor: 'Donneur',
			dont_know_zip: 'Vous ne connaissez pas le code postal ? Cliquez ici',
			dou_fe: 'Je confirme que les informations ci-dessus sont correctes.',
			downloads: 'Telecharger des fichiers',
			drop_images: 'Cliquez ou faites glisser les images ici',
			due_date: 'Maturité',
			edit: 'Éditer',
			edit_option: 'option de modification',
			edit_question: 'modifier la question',
			email: 'E-mail',
			email_already_registered: 'E-mail déjà enregistré',
			email_and_or_password_wrong: 'Mauvais email et/ou mot de passe !',
			email_for_login: 'Cet e-mail sera votre identifiant',
			email_or_password_wrong: 'email ou mot de passe incorrect',
			employee: 'Employé',
			employees: 'Employés',
			empty: 'Vide',
			entrada: "A-t-il une valeur d'entrée ? Si OUI, quelle valeur",
			entries_until_today: 'Entrées à ce jour',
			error_confirm_password: 'La confirmation et le nouveau mot de passe doivent être identiques',
			error_email: "Format d'email invalide",
			error_password: 'Mot de passe incorrect',
			error_phone: 'Numéro de téléphone invalide',
			estadoInteresse: 'État',
			exchange: 'Remplacement',
			expired: 'Expiré',
			extorno_td: 'retour',
			extra: 'Supplémentaire',
			extract: 'Extrait',
			extracts: 'extraits',
			fantasy: 'Fantaisie',
			fatherName: 'Le nom du père',
			February: 'Février',
			fee: 'frais de retrait',
			feedback: 'Retour',
			feminine: 'Féminin',
			fgts: 'Valeur de votre FGTS',
			field_required: 'champ obligatoire',
			final: 'Final',
			finance: 'Financier',
			financial: 'Financier',
			financial_payment: 'paiement financier',
			financial_payment_return: 'Retour de paiement financier',
			financial_receipt: 'reçu financier',
			financing: 'Financement',
			financings: 'Financement',
			first_10_pages: '10 premières pages',
			fisica: 'Personne physique',
			for_year: 'Par an',
			forget_password: "j'ai oublié le mot de passe",
			forgot_password: 'Mot de passe oublié',
			form: 'Former',
			form_of_payment: 'Forme de payement',
			found: 'Trouvé',
			founds: 'Trouvé',
			friend_indicated: 'Recommandé par mes amis',
			friend_indicateds: 'Recommandé par mes amis',
			fuel: 'Carburant',
			full_name: 'Nom et prénom',
			funcPublico: 'Êtes-vous un fonctionnaire?',
			fundamental_complete: 'Élémentaire - Complète',
			fundamental_incomplete: 'Élémentaire - Incomplet',
			ganh_diario: 'Gains quotidiens',
			gender: 'Genre',
			grand_total: 'total',
			group: 'Groupe',
			has_withdraw_today: "Un retrait a déjà été demandé aujourd'hui",
			home: 'Commencer',
			how_it_works: 'Comment ça fonctionne',
			how_to_call: "Comment voulez-vous qu'on vous appelle ?",
			id_td: 'Indication directe',
			images: 'Images',
			in_construction: 'En cours de construction',
			in_order: 'En ordre',
			in_stock: 'En stock',
			indicant: 'Indicateur',
			indicated_by: 'Vous êtes nominé par',
			indicated_not_found: "L'indicateur indiqué n'a pas été trouvé...",
			indicateds: 'Indiqué',
			indirects: 'Indirect',
			info: "Bulletins d'information",
			info_contact: 'Coordonnées',
			initial: 'Initial',
			insert_new: 'Insérer nouveau',
			installments: 'versements',
			intelligence: 'Intelligence',
			interesse: "Quelle est la région d'intérêt",
			invalid_contact: 'Format de contact invalide',
			invalid_images: 'images invalides',
			invalid_token: 'jeton invalide',
			invite: 'Inviter',
			invite_friends: 'Invite tes amis',
			January: 'Janvier',
			July: 'Juillet',
			June: 'Juin',
			juridica: 'Personne morale',
			know_exact_result: 'Connaissez-vous la valeur exacte ?',
			lead: 'Ensemble, nous construirons un nouvel avenir.',
			learn_more: 'Savoir plus',
			left: 'Gauche',
			left_preferential: 'Préférer le côté gauche',
			legalNature: 'Nature juridique',
			level: 'Niveau',
			limite_excedido: 'Limite de débit dépassée',
			linear: 'Linéaire',
			link_email: "Cliquez sur le lien envoyé à l'e-mail enregistré !",
			link_invite_copy: 'Partager le lien copié avec succès',
			liquid: 'Liquide',
			list: 'Liste',
			loading: 'Chargement',
			location: 'Emplacement',
			login: 'Entrer',
			logout: 'Sortir',
			male: 'Masculin',
			manage_users: 'gérer les utilisateurs',
			manager: 'Directeur',
			mandala: 'Groupe de Business',
			mandalas: "Groupes d'affaires",
			manufacturer: 'Fabricant',
			March: 'Mars',
			maritalStatus: 'État civil',
			market: 'Marché',
			married: 'Marié',
			master_complete: 'Maîtrise - Complète',
			master_incomplete: 'Master - Incomplet',
			May: 'Peut',
			message: 'Message',
			messageNotSent: "Il n'a pas été possible d'envoyer votre message",
			messageSent: 'Votre message a été envoyé avec succès',
			middle_complete: 'Moyen - Complet',
			middle_incomplete: 'Moyen - Incomplet',
			min_amount_error: 'La valeur doit être au moins :',
			min_withdraw: 'Montant minimum pour le retrait',
			missing: 'Manquant',
			mission: 'Mission',
			mission_vision_values: 'Mission, vision et valeurs',
			model: 'Modèle',
			monitors: 'Moniteurs',
			monthly: 'Paiement mensuel',
			motherName: 'le nom de la mère',
			movements: 'Mouvements',
			msg_renovacao: 'Vous avez atteint votre limite de gain, renouvelez votre forfait.',
			my_account: 'Mon compte',
			my_wallet: 'Mon portefeuille',
			mystock: 'Mon stock',
			name: 'Nom',
			nascMaisVelhoRenda2: "Quelle est la date de naissance de l'aîné ?",
			nationality: 'Nationalité (Pays de naissance)',
			naturalness: 'Lieu de naissance (Ville où vous êtes né)',
			nature: 'Nature',
			network: 'Réseau',
			new: 'Nouveau',
			new_call: 'Nouvel appel',
			new_financing: 'Nouveau financement',
			new_order: 'nouvel ordre',
			new_password: 'nouveau mot de passe',
			new_password_security: 'Nouveau mot de passe de sécurité',
			new_seller: 'Nouveau vendeur',
			new_wallet: 'Nouveau portefeuille',
			news: 'Nouvelles',
			next: 'Suivant',
			nick: 'Utilisateur',
			nis: 'SIP/NIS',
			nis_already_registered: 'PIS/NIS déjà enregistré',
			no: 'Non',
			no_approve: 'toujours pas approuvé',
			no_delete: 'ne pas exclure',
			no_literate: 'pas alphabétisé',
			no_repeat: 'Les données ne peuvent pas être répétées',
			no_results_found: 'aucun résultat trouvé',
			no_services_or_products_added: 'Aucun service ou produit ajouté',
			no_settings: 'Allez dans Paramètres et remplissez vos coordonnées.',
			none_friends_indicated: "Je n'ai pas encore parrainé d'amis",
			not_authorized: 'Pas autorisé',
			not_found: 'Pas trouvé',
			not_login: "Il n'a pas été possible d'entrer, vérifiez les données et réessayez !",
			not_orders: 'Pas de mandats',
			not_permission: "Vous n'êtes pas autorisé à accéder à cette page.",
			not_registered: 'Pas encore inscrit? Cliquez ici et inscrivez-vous',
			not_results: 'Aucun enregistrement trouvé',
			not_self_register: "Impossible de s'inscrire",
			not_today_entry: "Nous n'avons toujours pas eu de billets aujourd'hui",
			notfound_ad: 'Aucune annonce trouvée',
			November: 'Novembre',
			number: 'Nombre',
			obs: 'commentaires',
			October: 'Octobre',
			of: 'dans',
			off: 'Effacé',
			on_total: 'du total',
			open: 'Ouvrir',
			opening: 'Ouverture',
			operation: 'Performance',
			ordenation: 'ordination',
			order: 'Commande',
			order_not_found: 'commande introuvable',
			orders: 'Demandes',
			other: 'Autre',
			other_services_or_products: 'Autres services ou produits',
			paid: 'Payé',
			paid_date: 'Jour de paie',
			partial: 'Partiellement payé',
			passport: 'Passeport',
			password: 'Mot de passe',
			password_security: 'mot de passe de sécurité',
			pay: 'Payer',
			pay_generate: 'générer le paiement',
			pay_selected: 'Payer sélectionné',
			pay_value_in: 'verser le montant en',
			payable: 'Payer',
			payer: 'Payeur',
			payment: 'Paiement',
			ped_retirada: 'Demande de retrait',
			ped_retirada_gan_diario: 'Demande de retrait des gains quotidiens',
			pendent: 'En attente',
			people: 'Personnes',
			person: 'Personne',
			person_found_in_other_prefecture: 'Personne déjà inscrite dans une autre commune',
			personal: 'Gars',
			personal_addresses: 'Adresses',
			personal_contacts: 'Contacts',
			personal_data: 'Données personnelles',
			personal_infos: 'Information',
			personal_pcva: 'Programme des maisons vertes et jaunes',
			phone: 'Téléphone',
			pix: 'PIX',
			pix_payment: 'Paiement en PIX',
			pix_payment_return: 'Retour de paiement en PIX',
			pix_receipt: 'Réception en PIX',
			pl_carreira: 'Cheminement de carrière',
			plan: 'Plat',
			plates: 'Assiettes',
			please_wait: "S'il vous plaît, attendez",
			plus: 'plus',
			point: 'Indiquer',
			points: 'Points',
			portfolio: 'portefeuille',
			pos_complete: 'Lato sensu - Complet',
			pos_incomplete: 'Lato sensu - Incomplet',
			prefecture: "L'hôtel de ville",
			prefecture_addresses: 'Adresses',
			prefecture_contacts: 'Contacts',
			prefecture_data: 'Données de la mairie',
			prefecture_infos: 'Information',
			prefectures: 'mairies',
			prev: 'Précédent',
			price: 'Prix',
			print: 'Imprimer',
			privacy_policies: 'politique de confidentialité',
			private_works: 'Travaux privés',
			profession: 'Profession',
			profile: 'Profil',
			program: 'Programme',
			programs: 'Logiciel',
			project: 'Projet',
			projects: 'Projets',
			public_works: 'Travaux publics',
			purchase: 'Achat',
			qualify_binary: 'Qualification binaire',
			qualquer: "N'importe quel",
			quantity: 'Montant',
			quotation: 'Prix',
			read: 'Lire',
			receivable: 'Recevoir',
			recipient: 'destinataire',
			recommendations: 'Recommandations',
			redefine_password: 'Redéfinir le mot de passe',
			register: 'Enregistrer',
			register_people: 'Inscription des personnes',
			register_wallet: 'Enregistrez un portefeuille BTC pour effectuer des retraits',
			registers: 'Dossiers',
			registers_users: "Enregistrement de l'utilisateur",
			reject: 'Rejeter',
			remaining: 'Restant',
			remove: 'Retirer',
			rendaIndividual: 'Revenu brut individuel',
			renew: 'Renouveler',
			renew_mandala: 'Renouveau du Mandala',
			reply: 'Répondre',
			reports: 'Rapports',
			required_data_not_sent: 'Données requises non envoyées',
			resend_email: "renvoyer l'email",
			residential: 'Résidentiel',
			responded: 'Répondu',
			restricao: 'Avez-vous une restriction à votre nom ? (CPS/Serasa)',
			result: 'Résultat',
			results: 'Résultats',
			resume_data: 'Résumé des données',
			revoked: 'révoqué',
			rg: 'Enregistrement général (identité)',
			rgAgency: 'Émetteur du RG',
			rgDate: "Date d'émission du RG",
			rgUf: "Statut de l'identifiant",
			right: 'Droite',
			right_preferential: 'Préférer le côté droit',
			roi: 'Gains quotidiens',
			sale: 'Vente',
			saq_duplicado: 'Retrait en double',
			save: 'Sauver',
			schooling: 'Éducation',
			search: 'Recherche',
			secretary: 'secrétaire',
			security_password: 'mot de passe de sécurité',
			see: 'Voir',
			see_advents: 'voir les annonces',
			see_call: "voir l'appel",
			see_details: 'voir les détails',
			see_details_wallet: 'Afficher les détails du portefeuille',
			see_financing: 'Voir le financement',
			see_order: "Voir l'ordre",
			see_user: 'Afficher les utilisateurs',
			select_vehicle: 'sélectionner le véhicule',
			self_register: 'Enregistrer',
			seller: 'Vendeur',
			send_call: 'Envoyer le billet',
			send_to: 'Envoyer à',
			September: 'Septembre',
			settings: 'paramètres',
			share_your_link: 'Partagez votre lien',
			show: 'se présenter à',
			show_from: 'montrant de',
			side: 'Côté',
			sign_in_to_start_your_session: 'Connectez-vous pour démarrer votre session',
			sign_up: 'Enregistrer',
			single: 'Seul',
			sold: 'Vendu',
			state: 'État',
			status: 'Statut',
			strategies: 'Stratégies',
			street: 'Route',
			subject: 'Sujet',
			submit: 'Envoyer',
			submit_placeholder: 'Merci de renseigner votre email',
			subscribe: 'Inscrivez-vous pour recevoir nos actualités',
			subtitle: 'Légende',
			success_update_user: 'Utilisateur mis à jour avec succès !',
			success_wallet: 'Portfolio mis à jour avec succès !',
			suggestions: 'suggestions',
			superior_complete: 'Diplômé',
			superior_incomplete: 'supérieur incomplet',
			supervisor: 'Superviseur',
			support: 'Soutien',
			support_materials: 'Matériel de soutien',
			suspended: 'Suspendu',
			symbols: 'Symboles',
			system: 'Système',
			target: 'But',
			tempoCarteira: 'Depuis combien de temps le disque est-il dans le portefeuille',
			terms: "J'accepte les conditions d'utilisation",
			this_value_in: 'Cette valeur en',
			title: 'Titre',
			to: "Jusqu'à",
			to_pay: 'Éteindre',
			to_receive: 'recevable',
			today_entry: "L'entrée d'aujourd'hui",
			token_not_found_or_already_used: 'Jeton introuvable ou déjà utilisé',
			total: 'Total',
			total_entry: 'Entrée totale',
			traderbot: 'commerçant de robots',
			transf_credito: 'Transfert de crédit',
			transf_debito: 'Transfert de débit',
			transfer_payment: 'paiement de transfert',
			transfer_payment_return: 'Retour de paiement de transfert',
			transfer_receipt: 'réception en virement',
			transparency: 'Transparence',
			tx_adm_cred: 'Administrer la taxe',
			tx_adm_deb: 'Administrer la taxe',
			tx_retirada: 'frais de retrait',
			tx_retirada_gan_diario: 'Taux de retrait des gains quotidiens',
			type: 'Taper',
			update: 'Mettre à jour',
			update_wallet: 'Mettre à jour le portefeuille',
			upgrade: 'Amélioration',
			used: 'Utilisé',
			user: 'Utilisateur',
			user_active: 'utilisateur actif',
			user_already_exists: "L'utilisateur existe déjà!",
			user_and_or_password_wrong: "Mauvais nom d'utilisateur et / ou mot de passe!",
			user_inactive: 'utilisateur inactif',
			user_not_found: 'Utilisateur non trouvé',
			user_not_logged: 'Utilisateur non connecté',
			user_or_password_wrong: 'identifiant ou mot de passe incorrect',
			users: 'Utilisateurs',
			users_list: "liste d'utilisateur",
			valid_images: 'images valides',
			valorRenda2: 'Quelle est la valeur du deuxième revenu',
			value: 'Valeur',
			value_donate_on: 'Valeur du don en',
			value_financed: 'montant financé',
			value_installment: 'valeur du versement',
			values: 'Valeurs',
			vehicle: 'Véhicule',
			vehicles: 'Véhicules',
			view_order: 'Voir la commande',
			vision: 'Vision',
			visitor: 'Visiteur',
			voucher: 'Bon',
			wait_confirm: 'Confirmation en attente',
			waiting: 'En attendant',
			waiting_email: "L'inscription a été effectuée avec succès, il ne reste plus qu'à confirmer l'e-mail.",
			waiting_payment: 'En attente de paiement',
			wallet: 'Portefeuille',
			wallet_generate: 'Générer un portefeuille',
			wallets: 'portefeuilles',
			want_donate: 'Je veux faire un don',
			want_now_more_about: 'Je veux en savoir plus sur',
			warning: 'ATTENTION',
			we_are: 'Sont',
			webhooks: 'webhooks',
			welcome: 'Accueillir',
			who_we_are: 'Qui nous sommes',
			who_we_are_subtitle: 'Une brève description de la',
			widower: 'Veuf',
			will_expire_in: 'expirera dans',
			with: 'avec',
			withdraw: 'Retrait',
			withdraw_sended_email: 'Retrait demandé, vérifiez votre e-mail et cliquez sur le lien pour autoriser !',
			withdrawal: 'Retraits',
			withdrawals: 'Retraits',
			year: 'Année',
			years: 'Années',
			yes: 'Oui',
			your_country: 'Votre pays',
			your_dashboard: 'Ceci est votre panneau de contrôle.',
			your_link: 'Votre lien',
			zipcode: 'Code postal',
			amount_above_balance: 'Solde insuffisant',
			symbol: 'Symbole',
			close: 'Fermeture',
			high: 'Alto',
			low: 'Faible',
			order_book: 'Carnet de commande',
			coin: 'Pièce de monnaie',
			free: 'livre',
			locked: 'Bloqué',
			you_have: 'Tu as',
			buy_volume: 'Acheter un volume',
			sell_volume: 'Valoir de vente',
			estimate: 'Estimé',
			photo: 'Photo',
			telegram_chat_id: 'ID de chat Telegram',
			pendent_order: 'Vous avez des commandes en attente',
			active_automations: 'Automations actives',
			active_monitors: 'Moniteurs actifs',
			active_users: 'Utilisateurs actifs',
			active_connections: 'Connexions actives',
			ticker_health: 'Santé de ticker',
			book_health: 'Santé de réservation',
			enter_your_new_password: 'Entrez votre nouveau mot de passe',
			confirm_your_new_password: 'Entrez à nouveau votre nouveau mot de passe',
			alerts: 'Avertissement',
			alert: 'Alerte',
			how_create_binance_apikey: 'Comment créer la clé API à Binance',
			text_hint_binance:
				"Lors de la création de la clé API à Binance, les restrictions d'accès IP:\n172.105.78.139\nPuis vérifiez les options:\n• ENABLE EUROPEAN OPTIONS\n• ENABLE SPOT & MARGIN TRADING",
		},
	},
};

export { messages };
