import axios from './BaseService';
const API_URL = `${process.env.REACT_APP_API_URL}`;

export const doLogin = async (nick, password) => {
	const endPoint = `${API_URL}/auth/login`;
	const response = await axios.post(endPoint, { nick, password });
	return response.data;
};

export const doCheckIndicant = async nickIndicant => {
	const endPoint = `${API_URL}/auth/indicantVerify`;
	const response = await axios.post(endPoint, { nickIndicant });
	return response.data;
};

export const doGetCountries = async () => {
	const endPoint = `${API_URL}/countries`;
	const response = await axios.get(endPoint);
	return response.data;
};

export const doGetPlans = async () => {
	const endPoint = `${API_URL}/plans`;
	const response = await axios.get(endPoint);
	return response.data;
};

export const doVerifyNick = async nick => {
	const endPoint = `${API_URL}/auth/verifyNick`;
	const response = await axios.post(endPoint, { nick });
	return response.data;
};

export const doRegister = async post => {
	const endPoint = `${API_URL}/usersmmn/register`;
	const response = await axios.post(endPoint, post);
	return response.data;
};

export const doReconfirmEmail = async nick => {
	const endPoint = `${API_URL}/auth/reemailregister/${nick}`;
	const response = await axios.get(endPoint);
	return response.data;
};

export const sendToForget = async nick => {
	const endPoint = `${API_URL}/auth/forgetPassword`;
	const response = await axios.post(endPoint, { nick });
	return response.data;
};

export const getUserByTokenPass = async tokenPass => {
	const endPoint = `${API_URL}/auth/tokenpass/${tokenPass}`;
	const response = await axios.get(endPoint);
	return response.data;
};

export const redefinePassword = async post => {
	const endPoint = `${API_URL}/auth/redefinePassword`;
	const response = await axios.post(endPoint, post);
	return response.data;
};

export const doLogout = async token => {
	const endPoint = `${API_URL}/logout`;
	const headers = {
		'Content-Type': 'application/json',
		Authorization: `${token}`,
	};
	const response = await axios.post(endPoint, {}, { headers });
	return response.data;
};

export const checkSecurityPassword = async (token, securityPassword) => {
	const endPoint = `${API_URL}/auth/checkSecurityPassword`;
	const headers = { 'Content-Type': 'application/json', Authorization: `${token}` };
	const response = await axios.post(endPoint, { securityPassword }, { headers });
	return response.data;
};
