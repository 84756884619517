import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { i18n } from '../utils/translates/i18n';

const ConfirmedEmail = () => {
	useEffect(() => {
		const toTop = () => {
			document.title = `${i18n.t('confirmed_email')} | ${process.env.REACT_APP_NAME}`;
		};
		toTop();
	}, []);

	return (
		<>
			<div
				style={{
					padding: '50px',
					minWidth: '100%',
					height: '100%',
					background: 'url(/assets/images/space-bg.jpg) no-repeat center',
					backgroundSize: 'cover',
				}}>
				<div className='row'>
					<div className=' alert bg-primary p-5 rounded-lg col-md-6 ms-md-5 text-center'>
						<p className='h1 text-light fw-bold'>{i18n.t('congratulations').toUpperCase()}!</p>
						<p className='h3 text-light fw-bold'>{i18n.t('confirmed_email')}</p>
						<Link to='/login' className='btn btn-primary border-light btn-lg mt-3'>
							<strong>{i18n.t('login')}</strong>
						</Link>
					</div>
					<div className='col-md-3 m-auto d-none d-md-block'>
						<img src='/logos/logo.png' alt='logo' className='img-fluid' />
					</div>
				</div>
			</div>
		</>
	);
};

export default ConfirmedEmail;
