/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../utils/Loader';
import { i18n } from '../utils/translates/i18n';
import Translate from '../utils/translates/components/Translate';
import { CheckPrivateAdmin } from '../utils/CheckPrivateAdmin';
import { doLogin } from '../services/mmn/AuthService';

const Login = () => {
	const navigate = useNavigate();
	const [show, setShow] = useState(false);

	// const [nick, setNick] = useState('mastersystem');
	const [nick, setNick] = useState('');
	// const [password, setPassword] = useState('8a4(9<}Ex#Jme67v');
	const [password, setPassword] = useState('');
	const [alertFail, setAlertFail] = useState(false);
	const [msgAlertFail, setMsgAlertFail] = useState('');
	const [relink, setRelink] = useState(false);

	// Autorização de usuário
	useEffect(() => {
		document.title = `${i18n.t('login')} | ${process.env.REACT_APP_NAME}`;
		const doCheck = async () => {
			const token = sessionStorage.getItem('token');
			if (token) {
				const check = await CheckPrivateAdmin(token);
				if (check) navigate('/dashboard');
			}

			setShow(true);
		};
		doCheck();
	}, []);
	// Fim das Autorização de usuário

	const goLogin = async () => {
		const success = await doLogin(nick, password);

		if (success && success.status) {
			sessionStorage.setItem('token', success.token);
			sessionStorage.setItem('hasFutures', success.hasFutures);
			sessionStorage.setItem('id', success.id);
			setAlertFail(false);
			setMsgAlertFail('');
			navigate('/dashboard');
		} else {
			sessionStorage.removeItem('token');
			setAlertFail(true);
			setMsgAlertFail(i18n.t(success.error));
			setRelink(success.relink);
		}
	};

	const reSendEmail = async () => {
		navigate(`/reconfirmemail/${nick}`);
	};

	return !show ? (
		<Loader show={!show} onHide={() => setShow(true)} />
	) : (
		<>
			<div className='wrapper'>
				<div className='section-authentication-login d-flex align-items-center justify-content-center'>
					<div className='row'>
						<div className='mx-auto col-12 col-lg-10'>
							<div className='card radius-15'>
								<div className='row no-gutters'>
									<div className='col-12'>
										<Link to='/'>
											<img src='/logos/logo500w.png' className='img-fluid' alt='logo' />
										</Link>
										<div className='card-body px-md-5'>
											<div className='d-flex mx-2 align-items-center justify-content-between'>
												<h3 className='font-weight-bold'>{i18n.t('login')}</h3>
												<Translate />
											</div>
											<hr />
											{alertFail && (
												<div
													className='alert alert-danger alert-dismissible fade show p-2 my-3 d-flex justify-content-between align-items-center'
													role='alert'>
													<span className='fw-bold fs-6'>
														{msgAlertFail ? i18n.t(msgAlertFail)?.toUpperCase() : i18n.t('not_login')}
													</span>
													{relink && (
														<button className='btn btn-danger btn-sm' onClick={reSendEmail}>
															{i18n.t('resend_email')}
														</button>
													)}
												</div>
											)}
											<div className='form-group mt-4'>
												<label>{i18n.t('user')}</label>
												<input
													className='form-control'
													type='text'
													value={nick}
													placeholder={i18n.t('user')}
													onChange={e => setNick(e.target.value)}
												/>
											</div>
											<div className='form-group mt-4'>
												<label>{i18n.t('password')}</label>
												<input
													className='form-control'
													type='password'
													value={password}
													placeholder={i18n.t('password')}
													onChange={e => setPassword(e.target.value)}
												/>
											</div>
											<div className='mt-3 btn-group w-100' onClick={goLogin}>
												<button type='button' className='btn btn-dark btn-block' onClick={goLogin}>
													<span className='me-3'>{i18n.t('login')}</span>
													<i className='lni lni-arrow-right'></i>
												</button>
											</div>
											<Link to='/forget' className='btn btn-light btn-sm mt-2'>
												<i className='me-2 bx bxs-key'></i>
												{i18n.t('forget_password')}
											</Link>
											<hr />
											<div className='text-center'>
												<p className='mb-0'>
													<Link to='/register' className='btn btn-light btn-sm'>
														{i18n.t('not_registered')}
													</Link>
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Login;
