/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { i18n } from '../utils/translates/i18n';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loader from '../utils/Loader';
import { generateBtcWallet, getOrder /* generateBtcWallet */ } from '../services/mmn/OrdersService';
import { FormateDate, FormateFiatCurrency } from '../utils/CustomFormatations';
import Modals from '../components/SubComponents/Modals';
import LoaderPage from '../utils/LoaderPage';
import Toast from '../components/Toast/Toast';
import QRCode from 'react-qr-code';
import Countdown, { zeroPad } from 'react-countdown';

export default function OrderView() {
	const navigate = useNavigate();
	const param = useParams();
	const orderId = param.orderId;
	const [show, setShow] = useState(false);
	const [loader, setLoader] = useState(false);
	const token = sessionStorage.getItem('token');
	const [alertMsg, setAlertMsg] = useState('');
	const [modalShow, setModalShow] = useState(false);

	const [order, setOrder] = useState([]);
	const [orderPayment, setOrderPayment] = useState([]);

	const doGetOrder = async () => {
		const success = await getOrder(token, orderId);
		if (success.status) {
			setOrder(success.order);
			success.order?.payments?.map(o => o.status === 1 && o.cryptoWallet && setOrderPayment([o]));
		} else navigate('/dashboard/order');
	};

	const doGenerateBtcWallet = async () => {
		setShow(false);
		const success = await generateBtcWallet(token, order.id, 'btc');
		if (success.status) {
			setOrderPayment([success.orderPayment]);
			setShow(true);
		} else {
			setShow(true);
		}
	};

	const renderer = ({ hours, minutes, seconds, completed }) => {
		if (completed) {
			// Render a completed state
			doGenerateBtcWallet();
			navigate(0);
		} else {
			return (
				<span>
					{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
				</span>
			);
		}
	};

	useEffect(() => {
		const doCheck = async () => {
			document.title = `${i18n.t('order')} ${orderId} | ${process.env.REACT_APP_NAME}`;
			if (!token) navigate('/logout');
			await doGetOrder();
			setShow(true);
		};
		doCheck();
	}, []);

	return !show ? (
		<LoaderPage />
	) : (
		<>
			<section className='section'>
				<div className='container-fluid'>
					<div className='card card-body border-0 shadow mb-4 mt-4'>
						{alertMsg && <div className='alert alert-danger h4'>{i18n.t(alertMsg).toUpperCase()}</div>}
						<div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
							<div className='d-block md-4 mb-md-0'>
								<h2 className='h4'>
									{i18n.t('order')} # {order.id} | {i18n.t(order.type)}
								</h2>
							</div>
						</div>
						<div className='form-group'>
							<div className='row'>
								<div className='col-md-4 mb-3'>
									<label>{i18n.t('date')}:</label>
									<div className={`alert alert-default border border-1 fw-bold mb-0`}>
										{FormateDate(order.createdAt, false, true)}
									</div>
								</div>
								<div className='col-md-4 mb-3'>
									<label>{i18n.t('status')}:</label>
									<br />
									<div className={`alert bg-${order.statuses?.bg} border border-1 fw-bold mb-0 `}>
										{i18n.t(order.statuses.status)}
									</div>
								</div>
							</div>
							<div className='row'>
								<div className='col-8 mb-3'>
									<label>{i18n.t('type')}:</label>
									<div className={`alert alert-default border border-1 fw-bold mb-0`}>{i18n.t(order.type)}</div>
								</div>
							</div>
							<div className='row'>
								<div className='col-md-4 mb-3'>
									<label>{i18n.t('total')}:</label>
									<div className={`alert alert-default border border-1 fw-bold mb-0`}>
										{FormateFiatCurrency(order.total)}
									</div>
								</div>
								<div className='col-md-4 mb-3'>
									<label>{i18n.t('remaining')}:</label>
									<div className={`alert alert-default border border-1 fw-bold mb-0`}>
										{FormateFiatCurrency(order.remaining)}
									</div>
								</div>
							</div>
							{order.dischargeDate ? (
								<div className='row'>
									<div className='col-md-4 mb-3'>
										<label>{i18n.t('paid_date')}:</label>
										<div className={`alert alert-default border border-1 fw-bold mb-0`}>
											{FormateDate(order.dischargeDate, true, true)}
										</div>
									</div>
								</div>
							) : (
								<div className='row'>
									<div className='col-md-6 mb-3'>
										<button type='button' className='btn btn-primary' onClick={() => setModalShow(true)}>
											{i18n.t('pay_generate')}
										</button>
										<button
											type='button'
											className='ms-3 btn btn-outline-primary'
											onClick={() => navigate('/dashboard/order')}>
											{i18n.t('back')}
										</button>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</section>
			{modalShow && (
				<Modals
					show={modalShow}
					onHide={() => setModalShow(false)}
					title={`${i18n.t('pay')} ${i18n.t('order')} #${order.id}`}>
					{orderPayment.length ? (
						orderPayment.map((o, i) => (
							<>
								<div className='modal-content dash-font-size'>
									<div className='modal-body text-center'>
										<Link
											to={`https://www.blockchain.com/explorer/addresses/btc/${orderPayment[0]?.cryptoWallet}`}
											target='_blank'>
											<div id='qrcodeDiv'>
												<div className='my-3'>
													<QRCode
														size={256}
														style={{ height: 'auto', maxWidth: '300', width: '300' }}
														// value={`bitcoin:${orderPayment[0]?.cryptoWallet}?amount=${orderPayment[0]?.cryptoAmount}`}
														value={orderPayment[0]?.cryptoWallet}
													/>
												</div>
											</div>

											<div className='mb-3'>
												<span className='fw-bold bg-dark text-black mx-auto p-2'>{orderPayment[0]?.cryptoWallet}</span>
											</div>
										</Link>
										<ul className='list-group'>
											<li className='list-group-item p-2 fs-3 fw-bold bg-dark text-light'>
												<Countdown date={orderPayment[0]?.dueDate} renderer={renderer} />
											</li>
											<li className='list-group-item'>
												Pague o valor em Bitcoin: <br />
												<span className='fs-4 fw-bold'> {orderPayment[0]?.cryptoAmount} BTC</span>
												<br />
												<span className='fs-5 fw-bold'>({FormateFiatCurrency(orderPayment[0]?.amount)})</span>
											</li>
											<li className='list-group-item disabled'>
												Se sua corretora debitar as taxas do valor enviado, você deverá fazer a correção manualmente
												acrescentando os valores dessas taxas.
											</li>
										</ul>
									</div>
								</div>
								{/* <pre>{JSON.stringify(orderPayment, null, 4)}</pre> */}
							</>
						))
					) : (
						<button className='btn btn-primary btn-lg' onClick={doGenerateBtcWallet}>
							{i18n.t('generateBtcWallet')}
						</button>
					)}
				</Modals>
			)}

			<Toast />
			<Loader show={loader} onHide={() => setLoader(false)} />
		</>
	);
}
