import { VectorMap } from '@react-jvectormap/core';
import { worldMill } from '@react-jvectormap/world';
export default function WorldMap({ latlng }) {
	return (
		<>
			<VectorMap
				map={worldMill}
				markerStyle={{
					initial: {
						r: 7,
						fill: 'yellow',
						'fill-opacity': 1,
						stroke: '#000',
						'stroke-width': 5,
						'stroke-opacity': 0.4,
					},
				}}
				markers={latlng}
				normalizeFunction='polynomial'
				scaleColors={['#C8EEFF', '#0071A4']}
			/>
		</>
	);
}
