import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import { i18n } from '../../utils/translates/i18n';

export default function CardStatus({ bg, icon, title, division, valor, sub, perc, small, btn }) {
	function getBackground() {
		if (!bg) return 'icon-shape icon-shape-primary rounded me-4 me-sm-0';
		return `icon-shape icon-shape-${bg} rounded me-4 me-sm-0`;
	}
	return (
		<>
			<div className='card border-0 shadow h-100'>
				<div className='card-body'>
					<div className='row d-block d-xl-flex align-items-center'>
						<div className='col-12 d-flex align-items-center'>
							<div
								className={`d-flex align-items-center justify-content-center rounded-circle bg-${bg}`}
								style={{ minWidth: '50px', minHeight: '50px' }}>
								<i className={`fa-solid fa-${icon} text-bg-${bg} fa-2x`}></i>
							</div>
							<div className='ms-3'>
								<h2 className='h5'>{title}</h2>
								<h3 className='fw-extrabold'>{valor}</h3>
								<p className='mb-0'>{sub}</p>
								{perc && (
									<>
										<ProgressBar variant={bg} animated now={perc} style={{ height: '10px' }} />
										<small>{small}</small>
									</>
								)}
								{btn && (
									<button className={`btn btn-${bg} btn-sm py-1 w-100`} onClick={btn.func}>
										{i18n.t(btn.label)}
									</button>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
