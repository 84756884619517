import axios from './BaseService';
const API_URL = process.env.REACT_APP_API_URL;

export const getOrders = async (token, page, limit, search) => {
	const endPoint = `${API_URL}/ordersmmn/list${page ? `?page=${page}` : ''}`;
	const headers = { 'Content-Type': 'application/json', Authorization: `${token}` };
	const response = await axios.post(endPoint, { limit, search }, { headers });
	return response.data;
};

export const getOrder = async (token, orderId) => {
	const endPoint = `${API_URL}/ordersmmn/order/${orderId}`;
	const headers = { 'Content-Type': 'application/json', Authorization: `${token}` };
	const response = await axios.get(endPoint, { headers });
	return response.data;
};

export const generateBtcWallet = async (token, orderId, coin) => {
	const endPoint = `${API_URL}/ordersmmn/order/generateBtcWallet`;
	const headers = { 'Content-Type': 'application/json', Authorization: `${token}` };
	const response = await axios.post(endPoint, { orderId, coin }, { headers });
	return response.data;
};
