import React from 'react';
import { Link } from 'react-router-dom';
import { FormateDate, FormateFiatCurrency } from '../utils/CustomFormatations';
import { i18n } from '../utils/translates/i18n';

export default function OrderRow({ o }) {
	return (
		<li className={`list-group-item list-group-item-action list-group-item-${o.statuses?.bg}`}>
			<div className='row'>
				<div className='col-md-1 text-center'>
					<label> # {o.id}</label>
					{(o.status === 1 || o.status === 2) && (
						<Link className={`btn btn-${o.statuses?.bg} btn-sm`} to={`/dashboard/order/${o.id}`}>
							<i className='fas fa-dollar me-2'></i> {i18n.t('pay')}
						</Link>
					)}
				</div>
				<div className='col-md '>
					<label>{i18n.t('date')}</label>
					<p className={`text-dark fw-bold mb-0`}>{FormateDate(o.createdAt, false, true)}</p>
				</div>
				<div className='col-md'>
					<label>{i18n.t('status')}</label>
					<br />
					<span className={`badge bg-${o.statuses?.bg}`}>{i18n.t(o.statuses.status)}</span>
				</div>
				<div className='col-md '>
					<label>{i18n.t('type')}</label>
					<p className={`text-dark fw-bold mb-0`}>{i18n.t(o.type)}</p>
				</div>
				<div className='col-md '>
					<label>{i18n.t('total')}</label>
					<p className={`text-dark fw-bold mb-0`}>{FormateFiatCurrency(o.total)}</p>
				</div>
				<div className='col-md '>
					<label>{i18n.t('remaining')}</label>
					<p className={`text-dark fw-bold mb-0`}>{FormateFiatCurrency(o.remaining)}</p>
				</div>
				<div className='col-md '>
					<label>{i18n.t('due_date')}</label>
					<p className={`text-dark fw-bold mb-0`}>
						{o.dischargeDate
							? FormateDate(o.dischargeDate)
							: o.payments && o.payments[0]?.dueDate
							? FormateDate(o.payments[0]?.dueDate)
							: o.status === 1 || o.status === 2
							? i18n.t('waiting_payment')
							: i18n.t(o.statuses.status)}
					</p>
				</div>
			</div>
		</li>
	);
}
