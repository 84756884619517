import React from 'react';
import { i18n } from './translates/i18n';

export default function LoaderPage() {
	return (
		<div className='mt-5 text-center'>
			<img src='/logos/logo.png' alt='logo' className='img-fluid' style={{ width: '200px' }} />
			<div
				className='mt-4 text-light h2'
				style={{ textShadow: '-1px -1px 0 #000, 2px 2px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000' }}>
				<div className='spinner-border text-dark me-3' role='status'>
					<span className='visually-hidden'>{i18n.t('loading')}...</span>
				</div>
				{i18n.t('please_wait')?.toUpperCase()}
			</div>
		</div>
	);
}
