import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import MainRoutes from './mainRoutes';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<StrictMode>
		<MainRoutes />
	</StrictMode>,
);
