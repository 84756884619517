/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { i18n } from '../utils/translates/i18n';
import Loader from '../utils/Loader';
import Modals from '../components/SubComponents/Modals';
import { FormateDate } from '../utils/CustomFormatations';
import { getCalls, sendCall } from '../services/mmn/CallsService';
import { SettingPage } from '../utils/pagination/Settings';
import Pagination from '../utils/pagination/Pagination';
import { useNavigate } from 'react-router-dom';
import { CheckPrivateAdmin } from '../utils/CheckPrivateAdmin';
import LoaderPage from '../utils/LoaderPage';
import Toast from '../components/Toast/Toast';

export default function Call() {
	const navigate = useNavigate();
	const [show, setShow] = useState(false);

	const DEFAULTDATA = {
		subject: '',
		department: 'support',
		message: '',
	};

	const token = sessionStorage.getItem('token');
	const [loader, setLoader] = useState(false);
	const [calls, setCalls] = useState([]);
	const [search, setSearch] = useState('');
	const [modalShow, setModalShow] = useState(false);
	const [data, setData] = useState(DEFAULTDATA);

	const onInputChange = (field, value) => setData(prevState => ({ ...prevState, [field]: value }));

	// PARA PAGINAÇÃO
	const [count, setCount] = useState(0);
	const [page, setPage] = useState(1);
	const [limit, setLimits] = useState(10);
	SettingPage({ setting: setPage });

	const listCalls = async () => {
		setLoader(true);
		const success = await getCalls(token, page, limit, search);
		if (success.status) {
			setCount(success.list.count);
			setCalls(success.list.rows);
			setLoader(false);
		} else {
			setLoader(false);
		}
	};

	const doSearch = () => {
		setPage(1);
		listCalls();
	};

	const doSendCall = async () => {
		setLoader(true);
		const success = await sendCall(data, token);
		if (success.status) ReadCall(success.call.id);
		setLoader(false);
	};

	const ReadCall = id => {
		sessionStorage.setItem('idCall', id);
		window.location = '/dashboard/readcall';
	};

	useEffect(() => {
		listCalls();
	}, [token, page, limit]);

	useEffect(() => {
		window.document.title = `${i18n.t('calls')} | ${process.env.REACT_APP_NAME}`;
		const doCheck = async () => {
			const token = sessionStorage.getItem('token');
			if (!token) navigate('/logout');
			const check = await CheckPrivateAdmin(token);
			if (!check) navigate('/logout');
			setShow(true);
		};
		doCheck();
	}, []);

	return !show ? (
		<LoaderPage />
	) : (
		<>
			<div className='container-fluid'>
				<Pagination count={count} pageSize={limit}>
					<button className='btn btn-primary d-inline-flex align-items-center' onClick={() => setModalShow(true)}>
						<i className='fas fa-plus me-2'></i> <span>{i18n.t('new_call')}</span>
					</button>
					<div className='input-group mx-3'>
						<input
							type='text'
							className='form-control'
							placeholder={`${i18n.t('search')} ${i18n.t('subject')} / ${i18n.t('description')}`}
							value={search}
							onChange={e => setSearch(e.target.value)}
							onBlur={doSearch}
						/>
						<button className='btn input-group-text btn-dark' onClick={doSearch}>
							<i className='fas fa-search'></i>
						</button>
					</div>
					<select className='form-select w-50' value={limit} onChange={e => setLimits(e.target.value)}>
						<option className='bg-light' value='5'>
							5 {i18n.t('registers')}
						</option>
						<option className='bg-light' value='10'>
							10 {i18n.t('registers')}
						</option>
						<option className='bg-light' value='25'>
							25 {i18n.t('registers')}
						</option>
						<option className='bg-light' value='50'>
							50 {i18n.t('registers')}
						</option>
						<option className='bg-light' value='100'>
							100 {i18n.t('registers')}
						</option>
					</select>
				</Pagination>
				<div className='row'>
					<div className='col'>
						<div className='card radius-15'>
							<div className='card-body'>
								<ul className='list-group'>
									{calls?.map((c, i) => (
										<li
											className={`list-group-item list-group-item-action list-group-item-${c.statuses?.bg}`}
											key={i}
											onClick={() => ReadCall(c.id)}>
											<div className='row'>
												<div className='col-md-1 d-flex align-items-center'>
													<button className={`btn btn-${c.statuses.bg} btn-sm`}>
														<i className='fas fa-eye me-2'></i> # {c.id}
													</button>
												</div>
												<div className='col-md-2 '>
													<label>{i18n.t('date')}</label>
													<p className={`text-dark fw-bold mb-0`}>{FormateDate(c.createdAt, false, true)}</p>
												</div>
												<div className='col-md-7'>
													<div className='row'>
														<div className='col-md-4'>
															<label>{i18n.t('subject')}</label>
															<p className={`text-dark fw-bold mb-0`}>{c.subject.toUpperCase()}</p>
														</div>
														<div className='col-md-8'>
															<label>{i18n.t('description')}</label>
															<p className={`text-dark fw-bold mb-0`}>{c.description}</p>
														</div>
													</div>
												</div>
												<div className='col-md-2 d-flex align-items-center justify-content-end'>
													<div className={`alert my-auto py-1 bg-${c.statuses?.bg}`}>{i18n.t(c.statuses.status)}</div>
												</div>
											</div>
										</li>
									))}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Modals
				size='lg'
				title={i18n.t('new_call')}
				contentClassName='bg-dark'
				show={modalShow}
				onHide={() => setModalShow(false)}>
				<div className='row'>
					<div className='col'>
						<div className='form-group col-md-6'>
							<label>{i18n.t('department')}:</label>
							<select
								className='form-select'
								value={data.department}
								onChange={e => onInputChange('department', e.target.value)}>
								<option value={`support`} className='bg-light'>
									{i18n.t(`support`)}
								</option>
								<option value={`finance`} className='bg-light'>
									{i18n.t(`finance`)}
								</option>
							</select>
						</div>
						<div className='form-group mt-3'>
							<label>{i18n.t('subject')}</label>
							<input
								className='form-control'
								id='subject'
								type='text'
								placeholder={i18n.t('subject')}
								value={data.subject}
								onChange={e => onInputChange('subject', e.target.value)}
							/>
							<div className='invalid-feedback bg-danger p-1 mt-0'>{i18n.t('This field cannot be empty')}</div>
						</div>
						<div className='form-group mt-3'>
							<label>{i18n.t('message')}</label>
							<textarea
								className='form-control'
								id='message'
								rows={5}
								placeholder={i18n.t('message')}
								value={data.message}
								onChange={e => onInputChange('message', e.target.value)}></textarea>
							<div className='invalid-feedback bg-danger p-1 mt-0'>{i18n.t('This field cannot be empty')}</div>
						</div>
					</div>
				</div>
				<button className='btn btn-primary btn-lg align-self-end mt-3' onClick={doSendCall}>
					{i18n.t('send_call')}
				</button>
			</Modals>

			<Toast />
			<Loader show={loader} onHide={() => setLoader(false)} />
		</>
	);
}
